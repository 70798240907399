export default [
  {
    name: 'portal',
    path: '/portal',
    component: () => import('../pages/portal')
  },
  {
    name: 'portal-visualization-warning',
    path: '/portal/visualization/warning',
    component: () => import('../pages/visualization/warning')
  }
]
