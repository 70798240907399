<template>
  <vxe-table
    ref="tableInstance"
    :data="$_VALUE"
    :max-height="500"
    :column-config="{ resizable: true }"
    border="inner"
    class="border-top border-gray-200"
  >
    <vxe-column type="seq" title="序号" width="64" fixed="left" align="center" :col-id="$_INDEX_KEY">
      <template v-if="$slots[$_INDEX_KEY] || $slots[`cell(${$_INDEX_KEY})`]" v-slot="slotParams">
        <slot :name="$_INDEX_KEY" :for="$_INDEX_KEY" :data="{ item: slotParams.row, value: slotParams.row[$_INDEX_KEY], index: slotParams.$rowIndex }" :form="{ key: $_INDEX_KEY}" />
      </template>
    </vxe-column>
    <template :key="`table-column-${column.key}`" v-for="column in fieldsConfig">
      <vxe-column v-if="['do', 'action'].includes(column.key)" title="操作" :width="cellOptionWidth" fixed="right" col-id="do">
        <template v-slot="slotParams">
          <div class="w-100" :ref="el => cellOptionWrapper[slotParams.$rowIndex] = el">
            <cell-option variant="danger" @function="deleteOne(slotParams.$rowIndex)">删除</cell-option>
          </div>
        </template>
      </vxe-column>
      <vxe-column
        v-else-if="column.key !== $_INDEX_KEY"
        min-width="150"
        :show-overflow="!['zero-select'].includes(column.form?.component)"
        :sortable="!!column.sort"
        :formatter="parseFormatter(column)"
        :fixed="column.fixed ?? undefined"
        :title="column.label ?? column.key"
        :field="column.key"
        :col-id="column.key"
      >
        <template v-slot="slotParams">
          <slot v-if="$slots[column.key] || $slots[`cell(${column.key})`]" :name="column.key" :for="column.key" :data="{ item: slotParams.row, value: slotParams.row[column.key], index: slotParams.$rowIndex }" :form="column.form" />
          <component
            v-else-if="column.form"
            :is="column.form.component"
            :required="!!column.form.required"
            :validation="column.form.validation"
            :for="column.key"
            :label="column.label"
            v-bind="column.form.config"
            v-on="initAction(column.key, slotParams.$rowIndex) || {}"
            v-model="$_VALUE[slotParams.$rowIndex][column.key]" />
          <template v-else>{{ slotParams.row[column.key] }}</template>
        </template>
      </vxe-column>
    </template>
  </vxe-table>
  <div class="d-grid gap-2">
    <b-button v-if="config && !config.noButton && !dropdownButton" variant="outline" class="btn-outline-dashed text-primary" size="lg" @click="addOne">
      新增一行
    </b-button>
  </div>
  <b-dropdown v-if="config && !config.noButton && dropdownButton" variant="primary" id="dropdown-1" text="添加物料" class="m-md-2">
    <slot name="dropdownItem" :addOne="addOne">
      <b-dropdown-item-button @click="addOne">添加一行</b-dropdown-item-button>
    </slot>
  </b-dropdown>
  <slot name="footer" :items="$_VALUE" :fields="fieldsConfig" />
</template>

<script setup>
import { ref, defineProps, defineEmits, defineExpose, watch, onBeforeMount } from 'vue'
import { isEmpty, typeOf } from '../utils/common'
import { getComponent } from './internal-types'

const emit = defineEmits(['addOne', 'deleteOne', 'update:modelValue'])
const props = defineProps({
  modelValue: { type: Array, default: () => [] },
  config: { type: Object, default: () => { return {} } },
  change: {},
  for: { type: String },
  dropdownButton: { type: Boolean, default: false }
})

const $_INDEX_KEY = ref('index')
const $_KEY_LIST = ref({})
const $_VALUE = ref([])
const fieldsConfig = ref({})
const cellOptionWrapper = ref([])
const cellOptionWidth = ref(100)
const tableInstance = ref()

watch(cellOptionWrapper, value => {
  const minWidth = 100
  let width = 0
  for (const i in value) {
    const options = value[i].querySelectorAll('.cell-option')
    let currentWidth = 0
    for (const n in options) {
      if (options[n].clientWidth) {
        currentWidth += options[n].clientWidth + 12
      }
    }
    currentWidth += 32
    width = (currentWidth > width ? currentWidth : width)
  }
  cellOptionWidth.value = (minWidth > width ? minWidth : width)
  // tableInstance.value.refreshColumn()
}, { deep: true, once: true })

watch(() => props.modelValue, value => {
  $_VALUE.value = value || []
}, { immediate: true })

watch($_VALUE, value => {
  value.forEach(item => {
    for (const itemKey in item) {
      if (isEmpty(item[itemKey])) {
        item[itemKey] = null
      }
    }
  })
  tableInstance.value.loadData($_VALUE.value)
  emit('update:modelValue', value)
}, { deep: true })

function addOne () {
  const data = {}
  for (const i in props.config.fields) {
    if (props.config.fields[i].form?.default === 0) {
      data[props.config.fields[i].key] = 0
    } else {
      data[props.config.fields[i].key] = props.config.fields[i].form?.default || null
    }
  }
  $_VALUE.value.push(data)
  emit('addOne', $_VALUE.value)
}

function deleteOne (index) {
  $_VALUE.value.splice(index, 1)
  emit('deleteOne', $_VALUE.value, index)
}

async function init () {
  const data = [...props.config.fields ?? []]
  const keyListRaw = {}
  for (const i in data) {
    if (data[i].form) {
      if (data[i].form.config && data[i].form.config.options) {
        if (Object.prototype.toString.call(data[i].form.config.options) === '[object Promise]') {
          data[i].form.config.options = await data[i].form.config.options
        }
      }
      if (data[i].form.type || data[i].form.component) {
        data[i].form.component = getComponent(data[i].form.component ?? data[i].form.type)
      }
      keyListRaw[data[i].form.key] = {
        required: !!data[i].form.required,
        validation: data[i].form.validation ? parseValidationConfig(data[i].form.validation) : undefined
      }
    }
  }
  $_KEY_LIST.value = keyListRaw
  return data
}

function parseValidationConfig (config) {
  return (typeOf(config) !== 'array') ? [config] : config
}

function initAction (key, index) {
  const action = {}
  const fieldItem = props.config.fields.find(i => {
    return i.key === key
  })
  if (fieldItem.form && fieldItem.form.action) {
    for (const eventName in fieldItem.form.action) {
      const func = fieldItem.form.action[eventName]
      action[eventName] = (param, ...rest) => {
        func(param, $_VALUE.value, index)
      }
    }
  }
  return action
}

function parseFormatter (field) {
  return ({ cellValue, row, column }) => {
    return translateValue(cellValue, row, column, field)
  }
}

function translateValue (data, row, column, field) {
  let value = ''
  if (field.format) {
    value = row[field.key] !== undefined ? field.format(row[field.key], row) : undefined
  } else {
    value = row[field.key]
  }
  return value ? value.toString() : '-'
}

/**
 * @name validate
 * @description 此函数用于zero-form调用，用于验证当前组件中的值都是否合法
 * @description 检查表单是否合法
 * @description 会将不合法的字段的错误信息存入 inValidList
 * @description 检查必填项
 * @description 检查自定义验证
 * @description 返回不合法的字段数量
 * @returns {{message: string, status: number}}
 */
function validate () {
  const validationResult = {
    status: 0,
    message: ''
  }
  const validationList = {}
  validationResult.data = validationList
  return validationResult
}

function parseIndexColumn () {
  for (const n in fieldsConfig.value) {
    if (fieldsConfig.value[n].key === 'index' || fieldsConfig.value[n].type === 'index') {
      $_INDEX_KEY.value = fieldsConfig.value[n].key
      return
    }
  }
}

onBeforeMount(async () => {
  /* if (props.config.formType === 'add') {
    addOne()
  } */
  fieldsConfig.value = await init()
  parseIndexColumn()
})

defineExpose({ validate, items: $_VALUE, value: $_VALUE, addOne })
</script>
