let breadCrumb = []

function getBreadCrumb () {
  return breadCrumb
}

function setBreadCrumb (value) {
  breadCrumb = value
}

function install (Vue, options) {
  Vue.$breadCrumb = getBreadCrumb()
}

export default {
  install
}

export {
  setBreadCrumb,
  getBreadCrumb
}
