<template>
  <div class="bg-main">
    <div style="position: absolute;left: 20px; top: 10px;cursor: pointer;" @click="goBack">
      <img src="/mes3/assets/img/portal/back.svg" style="width: 16px;height: 16px;" alt="">
    </div>
    <div style="padding: 70px 30px 31px 30px;">
      <div class="d-flex">
        <div style="width: 416px">
          <div class="rule-border-1" style="height: 322px">
            <div class="card-title-bg-1 text-center" style="height: 34px;">
              设备状态
            </div>
            <div class="card-in-border-1 mx-4" style="height: 303px;margin-top: -33px;">
              <div class="mx-6 pt-8">
                <div class="info-bg p-4 d-flex justify-content-between" style="height: 120px;">
                  <div class="p-3" style="width: 148px">
                    <div class="d-flex justify-content-between">
                      <img src="@assets/img/dashboard/device-overview/icon-standby.png" style="width: 21px;height: 21px;"/>
                      <div class="text-digital">{{ deviceStatus.standby }}</div>
                    </div>
                    <div class="info-text mt-5">待机中:</div>
                  </div>
                  <div class="p-3" style="width: 148px">
                    <div class="d-flex justify-content-between">
                      <img src="@assets/img/dashboard/device-overview/icon-run.png" style="width: 21px;height: 21px;"/>
                      <div class="text-digital">{{ deviceStatus.run }}</div>
                    </div>
                    <div class="info-text mt-5">运行中:</div>
                  </div>
                </div>
                <div class="info-bg p-4 d-flex justify-content-between mt-4" style="height: 120px;">
                  <div class="p-3" style="width: 148px">
                    <div class="d-flex justify-content-between">
                      <img src="@assets/img/dashboard/device-overview/icon-error.png" style="width: 21px;height: 21px;"/>
                      <div class="text-digital">{{ deviceStatus.error }}</div>
                    </div>
                    <div class="info-text mt-5">故障中:</div>
                  </div>
                  <div class="p-3" style="width: 148px">
                    <div class="d-flex justify-content-between">
                      <img src="@assets/img/dashboard/device-overview/icon-shutdown.png" style="width: 21px;height: 21px;"/>
                      <div class="text-digital">{{ deviceStatus.shutdown }}</div>
                    </div>
                    <div class="info-text mt-5">关机中:</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rule-border-1" style="height: 322px">
            <div class="card-title-bg-1 text-center" style="height: 34px;">
              事件状态
            </div>
            <div class="card-in-border-1 mx-4" style="height: 303px;margin-top: -33px;">
              <div class="mx-8 pt-10">
                <div class="info-bg p-4 d-flex justify-content-between" style="height: 120px;">
                  <div class="p-3" style="width: 148px">
                    <div class="d-flex justify-content-between">
                      <img src="@assets/img/dashboard/device-overview/icon-event.png" style="width: 21px;height: 21px;"/>
                      <div class="text-digital">{{ eventStatus.all }}</div>
                    </div>
                    <div class="info-text mt-5">事件总数:</div>
                  </div>
                  <div class="p-3" style="width: 148px">
                    <div class="d-flex justify-content-between">
                      <img src="@assets/img/dashboard/device-overview/icon-urgent.png" style="width: 21px;height: 21px;"/>
                      <div class="text-digital">{{ eventStatus.urgent }}</div>
                    </div>
                    <div class="info-text mt-5">紧急事件:</div>
                  </div>
                </div>
                <div class="info-bg p-4 d-flex justify-content-between mt-4" style="height: 120px;">
                  <div class="p-3" style="width: 148px">
                    <div class="d-flex justify-content-between">
                      <img src="@assets/img/dashboard/device-overview/icon-wait.png" style="width: 21px;height: 21px;"/>
                      <div class="text-digital">{{ eventStatus.wait }}</div>
                    </div>
                    <div class="info-text mt-5">待响应事件:</div>
                  </div>
                  <div class="p-3" style="width: 148px">
                    <div class="d-flex justify-content-between">
                      <img src="@assets/img/dashboard/device-overview/icon-repair.png" style="width: 21px;height: 21px;"/>
                      <div class="text-digital">{{ eventStatus.repair }}</div>
                    </div>
                    <div class="info-text mt-5">正常维修事件:</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="padding: 59px 35px 0 35px;">
          <div style="width: 962px">
            <div>
              <div class="card-title-bg-3 d-flex" style="height: 39px">
                设备OEE
              </div>
              <div class="rule-border-3 d-flex" style="height: 220px;margin-top: 5px;padding: 0 10px 18px 10px;">
                <div style="width: 25%;padding: 0 13px 0 13px">
                  <div class="card-in-border-4" style="height: 202px;">
                    <div class="text-center pt-1" style="font-size: 18px;color: #FFF">时间开动率</div>
                    <div class="pie-bg-base mx-8 ms-9">
                      <div class="pie-bg mt-6" style="height: 127px;margin-left: -8px">
                        <z-original-echarts ref="oeePie1" :options="oeePieOptions" title="techOnePie" style="height: 130px;width: 153px;"></z-original-echarts>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 25%;padding: 0 13px 0 13px">
                  <div class="card-in-border-4" style="height: 202px;">
                    <div class="text-center pt-1" style="font-size: 18px;color: #FFF">性能开动率</div>
                    <div class="pie-bg-base mx-8 ms-9">
                      <div class="pie-bg mt-6" style="height: 127px;margin-left: -8px">
                        <z-original-echarts ref="oeePie2" :options="oeePieOptions" title="techTwoPie" style="height: 130px;width: 153px;"></z-original-echarts>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 25%;padding: 0 13px 0 13px">
                  <div class="card-in-border-4" style="height: 202px;">
                    <div class="text-center pt-1" style="font-size: 18px;color: #FFF">一次合格率</div>
                    <div class="pie-bg-base mx-8 ms-9">
                      <div class="pie-bg mt-6" style="height: 127px;margin-left: -8px">
                        <z-original-echarts ref="oeePie3" :options="oeePieOptions" title="techThreePie" style="height: 130px;width: 153px;"></z-original-echarts>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 25%;padding: 0 13px 0 13px">
                  <div class="card-in-border-4" style="height: 202px;">
                    <div class="text-center pt-1" style="font-size: 18px;color: #FFF">总OEE</div>
                    <div class="pie-bg-base mx-8 ms-9">
                      <div class="pie-bg mt-6" style="height: 127px;margin-left: -8px">
                        <z-original-echarts ref="oeePie4" :options="oeePieOptions" title="techFourPie" style="height: 130px;width: 153px;"></z-original-echarts>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between" style="margin-top: 26px">
              <div style="width: 462px">
                <div class="card-title-bg-4 d-flex" style="height: 39px;width: 462px;">
                  设备实时状态占比<div class="subtitle" style="padding-top: 7px;margin-left: 7px;">Proportion of equipment</div>
                </div>
                <div class="rule-border-4" style="height: 252px;width: 462px;margin-top: 5px;">
                  <div class="px-4 pt-4">
                    <div class="card-in-border-5" style="height: 199px">
                      <div class="mx-8 d-flex">
                        <div class="pie-bg-2 mt-3" style="height: 179px;width: 221px;">
                          <z-original-echarts ref="deviceStatusPie" :options="deviceStatusPieOptions" title="deviceStatusPie" style="height: 179px;width: 221px;"></z-original-echarts>
                        </div>
                        <div class="p-2 pt-8" style="width: 169px;color: white;font-size: 15px">
                          <div class="d-flex mb-4">
                            <div class="mt-1 me-3" style="width: 20px;height: 13px;background-color: #00fed1;"></div>
                            <div>运行: 62.10%</div>
                          </div>
                          <div class="d-flex mb-4">
                            <div class="mt-1 me-3" style="width: 20px;height: 13px;background-color: rgba(2, 87, 154, 1);"></div>
                            <div>关机: 10.10%</div>
                          </div>
                          <div class="d-flex mb-4">
                            <div class="mt-1 me-3" style="width: 20px;height: 13px;background-color: #fe7b7b;"></div>
                            <div>故障: 18.30%</div>
                          </div>
                          <div class="d-flex mb-4">
                            <div class="mt-1 me-3" style="width: 20px;height: 13px;background-color: #fae273;"></div>
                            <div>待机: 9.00%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="width: 462px">
                <div class="card-title-bg-4 d-flex" style="height: 39px;width: 462px;">
                  设备故障占比<div class="subtitle" style="padding-top: 7px;margin-left: 7px;">Proportion of equipment failure</div>
                </div>
                <div class="rule-border-4" style="height: 252px;width: 462px;margin-top: 5px;">
                  <div class="px-4 pt-4">
                    <div class="card-in-border-5" style="height: 199px">
                      <div class="mx-6 d-flex">
                        <div class="pie-bg-2 mt-3" style="height: 179px;width: 221px;">
                          <z-original-echarts ref="deviceErrorPie" :options="deviceErrorPieOptions" title="deviceErrorPie" style="height: 179px;width: 221px;"></z-original-echarts>
                        </div>
                        <div class="p-2 pt-7" style="width: 182px;color: white;font-size: 15px">
                          <div class="d-flex mb-3">
                            <div class="mt-1 me-3" style="width: 20px;height: 13px;background-color: #F4FA6A;"></div>
                            <div>电流大: 62.10%</div>
                          </div>
                          <div class="d-flex mb-3">
                            <div class="mt-1 me-3" style="width: 20px;height: 13px;background-color: #89F78B;"></div>
                            <div>内燃: 10.10%</div>
                          </div>
                          <div class="d-flex mb-3">
                            <div class="mt-1 me-3" style="width: 20px;height: 13px;background-color: #0EE5C3;"></div>
                            <div>电压高: 18.30%</div>
                          </div>
                          <div class="d-flex mb-3">
                            <div class="mt-1 me-3" style="width: 20px;height: 13px;background-color: #08CFFD;"></div>
                            <div>漏油: 9.00%</div>
                          </div>
                          <div class="d-flex mb-3">
                            <div class="mt-1 me-3" style="width: 20px;height: 13px;background-color: #0063FE;"></div>
                            <div>其他: 9.00%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 416px">
          <div class="rule-border-5" style="height: 233px">
            <div class="card-title-bg-1 text-center" style="height: 34px;">
              产量总览
            </div>
            <div class="card-in-border-2 mx-4" style="height: 215px;margin-top: -33px;">
              <div class="mx-8 pt-9 d-flex justify-content-between">
                <div>
                  <div class="text-base-bg" style="height: 159px;width: 143px;">
                    <div class="text-center pt-1">
                      <img src="@assets/img/dashboard/device-overview/icon-number.png" style="width: 52px;height: 55px;"/>
                    </div>
                    <div class="text-digital text-number text-center pt-11">{{ productionInfo.plan }}</div>
                  </div>
                  <div class="text-center text-base-bottom">今日计划产量</div>
                </div>
                <div>
                  <div class="text-base-bg" style="height: 159px;width: 143px;">
                    <div class="text-center pt-1">
                      <img src="@assets/img/dashboard/device-overview/icon-number.png" style="width: 52px;height: 55px;"/>
                    </div>
                    <div class="text-digital text-number text-center pt-11">{{ productionInfo.real }}</div>
                  </div>
                  <div class="text-center text-base-bottom">今日实际产量</div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 20px">
            <div class="card-title-bg-2 d-flex" style="height: 39px;">
              一周产量统计<div class="subtitle" style="padding-top: 7px;margin-left: 7px;">Weekly output statistics</div>
            </div>
            <div class="rule-border-6" style="height: 348px;margin-top: 7px;">
              <div class="card-in-border-3 mx-4" style="height: 332px;">
                <div class="px-4">
                  <div v-for="(item, i) in weekProduction" :key="i">
                    <div class="d-flex ps-2">
                      <img class="mt-2" src="@assets/img/dashboard/device-overview/arrow.png" style="width: 13px;height: 7px;"/>
                      <div class="ms-2" style="color: #9FA9B1">{{ i }}</div>
                    </div>
                    <div class="process ps-2 d-flex" style="width: 343px;height: 25px;">
                      <div class="value-bar mt-2" :style="`width: ${266*(item/weekMax)}px`"></div>
                      <div class="text-digital ms-1 mt-2" style="font-size: 15px;color: #00FFFF;line-height: 10px;">{{ item }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex" style="margin-top: 31px">
        <div style="width: 416px">
          <div class="card-title-bg-2 d-flex" style="height: 39px;">
            月OEE<div class="subtitle" style="padding-top: 7px;margin-left: 7px;">Proportion of equipment</div>
          </div>
          <div class="rule-border-2" style="height: 252px;margin-top: 10px;">
            <div class="mx-6 chart-base-1">
              <z-original-echarts ref="monthOEEBar" :options="monthOEEBarOptions" title="monthOEEBar" style="height: 234px;width: 397px;"/>
            </div>
          </div>
        </div>
        <div style="margin-left: 35px;width: 1412px;">
          <div class="card-title-bg-5 d-flex" style="height: 39px;">
            设备传感数据<div class="subtitle" style="padding-top: 7px;margin-left: 20px;">Proportion of equipment</div>
          </div>
          <div class="rule-border-7" style="height: 252px;margin-top: 10px;">
            <div class="px-4 pt-4">
              <div class="card-in-border-6" style="height: 199px">
                <div class="mx-6 chart-base-2">
                  <z-original-echarts ref="sensorDataLine" :options="sensorDataLineOptions" title="sensorDataLine" style="height: 215px;width: 1365px;"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 修饰器依赖
import { Component, Vue } from '@min/vue-compatible-decorator'
import zOriginalEcharts from '@/components/dashboard/z-original-echarts'
import * as echarts from 'echarts'
import DashboardService from '@/services/dashboard'

export default
@Component({
  name: 'device-overview',
  components: { zOriginalEcharts }
})
class DeviceOverview extends Vue {
  monthOEEBarOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '1%',
      right: '3%',
      top: '12%',
      bottom: '5%',
      containLabel: true,
      textStyle: {
        color: '#FFF'
      }
    },
    xAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: 'rgba(0, 189, 245, .3)',
          type: 'solid'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: '#FFF'
      }
    },
    yAxis: [
      {
        type: 'value',
        name: 'OEE',
        nameLocation: 'end',
        nameTextStyle: {
          color: '#fff'
        },
        position: 'left',
        min: 0,
        axisLine: {
          show: true,
          lineStyle: {
            color: 'rgba(0, 189, 245, .3)'
          }
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(0, 228, 255, 0.08)',
            type: 'dashed'
          }
        },
        axisLabel: {
          color: '#FFF'
        }
      },
      {
        type: 'value',
        name: '环比增长率',
        nameLocation: 'end',
        nameTextStyle: {
          color: '#fff'
        },
        position: 'right',
        min: -100,
        max: 100,
        axisLine: {
          show: true,
          lineStyle: {
            color: 'rgba(0, 189, 245, .3)'
          }
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(0, 228, 255, 0.08)',
            type: 'dashed'
          }
        },
        axisLabel: {
          color: '#FFF',
          formatter: '{value}%'
        }
      }
    ],
    dataset: {
      dimensions: ['month', 'value', 'rate'],
      source: []
    },
    dataSource: {
      type: 'promise',
      promise: this.getMonthOeeInfo
    },
    series: [
      {
        name: 'OEE',
        type: 'bar',
        barWidth: 10,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(0, 228, 255, 1)'
            },
            {
              offset: 1,
              color: 'rgba(0, 228, 255, 0.2)'
            }
          ]),
          borderRadius: [8, 8, 0, 0]
        }
      },
      {
        name: '环比增长率',
        type: 'line',
        yAxisIndex: 1,
        itemStyle: {
          color: '#F3CC27'
        }
      }
    ]
  }

  sensorDataLineOptions = {
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      left: '1%',
      right: '3%',
      top: '12%',
      bottom: '10%',
      containLabel: true,
      textStyle: {
        color: '#FFF'
      }
    },
    xAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: 'rgba(0, 189, 245, .3)',
          type: 'solid'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: '#FFF'
      }
    },
    yAxis: [
      {
        type: 'value',
        name: '温度',
        nameLocation: 'end',
        nameTextStyle: {
          color: '#fff'
        },
        min: -40,
        position: 'left',
        axisLine: {
          show: true,
          lineStyle: {
            color: 'rgba(0, 189, 245, .3)'
          }
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(0, 228, 255, 0.08)',
            type: 'dashed'
          }
        },
        axisLabel: {
          color: '#FFF'
        }
      },
      {
        type: 'value',
        name: '湿度',
        nameLocation: 'end',
        nameTextStyle: {
          color: '#fff'
        },
        position: 'right',
        min: 0,
        max: 100,
        axisLine: {
          show: true,
          lineStyle: {
            color: 'rgba(0, 189, 245, .3)'
          }
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(0, 228, 255, 0.08)',
            type: 'dashed'
          }
        },
        axisLabel: {
          color: '#FFF',
          formatter: '{value}%'
        }
      }
    ],
    dataset: {
      dimensions: ['time', 'wd', 'sd'],
      source: []
    },
    dataSource: {
      type: 'sql',
      sql: '1908'
    },
    series: [
      {
        name: '温度',
        type: 'line',
        itemStyle: {
          color: 'rgba(0, 228, 255, 1)'
        }
      },
      {
        name: '湿度',
        type: 'line',
        yAxisIndex: 1,
        itemStyle: {
          color: '#F3CC27'
        }
      }
    ]
  }

  oeePieOptions = {
    color: ['rgba(2, 87, 154, 1)', 'rgba(0, 254, 255, 0.7)'],
    grid: {
      left: '1%',
      right: '1%',
      top: '1%',
      bottom: '1%',
      containLabel: true
    },
    series: [
      {
        type: 'pie',
        radius: ['80%', '95%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 5,
          borderColor: '#092033',
          borderWidth: 5
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{@value}%',
          fontStyle: 'italic',
          fontFamily: 'ds-digib',
          borderWidth: 0,
          fontSize: 20,
          color: 'rgba(0, 254, 255, 1)',
          fontWeight: 'bold'
        },
        labelLine: {
          show: false
        },
        emphasis: {
          scale: false
        }
      }
    ],
    dataset: {
      dimensions: ['name', 'value'],
      source: []
    }
  }

  deviceStatusPieOptions = {
    tooltip: {
      trigger: 'item'
    },
    grid: {
      left: '1%',
      right: '3%',
      top: '12%',
      bottom: '5%',
      containLabel: true,
      textStyle: {
        color: '#FFF'
      }
    },
    color: ['#00fed1', 'rgba(2, 87, 154, 1)', '#fe7b7b', '#fae273'],
    series: [
      {
        name: '状态',
        type: 'pie',
        radius: ['75%', '95%'],
        itemStyle: {
          borderRadius: 5,
          borderColor: '#092033',
          borderWidth: 5
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{@name}\n{@rate}%',
          fontFamily: 'ds-digib',
          borderWidth: 0,
          fontSize: 20,
          color: '#FFF',
          fontWeight: 'bold'
        },
        tooltip: {
          formatter: '{b}:{d}%'
        }
      }
    ],
    dataset: {
      dimensions: ['name', 'rate']
    },
    dataSource: {
      type: 'sql',
      sql: '1909'
    }
  }

  deviceErrorPieOptions = {
    tooltip: {
      trigger: 'item'
    },
    grid: {
      left: '1%',
      right: '3%',
      top: '12%',
      bottom: '5%',
      containLabel: true,
      textStyle: {
        color: '#FFF'
      }
    },
    color: ['#F4FA6A', '#89F78B', '#0EE5C3', '#08CFFD', '#0063FE'],
    series: [
      {
        name: '状态',
        type: 'pie',
        radius: ['75%', '95%'],
        itemStyle: {
          borderRadius: 5,
          borderColor: '#092033',
          borderWidth: 5
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{@name}\n{@rate}%',
          fontFamily: 'ds-digib',
          borderWidth: 0,
          fontSize: 20,
          color: '#FFF',
          fontWeight: 'bold'
        },
        tooltip: {
          formatter: '{b}:{d}%'
        }
      }
    ],
    dataset: {
      dimensions: ['name', 'rate']
    },
    dataSource: {
      type: 'sql',
      sql: '1907'
    }
  }

  timer = null

  async mounted () {
    await this.init()
    this.timer = setInterval(this.update, 300000)
  }

  destroyed () {
    clearInterval(this.timer)
  }

  async init () {
    const promiseList = [this.getDeviceStatus(), this.getEventStatus(), this.getProductionInfo(), this.getWeekProduction(), this.initOeePie()]
    await Promise.all(promiseList)
  }

  async update () {
    const promiseList = [this.$refs.monthOEEBar.updateData(), this.$refs.sensorDataLine.updateData(), this.$refs.deviceErrorPie.updateData(), this.$refs.deviceStatusPie.updateData(), this.init()]
    await Promise.all(promiseList)
  }

  deviceStatus = { standby: 0, run: 0, error: 0, shutdown: 0 }

  async getDeviceStatus () {
    const data = (await DashboardService.getData(1901))[0]
    this.deviceStatus = {
      standby: data.dj || 0,
      run: data.yx || 0,
      error: data.gz || 0,
      shutdown: data.gj || 0
    }
  }

  eventStatus = { all: 0, urgent: 0, wait: 0, repair: 0 }

  async getEventStatus () {
    const data = (await DashboardService.getData(1904))[0]
    this.eventStatus = {
      all: data.sjzs || 0,
      urgent: data.jjsj || 0,
      wait: data.dxysj || 0,
      repair: data.zcwxsj || 0
    }
  }

  productionInfo = { plan: 0, real: 0 }

  async getProductionInfo () {
    const data = (await DashboardService.getData(1905))[0]
    this.productionInfo = {
      plan: data.jrjhcl || 0,
      real: data.jrsjcl || 0
    }
  }

  weekProduction = {}

  weekMax = 0

  async getWeekProduction () {
    const info = {}
    let max = 0
    const data = (await DashboardService.getData(1906))
    for (const i in data) {
      max = data[i].value > max ? data[i].value : max
      info[data[i].date] = data[i].value
    }
    this.weekProduction = info
    this.weekMax = max
  }

  async getMonthOeeInfo () {
    const data = await DashboardService.getData(1903)
    for (const i in data) {
      data[i].rate = (data[i - 1] ? Math.round(((parseFloat(data[i].value) - parseFloat(data[i - 1].value)) / parseFloat(data[i - 1].value)) * 100) : null)
    }
    return data
  }

  async initOeePie () {
    const data = (await DashboardService.getData(1902))[0]
    this.$refs.oeePie1.updateData(
      [
        { name: '', value: 100 - parseInt(data.sjkdl) },
        { name: '时间开动率', value: parseInt(data.sjkdl) }
      ]
    )
    this.$refs.oeePie2.updateData([
      { name: '', value: 100 - parseInt(data.xnkdl) },
      { name: '性能开动率', value: parseInt(data.xnkdl) }
    ])
    this.$refs.oeePie3.updateData([
      { name: '', value: 100 - parseInt(data.ychgl) },
      { name: '一次合格率', value: parseInt(data.ychgl) }
    ])
    this.$refs.oeePie4.updateData([
      { name: '', value: 100 - parseInt(data.zoee) },
      { name: '总OEE', value: parseInt(data.zoee) }
    ])
  }

  goBack () {
    // 返回portal
    window.location.href = '/portal'
  }
}
</script>
<style lang="scss" scoped>
.bg-main {
  background-image: url("@assets/img/dashboard/device-overview/bg-main.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: alibaba-puhuiti;
  .rule-border-1 {
    background-image: url("@assets/img/dashboard/device-overview/rule-border-1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .card-title-bg-1 {
      background-image: url("@assets/img/dashboard/device-overview/card-title-bg-1.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      line-height: 18px;
      color: #E8FBFF;
      font-family: hylingxintij;
      padding-top: 7px;
    }
    .card-in-border-1 {
      background-image: url("@assets/img/dashboard/device-overview/card-in-border-1.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .info-bg {
        background-image: url("@assets/img/dashboard/device-overview/info-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .card-title-bg-2 {
    background-image: url("@assets/img/dashboard/device-overview/card-title-bg-2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 18px;
    line-height: 18px;
    color: #E8FBFF;
    font-family: hylingxintij;
    padding-top: 9px;
    padding-left: 48px;
  }
  .rule-border-2 {
    background-image: url("@assets/img/dashboard/device-overview/rule-border-2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .chart-base-1 {
      background-image: url("@assets/img/dashboard/device-overview/chart-base-1.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
    }
  }
  .card-title-bg-3 {
    background-image: url("@assets/img/dashboard/device-overview/card-title-bg-3.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 18px;
    line-height: 18px;
    color: #E8FBFF;
    font-family: hylingxintij;
    padding-top: 9px;
    padding-left: 48px;
  }
  .rule-border-3 {
    background-image: url("@assets/img/dashboard/device-overview/rule-border-3.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .card-in-border-4 {
      background-image: url("@assets/img/dashboard/device-overview/card-in-border-4.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .pie-bg-base {
        background-image: url("@assets/img/dashboard/device-overview/pie-bg-base.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .pie-bg {
          background-image: url("@assets/img/dashboard/device-overview/pie-bg.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .card-title-bg-4 {
    background-image: url("@assets/img/dashboard/device-overview/card-title-bg-4.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 18px;
    line-height: 18px;
    color: #E8FBFF;
    font-family: hylingxintij;
    padding-top: 9px;
    padding-left: 48px;
  }
  .rule-border-4 {
    background-image: url("@assets/img/dashboard/device-overview/rule-border-4.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .card-in-border-5 {
      background-image: url("@assets/img/dashboard/device-overview/card-in-border-5.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .pie-bg-2 {
        background-image: url("@assets/img/dashboard/device-overview/pie-bg-2.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .rule-border-5 {
    background-image: url("@assets/img/dashboard/device-overview/rule-border-5.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .card-title-bg-1 {
      background-image: url("@assets/img/dashboard/device-overview/card-title-bg-1.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      line-height: 18px;
      color: #E8FBFF;
      font-family: hylingxintij;
      padding-top: 7px;
    }
    .card-in-border-2 {
      background-image: url("@assets/img/dashboard/device-overview/card-in-border-2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .text-base-bg {
        background-image: url("@assets/img/dashboard/device-overview/text-base-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .text-number {
          font-size: 30px;
          font-weight: 400;
          background-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(43, 240, 252, 0.8));
          background-clip: text;
          -webkit-background-clip:text;
          color: transparent;
          line-height: 30px;
        }
      }
      .text-base-bottom {
        font-size: 14px;
        font-weight: bold;
        color: #E8FBFF;
        line-height: 18px;
      }
    }
  }
  .rule-border-6 {
    background-image: url("@assets/img/dashboard/device-overview/rule-border-6.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .card-in-border-3 {
      background-image: url("@assets/img/dashboard/device-overview/card-in-border-3.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .process {
        background-image: url("@assets/img/dashboard/device-overview/process-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .value-bar {
          height: 9px;
          background: linear-gradient(90deg, rgba(0, 255, 255, 0) 0%, #00FFFF 100%);
          border-radius: 0px 4px 4px 0px;
        }
      }
    }
  }
  .card-title-bg-5 {
    background-image: url("@assets/img/dashboard/device-overview/card-title-bg-5.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 18px;
    line-height: 18px;
    color: #E8FBFF;
    font-family: hylingxintij;
    padding-top: 9px;
    padding-left: 48px;
  }
  .rule-border-7 {
    background-image: url("@assets/img/dashboard/device-overview/rule-border-7.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .card-in-border-6 {
      background-image: url("@assets/img/dashboard/device-overview/card-in-border-6.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .chart-base-2 {
        background-image: url("@assets/img/dashboard/device-overview/chart-base-2.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom;
      }
    }
  }
}
.subtitle {
  font-size: 10px;
  color: #9FA9B1;
  line-height: 12px;
}
.text-digital {
  font-size: 26px;
  font-family: ds-digib;
  font-weight: 400;
  color: #00FEFF;
  line-height: 18px;
}
.info-text {
  font-weight: 400;
  color: #E8FBFF;
  line-height: 18px;
}
</style>
