import IframeContainer from '@/components/container/iframe.vue'

export default [
  {
    path: '/operation/datasource',
    name: 'operation-datasource',
    component: IframeContainer
  },
  {
    path: '/operation/dataset',
    name: 'operation-dataset',
    component: IframeContainer
  },
  {
    path: '/operation/dataset/add',
    name: 'operation-dataset-add',
    component: IframeContainer
  },
  {
    path: '/operation/panel',
    name: 'operation-panel',
    component: IframeContainer
  },
  {
    path: '/operation/screen',
    name: 'operation-screen',
    component: IframeContainer
  }
]
