<template>
  <vxe-table ref="tableInstance"
             :data="items"
             :max-height="500"
             :column-config="{ resizable: true }"
             :expand-config="expandConfig"
             border="inner"
             class="border-top border-gray-200"
  >
    <vxe-column type="seq" title="序号" width="64" fixed="left" align="center" :col-id="$_INDEX_KEY">
      <template v-if="$slots[$_INDEX_KEY] || $slots[`cell(${$_INDEX_KEY})`]" v-slot="slotParams">
        <slot :name="$_INDEX_KEY" :for="$_INDEX_KEY" :data="{ item: slotParams.row, value: slotParams.row[$_INDEX_KEY], index: slotParams.$rowIndex }" :form="{ key: $_INDEX_KEY}" />
      </template>
    </vxe-column>
    <vxe-column v-if="expandMode" type="expand" width="50">
      <template #content="{ row }">
        <slot name="expand-content" :data="{ data: row }" />
      </template>
    </vxe-column>
    <template :key="`table-column-${field.key}`" v-for="field in computedFieldConfig">
      <vxe-column
        v-if="field.key !== $_INDEX_KEY"
        min-width="150"
        show-overflow
        :sortable="!!field.sort"
        :formatter="parseFormatter(field)"
        :fixed="field.fixed ?? undefined"
        :title="field.label ?? field.key"
        :field="field.key"
        :col-id="field.key"
      >
        <template v-if="$slots[field.key] || $slots[`cell(${field.key})`]" v-slot="slotParams">
          <slot :name="field.key" :for="field.key" :data="{ item: slotParams.row, value: slotParams.row[field.key], index: slotParams.$rowIndex }" :form="field.form" />
        </template>
      </vxe-column>
    </template>
  </vxe-table>
  <slot name="footer" :items="data" :fields="computedFieldConfig" />
</template>

<script setup>
import { defineProps, onBeforeMount, ref, watch } from 'vue'

const props = defineProps({
  config: {},
  data: { type: [Array, Object], default: () => [] },
  isStaticMode: { type: Boolean, default: true },
  name: { type: String, default: '' },
  // 是否开启展开行功能
  expandMode: { type: Boolean, default: false },
  expandConfig: { type: Object }
})

const computedFieldConfig = ref([])
const items = ref([])
const $_INDEX_KEY = ref('index')

watch(() => props.data, value => {
  const data = []
  for (const n in value) {
    data.push(value[n])
  }
  items.value = data
}, { deep: true, immediate: true })

function init () {
  computedFieldConfig.value = props.config.fields ?? []
  parseIndexColumn()
}

function parseIndexColumn () {
  for (const n in computedFieldConfig.value) {
    if (computedFieldConfig.value[n].key === 'index' || computedFieldConfig.value[n].type === 'index') {
      $_INDEX_KEY.value = computedFieldConfig.value[n].key
      return
    }
  }
}

function parseFormatter (field) {
  return ({ cellValue, row, column }) => {
    return translateValue(cellValue, row, column, field)
  }
}

function translateValue (data, row, column, field) {
  let value = ''
  if (field.format) {
    value = row[field.key] !== undefined ? field.format(row[field.key], row) : undefined
  } else {
    value = row[field.key]
  }
  return value ? value.toString() : '-'
}

onBeforeMount(() => {
  init()
})
</script>
