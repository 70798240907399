<template>
  <div v-if="breadCrumb.length > 0" id="kt_app_breadcrumb">
    <ul class="breadcrumb breadcrumb-separatorless fs-6 mt-0">
      <template v-for="(item, index) in breadCrumb" :key="`bread-crumb-label-${index}`">
        <li class="breadcrumb-item text-gray-600 lh-1">{{ item.meta.title }}</li>
        <li v-if="index < breadCrumb.length - 1" class="breadcrumb-item text-gray-600">/</li>
      </template>
    </ul>
  </div>
</template>
<script>
import { Vue, Component, Prop } from '@min/vue-compatible-decorator'

@Component()
export default class Breadcrumb extends Vue {
  @Prop({ type: Array, default: [] }) breadCrumb
}
</script>
