<template>
  <vxe-table ref="tableInstance"
             :data="$_DISPLAY_VALUE"
             :max-height="500"
             :column-config="{ resizable: true }"
             border="inner"
             class="border-top border-gray-200"
  >
    <vxe-column type="seq" title="序号" width="64" fixed="left" align="center" :col-id="$_INDEX_KEY">
      <template v-if="$slots[$_INDEX_KEY] || $slots[`cell(${$_INDEX_KEY})`]" v-slot="slotParams">
        <slot :name="$_INDEX_KEY" :for="$_INDEX_KEY" :data="{ item: slotParams.row, value: slotParams.row[$_INDEX_KEY], index: slotParams.$rowIndex }" :form="{ key: $_INDEX_KEY}" />
      </template>
    </vxe-column>
    <template :key="`table-column-${column.key}`" v-for="column in computedFields">
      <vxe-column v-if="['do', 'action'].includes(column.key)" title="操作" :width="cellOptionWidth" fixed="right" col-id="do">
        <template v-slot="slotParams">
          <div class="w-100" :ref="el => cellOptionWrapper[slotParams.$rowIndex] = el">
            <cell-option @function="changeOne(slotParams.$rowIndex)">编辑</cell-option>
            <cell-option variant="danger" @function="deleteOne(slotParams.$rowIndex)">删除</cell-option>
          </div>
        </template>
      </vxe-column>
      <vxe-column v-else-if="column.key !== $_INDEX_KEY"
                  min-width="150"
                  show-overflow
                  :sortable="!!column.sort"
                  :formatter="parseFormatter(column)"
                  :fixed="column.fixed ?? undefined"
                  :title="column.label ?? column.key"
                  :field="column.key"
                  :col-id="column.key"
      >
        <template v-if="$slots[column.key] || $slots[`cell(${column.key})`]" v-slot="slotParams">
          <slot :name="column.key" :value="slotParams.row[column.key]" :data="{ item: slotParams.row, value: slotParams.row[column.key] }" :item="slotParams.row" :index="slotParams.$rowIndex" />
        </template>
      </vxe-column>
    </template>
  </vxe-table>
  <div class="d-grid gap-2">
    <b-button v-if="edit && !noButton" ref="add" variant="outline" class="btn-outline-dashed text-primary" size="lg" @click="addOne">
      新增一行
    </b-button>
  </div>
  <slot name="modal" :configs="computedConfig" :change="change" :back-fill="backFill" :submit="submit" :modal-open="modalOpen" :modal-close="modalClose">
    <form-modal :modal-id="modalId" :modal-open="modalOpen" :configs="computedConfig" :change="change" :back-fill="backFill" @submit="submit" @closeModal="modalClose" />
  </slot>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import FormModal from './form-modal.vue'
import { isEqual } from '../utils/common'

const emit = defineEmits(['update:modelValue', 'addOne', 'changeOne', 'deleteOne'])
const props = defineProps({
  fields: Object,
  modelValue: { type: Array, default: () => [] },
  for: String,
  formConfig: Object,
  edit: { type: Boolean, default: true },
  transformRule: {},
  backwardTransformRule: {},
  modalId: { type: String, default: 'table' },
  noButton: { type: Boolean, default: false },
  config: {}
})
const $_INDEX_KEY = ref('index')
const $_DISPLAY_VALUE = ref([])
const $_LIST = ref(props.modelValue)
const change = ref(false)
const backFill = ref({})
const $_INDEX = ref(0)
const modalOpen = ref(false)
const cellOptionWrapper = ref([])
const cellOptionWidth = ref(100)
const computedFields = ref(props.fields)
const computedConfig = ref(props.formConfig)

watch(() => props.config, config => {
  computedFields.value = config?.fields ?? props.fields
  computedConfig.value = config?.formConfig ?? props.formConfig
}, { immediate: true })

watch($_LIST, value => {
  console.log(value)
  const data = [...$_LIST.value]
  for (const i in data) {
    data[i] = transformData(data[i])
  }
  $_DISPLAY_VALUE.value = data
  emit('update:modelValue', value)
}, { deep: true, immediate: true })

watch(() => props.modelValue, value => {
  if (!isEqual(value, $_LIST.value)) {
    $_LIST.value = [...value]
  }
}, { immediate: true })

watch(cellOptionWrapper, value => {
  const minWidth = 100
  let width = 0
  for (const i in value) {
    const options = value[i].querySelectorAll('.cell-option')
    let currentWidth = 0
    for (const n in options) {
      if (options[n].clientWidth) {
        currentWidth += options[n].clientWidth + 12
      }
    }
    currentWidth += 32
    width = (currentWidth > width ? currentWidth : width)
  }
  cellOptionWidth.value = (minWidth > width ? minWidth : width)
  // tableInstance.value.refreshColumn()
}, { deep: true, once: true })

function parseFormatter (field) {
  return ({ cellValue, row, column }) => {
    return translateValue(cellValue, row, column, field)
  }
}

function translateValue (data, row, column, field) {
  let value = ''
  if (field.format) {
    value = row[field.key] !== undefined ? field.format(row[field.key], row) : undefined
  } else {
    value = row[field.key]
  }
  return value ? value.toString() : '-'
}

function modalClose () {
  modalOpen.value = false
}

function submit (data) {
  if (change.value) {
    $_LIST.value[$_INDEX.value] = data
    $_DISPLAY_VALUE.value[$_INDEX.value] = transformData(data)
  } else {
    $_LIST.value.push(data)
    $_DISPLAY_VALUE.value.push(transformData(data))
  }
  modalOpen.value = false
}

function addOne () {
  backFill.value = {}
  change.value = false
  modalOpen.value = true
  emit('addOne')
}

function changeOne (index) {
  change.value = true
  backFill.value = transformData($_LIST.value[index], 'backward')
  $_INDEX.value = index
  modalOpen.value = true
  emit('changeOne')
}

function deleteOne (index) {
  $_LIST.value.splice(index, 1)
  $_DISPLAY_VALUE.value.splice(index, 1)
  emit('deleteOne')
}

function transformData (item, type = 'forward') {
  const rule = (type === 'forward' ? props.transformRule : props.backwardTransformRule)
  let display = {}

  if (rule) {
    switch (typeof rule) {
      case 'function':
        display = rule(item)
        break
      case 'object':
        for (const i in item) {
          if (item[i] && item[i] !== '') {
            if (typeof rule[i] === 'object') {
              display[i] = rule[i][item[i]]
            } else if (typeof rule[i] === 'function') {
              display[i] = rule[i](item[i])
            } else {
              display[i] = item[i]
            }
          } else {
            display[i] = '-'
          }
        }
        break
      default:
        return item
    }
  } else {
    return item
  }
  return display
}

/* onMounted(() => {
  init()
}) */
</script>
