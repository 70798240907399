<template>
  <div
    class="app-navbar-item overflow-hidden action-thumb-hover-wrapper"
    :class="[...activeClassObject, ...showActionClassObject, ...actionItemClassObject, !showTransition ? '' : 'transition-3']"
    :style="{...showActionObjectObject, ...actionItemStyleObject}"
    :dir="showAction ? '' : 'rtl'"
    @click.prevent="toggleExpand"
  >
    <div v-if="!showAction" class="cursor-pointer flex-grow-1 h-100" :class="actionThumbClassObject">
      <slot name="action-thumb" />
    </div>
    <div :id="`action-item-${actionItemId}`" v-if="showAction && !noActionExpand" class="d-block menu menu-sub menu-column menu-gray-700" :class="actionExpandClassObject" v-bind="actionExpendAttributes" :style="actionExpandStyleObject">
      <slot name="action-expand" />
    </div>
  </div>
</template>
<script>
import { Vue, Prop, Component, Watch } from '@min/vue-compatible-decorator'
import { v4 as uuid } from 'uuid'

@Component({
  emits: ['actionShow', 'actionShown', 'actionHide', 'actionHidden']
})
class ActionItem extends Vue {
  @Prop({ type: [String, Number], default: 40 }) width
  @Prop({ type: [String, Number], default: 40 }) height
  @Prop({ type: [String, Number], default: 150 }) hoverWidth
  @Prop({ type: [String, Number], default: 0 }) activeWidth
  @Prop({ type: [String, Number], default: 150 }) expandWidth
  @Prop({ type: [String, Number], default: 150 }) expandHeight
  @Prop({ type: [String, Array, Object], default: '' }) actionThumbClass
  @Prop({ type: [String, Array, Object], default: '' }) actionExpandClass
  @Prop({ type: [String, Array, Object], default: '' }) actionExpandStyle
  @Prop({
    type: Object,
    default: () => {
    }
  }) actionExpendAttributes

  @Prop({ type: String, default: '' }) variant
  @Prop({ type: Boolean, default: false }) active
  @Prop({ type: Boolean, default: false }) noActionExpand

  actionItemId = uuid()
  showAction = false
  showTransition = true

  get actionItemClassObject () {
    const classObject = []
    if (!isNaN(this.height)) {
      classObject.push(`h-${this.height}px`)
    }
    classObject.push(`w-${this.width}px`)
    classObject.push(`w-hover-${this.hoverWidth}px`)
    // classObject.push(`bg-hover-blur-${this.variant}`)

    return classObject
  }

  get actionItemStyleObject () {
    const styleObject = {}
    if (isNaN(this.height)) {
      styleObject.height = `${this.height} !important`
    }
    return styleObject
  }

  get activeClassObject () {
    const classObject = []
    if (this.active) {
      classObject.push('action-item-active-wrapper')
      let activeWidth = this.activeWidth
      if (activeWidth === '' || activeWidth === 0) {
        activeWidth = this.hoverWidth
      }
      classObject.push(`w-active-${activeWidth}px`)
    }
    return classObject
  }

  get showActionClassObject () {
    const classObject = []
    if (this.showAction) {
      classObject.push('action-item-active-wrapper')
      classObject.push('align-items-baseline')
      classObject.push('shadow')
      classObject.push(`bg-blur-${this.variant}`)
      classObject.push(`w-${this.expandWidth}px`)
      classObject.push(`w-hover-${this.expandWidth}px`)
      if (!isNaN(this.expandHeight)) {
        classObject.push(`h-${this.expandHeight}px`)
      }
    }

    return classObject
  }

  get showActionObjectObject () {
    const styleObject = {}
    if (this.showAction) {
      if (isNaN(this.expandHeight)) {
        styleObject.height = `${this.expandHeight} !important`
      }
    }

    return styleObject
  }

  get actionThumbClassObject () {
    if (this.actionThumbClass instanceof String) {
      return [this.actionThumbClass]
    } else return this.actionThumbClass
  }

  get actionExpandClassObject () {
    if (this.actionExpandClass instanceof String) {
      return [this.actionExpandClass]
    } else return this.actionExpandClass
  }

  get actionExpandStyleObject () {
    if (this.actionExpandStyle instanceof String) {
      return [this.actionExpandStyle]
    } else return this.actionExpandStyle
  }

  @Watch('showAction')
  watchShowAction (value) {
    if (value && !this.noActionExpand) {
      this.showTransition = false
      this.$emit('actionShow', this)
      this.$nextTick(() => {
        this.$emit('actionShown', this)
      })
    } else if (!value) {
      setTimeout(() => {
        this.showTransition = true
      }, 100)
    }
  }

  toggleExpand () {
    try {
      if (this.noActionExpand) {
        return
      }

      const closeEl = (e) => {
        // 监测点击事件，点击userMenu之外的区域，关闭userMenu, userMenu的id为userMenu
        const actionEl = document.getElementById(`action-item-${this.actionItemId}`)
        if (actionEl && e && !actionEl.contains(e.target)) {
          this.showAction = false
          document.removeEventListener('click', closeEl)
        }
      }

      if (this.showAction) {
        closeEl()
      } else {
        this.showAction = true
        setTimeout(() => {
          document.addEventListener('click', closeEl)
        }, 300)
      }
    } catch (e) {
      console.log(e)
    }
  }

  hide () {
    this.showAction = false
    this.$emit('actionHide', this)
    this.$nextTick(() => {
      this.$emit('actionHidden', this)
    })
  }
}

export default ActionItem
</script>
