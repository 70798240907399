import { Restful, IntelliApi } from '@/services/intelli-api'

/**
 * IntelliApi Class
 * @class user-group
  **/
@Restful({
  name: 'user-group',
  path: 'system/user-group'
})
class UserGroupService extends IntelliApi {}

export default new UserGroupService()
