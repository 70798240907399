<template>
  <b-modal id="modal-component" modal-class="modal-component" :size="size" v-model="showModalComponent" :title="modalTitle" title-class="fs-3 text-gray-700" hide-footer>
    <div class="scroll px-5" :style="computedStyle">
      <component :is="currentModal" :modal-component="true" :route="route" />
    </div>
  </b-modal>
</template>

<script>
import { Vue, Component } from '@min/vue-compatible-decorator'
import { defineAsyncComponent, shallowRef } from 'vue'
import Event from '@/utils/event'
import { routes } from '@/router'
import { useMenuStore } from '@/stores/menu'

function throttle (fn, time) {
  // 定义一个标记
  let flag = true
  // 闭包
  return function (e) {
    if (!flag) return
    flag = false
    setTimeout(() => {
      fn.apply(this, arguments)
      flag = true
    }, time)
  }
}

@Component()
export default class ModalComponent extends Vue {
  currentModal = shallowRef()
  showModalComponent = false
  size = 'lg'
  modalTitle = ''
  route = {}
  clientHeight = 0

  get computedStyle () {
    return {
      height: `${this.clientHeight > 200 ? (this.clientHeight - 200) : 0}px`
    }
  }

  mounted () {
    Event.on('LAYOUT:ACTION:MODAL:OPEN', async (routeName, route) => {
      const menu = useMenuStore().getMenuByName(route.name)
      if (menu.id && menu.id !== '' && menu.id !== 0) {
        this.modalTitle = menu.meta.title
      }
      for (const n in routes) {
        if (routes[n].name === routeName) {
          this.currentModal = defineAsyncComponent(routes[n].components.default)
          this.route = routes[n]
          this.showModalComponent = true
          break
        }
      }
    })
    Event.on('LAYOUT:ACTION:MODAL:RESIZE', (size) => {
      this.size = size
    })

    Event.on('LAYOUT:ACTION:MODAL:CLOSE', () => {
      this.showModalComponent = false
    })

    this.clientHeight = document.body.clientHeight
    window.addEventListener('resize', () => throttle(() => {
      this.clientHeight = document.body.clientHeight
    }, 200))
  }

  unmounted () {
    Event.remove('LAYOUT:ACTION:MODAL:RESIZE', (size) => {
      this.size = size
    })

    Event.remove('LAYOUT:ACTION:MODAL:CLOSE', () => {
      this.showModalComponent = false
    })
  }
}
</script>
