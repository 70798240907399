import Event from './event'
import { v4 as uuid } from 'uuid'

const Conditions = {}

class Condition {
  status = false
  runner = {}
  name = ''

  constructor (name) {
    this.name = name
  }

  run (runFunction, caseId = '') {
    if (!caseId || caseId === '') {
      this.runner[uuid()] = runFunction
    } else {
      this.runner[caseId] = runFunction
    }
    Event.emit(`CONDITION.${this.name}`, 'trigger')
    return this
  }

  set () {
    this.status = true
    Event.emit(`CONDITION.${this.name}`, 'set')
    return this
  }
}

function run (name) {
  for (const n in Conditions[name].runner) {
    Conditions[name].runner[n]()
  }
}

function initCondition (name) {
  if (!Condition[name]) {
    Conditions[name] = new Condition(name)
    Event.on(
      `CONDITION.${name}`, ['set', 'trigger'],
      () => {
        run(name)
      })
  }
  return Conditions[name]
}

export { initCondition }
