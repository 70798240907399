import { Restful, IntelliApi } from '@/services/intelli-api'

/**
 * IntelliApi Class
 * @class configuration
 **/
@Restful({
  name: 'configuration',
  path: 'system/data/config'
})
class ConfigService extends IntelliApi {}

export default new ConfigService()
