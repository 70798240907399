<template>
  <b-modal v-model="openModal" :id="modalId" ref="FormModal" hide-header size="xl" centered no-close-on-backdrop @shown="onShown">
    <slot v-bind="{ submit: add, configs, value: $_VALUE, close, change, formRef }">
      <zero-form v-if="openModal" ref="formRef" :configs="configs" :change="change" v-model="$_VALUE" :show-button="false" />
    </slot>
    <template #footer>
      <b-button variant="dark" size="lg" class="w-100px me-4" @click="close">关闭</b-button>
      <b-button variant="primary" size="lg" class="w-100px" @click="add">提交</b-button>
    </template>
  </b-modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'

const emit = defineEmits(['closeModal', 'cancel', 'close', 'show', 'submit'])
const props = defineProps({
  modalId: { type: String },
  configs: {},
  backFill: { type: Object, default: () => { return {} } },
  change: { type: Boolean, default: false },
  modalOpen: { type: Boolean, default: false }
})

const $_VALUE = ref({})

const formRef = ref()
const openModal = ref(false)

async function add () {
  if (formRef.value) {
    formRef.value.submit(data => emit('submit', data))
  }
  openModal.value = false
}

function onShown () {
  emit('show', props.configs, $_VALUE.value, props.modalId)
}

function close () {
  /* if (formRef.value) {
    formRef.value.cancel()
  } */
  openModal.value = false
  emit('closeModal')
}

watch(() => props.modalOpen, value => {
  openModal.value = value
})

watch(() => props.backFill, value => {
  console.log(JSON.parse(JSON.stringify(value)))
  $_VALUE.value = value
})
</script>
