/*
 * @Author: xuzhitao
 * @Date: 2023-01-04 09:20:06
 * @LastEditors: xuzhitao
 * @LastEditTime: 2023-04-20 09:15:27
 * @FilePath: /intellidevice-web-app/src/stores/version.ts
 */
import Package from '@root/package.json'
import { defineStore } from 'pinia'

function _INIT () {
  let store = {}
  store = Package.about || {}
  store.milestone = Package.milestone || ''
  store['app-version'] = Package.version || ''
  store['build-version'] = Package.build || ''
  store.tenant = Package.tenant || {}

  const envData = process.env

  for (const n in envData) {
    if (n.indexOf('PACKAGE_') === 0) {
      const key = n.substring(8).replace(/_/g, '-').toLowerCase()
      store[key] = envData[n]
    }
  }
  return store
}
export const useVersionStore = defineStore({
  id: 'version',
  state: () => ({
    store: _INIT()
  }),
  getters: {
    data () {
      return this.store
    }
  },
  actions: {
    value (key) {
      return this.store[key] || undefined
    }
  }
})
