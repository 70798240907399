import { Restful, RestfulRequest, IntelliApi } from '@/services/intelli-api'

/**
 * IntelliApi Class
 * @class event-abnormal
 **/
@Restful({
  name: 'event-abnormal',
  path: 'common/abnormal-event'
})
class EventAbnormalService extends IntelliApi {
  @RestfulRequest({
    method: 'GET',
    path: '?sort=-time',
    parameters: ['data']
  })
  getList (data) {}
}

export default new EventAbnormalService()
