<!--
 * @Author: xuzhitao
 * @Date: 2022-12-30 09:35:21
 * @LastEditors: xuzhitao
 * @LastEditTime: 2023-01-05 11:18:14
 * @FilePath: /mirrors.in-mes.com-ui/src/components/zero-vue/components/icon-svg.vue
-->
<template>
  <span :class="computedClass" v-bind="$attrs">
    <inline-svg :title="title" :src="computedSrc" />
  </span>
</template>

<script>
import { Component, Vue, Prop } from '@min/vue-compatible-decorator'

@Component({})
class IconSvg extends Vue {
  @Prop({ type: String, default: 'svg' }) type
  @Prop({ type: String, default: 'default' }) variant
  @Prop({ type: String, default: '' }) size
  @Prop({ type: String, default: '' }) name
  @Prop({ type: String, default: '' }) src
  @Prop({ type: String, default: '' }) title

  get computedSrc () {
    if (this.src !== '') {
      return this.src
    } else {
      if (this.name) {
        return `/assets/img/icon/${this.type}/${this.name.replace(/\./g, '/')}.svg`
      } else {
        return ''
      }
    }
  }

  get computedClass () {
    const classObject = ['svg-icon']

    if (this.variant !== 'default') {
      classObject.push(`svg-icon-${this.variant}`)
    }

    if (this.size !== '') {
      classObject.push(`svg-icon-${this.size}`)
    }

    if (this.$attrs.class) {
      classObject.push(this.$attrs.class.toString())
    }
    return classObject
  }
}

export default IconSvg
</script>
