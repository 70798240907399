<template>
  <b-button size="sm" variant="link" class="btn-text-muted text-hover-primary btn-icon-muted fw-normal py-1" @click="show = true">
    <icon class="text-gray-800 p-0" type="ri" name="equalizer-3" mode="fill" size="5" />
  </b-button>
  <b-offcanvas v-model="show" lazy title="表格展示字段设置" body-class="p-0" placement="end">
    <div id="table-config-content">
      <div class="p-4">
        <div class="fs-base text-gray-1000">已展示字段</div>
        <div class="fs-7 text-gray-600 mt-1">将按以下顺序展示，按住可拖动</div>
      </div>
      <draggable id="table-config-list" :list="fields" drag-class="dragging" :disabled="!enableSort" item-key="key">
        <template #item="{ element, index }">
          <div v-if="element.visible" :class="['table-config-item', 'bg-white d-flex justify-content-between text-start position-relative', element.locked ? 'disabled' : '', (selectFields.key === element.key) && !element.locked ? 'bg-secondary cursor-move' : '']" @click="selectElement(element)" :key="index">
            <div class="d-flex align-items-center">
              <div class="text-center" style="width: 1.2rem;">
                <icon v-if="enableSort && !element.locked && (selectFields.key === element.key)" type="ri" name="draggable" mode="" size="6" />
              </div>
              <b-form-checkbox v-model="element.visible" :id="`column-config-${element.key}`" @click.stop/>
              <div class="fs-6 text-info lh-1r cursor-pointer" style="margin-left: -.75rem;">
                {{ element.label }}
              </div>
            </div>
            <div class="d-flex table-config-item-options align-items-center me-8">
              <a class="text-gray-400 text-hover-gray-700 ms-2" :class="{ fixed: element.fixed === 'left' }" href="#" @click.stop="fixedChange(element, 'left')">
                <icon type="ri" name="pushpin" :mode="element.fixed === 'left' ? 'fill' : 'line'" size="5" class="option-item" />
              </a>
              <a class="text-gray-400 text-hover-gray-700 ms-2" :class="{ fixed: element.fixed === 'right' }" href="#" @click.stop="fixedChange(element, 'right')">
                <icon type="ri" name="pushpin" :mode="element.fixed === 'right' ? 'fill' : 'line'" size="5" class="option-item" style="transform: rotateY(180deg)" />
              </a>
            </div>
          </div>
        </template>
      </draggable>
      <div class="p-4 mt-4">
        <div class="fs-base">未展示字段</div>
      </div>
      <template v-for="(element, index) in fields">
        <div v-if="!element.visible" class="bg-white d-flex text-start" :key="index" style="padding: .65rem .65rem .65rem 0">
          <div class="d-flex align-items-center">
            <div style="width: 1.2rem;">
            </div>
            <b-form-checkbox v-model="element.visible" :id="`column-config-${element.key}`" />
            <div class="fs-6 lh-1r text-gray-700" style="margin-left: -.75rem;">
              {{ element.label }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="bottom-button">
      <div class="d-flex justify-content-end px-4 py-6 h-100">
        <b-button variant="dark" class="min-w-80px me-2 drawer_close_button" @click="cancel">取消</b-button>
        <b-button class="min-w-80px drawer_close_button" variant="info" @click="submit">保存</b-button>
      </div>
    </div>
  </b-offcanvas>
</template>

<script setup>
import { defineModel, defineProps, onMounted, ref } from 'vue'
// import ZDrawer from '@/components/zero-bootstrap/z-drawer.vue'
// import CommonService from '@/services/common'
import draggable from 'vuedraggable'
import CommonService from '@/services/common'
import notification from '@/utils/notification'
// import notification from '@/utils/notification'

const props = defineProps({
  columnList: { type: Array, default: () => [] },
  tableName: { type: String, default: '' }
})

const column = defineModel('column')
const fields = ref([])
const enableSort = ref(false)
const selectFields = ref({})

const show = ref(false)

const selectElement = (item) => {
  if (!item.locked) {
    enableSort.value = true
    selectFields.value = item
  }
}

const fixedChange = (item, dir) => {
  if (item.fixed === dir) {
    item.fixed = ''
  } else {
    item.fixed = dir
  }
}

const submit = async () => {
  selectFields.value = {}
  const fieldsTemp = [...fields.value]
  for (const index in fieldsTemp) {
    fieldsTemp[index].renderSortNumber = parseInt(index) + 1
  }
  column.value = fieldsTemp
  try {
    const tableName = props.tableName
    await CommonService.setColumnConfig(tableName, { column: fieldsTemp })
    await notification.success('配置完成', '当前列表可显示列设置完成')
    show.value = false
  } catch (e) {
    await notification.error('配置失败', e.data.message)
  }
}

const cancel = async () => {
  fields.value = await initFormConfig()
  selectFields.value = {}
  show.value = false
}

const initFormConfig = async () => {
  let config = {}
  try {
    config = await CommonService.getColumnConfig(props.tableName)
  } catch (e) {}

  const fieldsTemp = [...props.columnList].map((item, index) => {
    return {
      key: item.key,
      label: item.label,
      visible: true,
      fixed: (item.key === 'do' ? 'right' : '') || item.fixed,
      locked: item.key === 'do' || item.locked,
      renderSortNumber: index + 2
    }
  })

  fieldsTemp.unshift({
    key: 'index',
    label: '序号',
    visible: true,
    fixed: 'left',
    locked: true,
    renderSortNumber: 1
  })

  if (config && config.column) {
    for (const index in fieldsTemp) {
      let item = fieldsTemp[index]
      const itemConfig = config.column.find(i => item.key === i.key)
      if (itemConfig) {
        item = Object.assign(item, itemConfig)
      } else if (fieldsTemp[index - 1]) {
        item.renderSortNumber = fieldsTemp[index - 1].renderSortNumber
      }
    }
  }

  fieldsTemp.sort((a, b) => {
    return a.renderSortNumber - b.renderSortNumber
  })

  // for (const item of config) {
  //   const fieldTempIndex = fieldsTemp.findIndex(fTemp => fTemp.key === item.key)
  //   if (fieldTempIndex !== -1) {
  //     fieldsTemp[fieldTempIndex] = Object.assign(fieldsTemp[fieldTempIndex], item)
  //   }
  // }
  return fieldsTemp
}

onMounted(async () => {
  const fieldsTemp = [...await initFormConfig()]
  fields.value = fieldsTemp
  for (const index in fieldsTemp) {
    fieldsTemp[index].renderSortNumber = parseInt(index) + 1
  }
  column.value = fieldsTemp
})
</script>
