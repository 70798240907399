<template>
  <div id="app_page_tabs" class="card-header card-header-tabs-line app-page-tabs bg-white border-gray-200 border-bottom z-index-3" :class="{ 'd-none': $_SHOWING.length === 0 && $_HIDDEN.length === 0 }">
    <ul id="nav-tab-container" class="nav nav-tabs nav-line-tabs nav-stretch d-flex">
      <template v-for="(item, index) in $_SHOWING" :key="`tab-showing-${index}`">
        <li v-if="item.name && item.name !== ''" class="nav-item">
          <!--        <a :class="['nav-link', item.active ? 'active text-white bg-primary border-primary border-bottom': 'text-gray-700']" data-toggle="tab" href="#" role="tab" @click.prevent="go(item, index)">-->
          <a :class="['nav-link', 'text-active-primary', item.active ? 'active fw-bold': 'text-gray-700']" data-toggle="tab" href="#" role="tab" @click.prevent="go(item, index)">
            <span class="tab-title">{{ item.title }}</span>
            <span class="tab-close" @click.prevent.stop="close(index, { fromHidden: false })" />
          </a>
        </li>
      </template>
      <li class="nav-item action ms-auto" v-if="$_HIDDEN.length > 0">
        <button class="nav-link text-gray-700" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"><icon type="ri" name="more-2" mode="fill" /></button>
        <div class="menu menu-sub menu-sub-dropdown rounded-0 menu-column menu-gray-800 menu-state-bg-primary w-200px" data-kt-menu="true">
          <div v-for="(item, index) in $_HIDDEN" :key="`tab-hidden-${index}`" class="menu-item">
            <a class="menu-link" href="#" @click.prevent="go(item)">
              <span class="navi-text tab-title">{{ item.title }}</span>
              <span class="tab-close" @click.stop="close(index, { fromHidden: true })"></span>
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script setup>
import { PAGE_TABS_EVENTS } from '@/components/layout/page-tabs/page-tabs'
import Event from '@/utils/event'
import { KEEPALIVE_EVENT } from '@/router/keep-alive'
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted, ref, watch, computed } from 'vue'
import { useRouter } from 'vue-router'
import { isEmpty, isEqual } from '@/components/zero-vue/utils/common'

const router = useRouter()

const $_SHOWING = ref([])
const $_HIDDEN = ref([])

const watchedShowing = computed(() => {
  return [...$_SHOWING.value]
})

const watchedHidden = computed(() => {
  return [...$_HIDDEN.value]
})

watch($_HIDDEN, value => {
  if (value.length && value.length > 0) {
    // eslint-disable-next-line no-undef
    KTMenu.createInstances()
  }
}, { deep: true })

watch([watchedShowing, watchedHidden], ([showing, hidden], [oldShowing, oldHidden]) => {
  if (!isEqual(showing, oldShowing) || !isEqual(hidden, oldHidden)) {
    const storage = { showing: [], hidden: [] }
    for (const n in showing) {
      if (!isEmpty(showing[n]) || (showing[n].name && showing[n].name !== '')) {
        storage.showing.push(showing[n])
      }
    }
    for (const n in hidden) {
      if (!isEmpty(hidden[n]) || (showing[n].name && showing[n].name !== '')) {
        storage.showing.push(hidden[n])
      }
    }
    window.sessionStorage.setItem('INTELLIMES_PAGE_TABS', JSON.stringify(storage))
  }
}, { deep: true })

function active (tab) {
  if (tab.name && tab !== '') {
    const showingIndex = $_SHOWING.value.findIndex(item => item.name === tab.name)
    const hiddenIndex = $_HIDDEN.value.findIndex(item => item.name === tab.name)

    if (showingIndex > -1) {
      // 在showing中存在，直接切换
      exchange(showingIndex)
    } else if (hiddenIndex > -1) {
      exchange($_SHOWING.value.length, hiddenIndex)
      // 在hidden中存在，切换至showing最后一个元素
    } else {
      // 两者都不存在，在showing中新增一个，直接在第一个显示
      $_SHOWING.value.unshift({ ...tab })
      // exchange($_SHOWING.value.length - 1)
      exchange(0)
      Event.emit(PAGE_TABS_EVENTS.ADDED, 'default', $_SHOWING.value[$_SHOWING.value.length - 1])
    }
    updateActive()
  }
}

function exchange (toIndex, fromHidden = null) {
  for (let index = 0; index < $_SHOWING.value.length; index++) {
    $_SHOWING.value[index].active = false
  }
  if (fromHidden === null) {
    $_SHOWING.value[toIndex].active = true
    $_SHOWING.value[toIndex].locked = true
  } else {
    // showing交换至hidden
    $_SHOWING.value.push({ ...$_HIDDEN.value[fromHidden], active: true, locked: true })
    $_HIDDEN.value.splice(fromHidden, 1)
  }
}

function go (item) {
  router.push(item)
}

function close (index, options = { fromHidden: false, to: {} }) {
  const isActive = $_SHOWING.value[index]?.active
  if (options.fromHidden) {
    Event.emit(KEEPALIVE_EVENT.REMOVE_INCLUDE, 'default', $_HIDDEN.value[index].name)
    $_HIDDEN.value.splice(index, 1)
  } else {
    Event.emit(KEEPALIVE_EVENT.REMOVE_INCLUDE, 'default', $_SHOWING.value[index].name)
    $_SHOWING.value.splice(index, 1)
    if (options?.to?.name) {
      const showingIndex = $_SHOWING.value.findIndex(item => item.name === options.to.name)
      const hiddenIndex = $_HIDDEN.value.findIndex(item => item.name === options.to.name)
      if (showingIndex > -1) {
        router.push($_SHOWING.value[showingIndex])
      } else if (hiddenIndex > -1) {
        router.push($_HIDDEN.value[hiddenIndex])
      }
    } else if (options?.to?.path) {
      router.push(options.to.path)
    } else if (isActive) {
      if (index > 0) {
        router.push($_SHOWING.value[index - 1])
      } else if (index === 0 && $_SHOWING.value.length > 0) {
        router.push($_SHOWING.value[0])
      } else {
        router.push('/')
      }
    }
  }
  updateActive()
}

async function updateActive () {
  await nextTick()
  const tabsDom = document.querySelectorAll('#nav-tab-container .nav-item')
  const maxWidth = document.querySelector('#app_page_tabs').clientWidth - 50
  let tabsWidth = 0
  const switchToHidden = []
  // 计算所有tab宽度
  for (let n = 0; n < tabsDom.length; n++) {
    tabsWidth += tabsDom[n].clientWidth
  }
  // 所有tab宽度超过container，非lock的移入hidden
  if (tabsWidth > maxWidth) {
    for (let n = $_SHOWING.value.length - 1; n >= 0; n--) {
      if (!$_SHOWING.value[n].locked) {
        tabsWidth -= tabsDom[n].clientWidth
        switchToHidden.push(n)
        if (tabsWidth <= maxWidth) {
          break
        }
      }
    }
    // switch
    for (const n of switchToHidden) {
      $_HIDDEN.value.push({ ...$_SHOWING.value[n] })
      $_SHOWING.value.splice(n, 1)
    }
  } else if (tabsWidth < (maxWidth - 98) && $_HIDDEN.value.length > 0) {
    // tab最小宽度98, tab宽度未充满maxWidth, 且hidden中有值，计算是否可以将hidden中值反写到showing
    const count = Math.floor((maxWidth - tabsWidth) / 98)
    const length = Math.min(count, $_HIDDEN.value.length)
    for (let n = 0; n < length; n++) {
      $_SHOWING.value.push({ ...$_HIDDEN.value[n] })
      $_HIDDEN.value.splice(n, 1)
    }
    await updateActive()
  }

  for (const n in $_SHOWING.value) {
    $_SHOWING.value[n].locked = false
  }
}

onMounted(() => {
  updateActive()
})

onBeforeMount(() => {
  try {
    const pageTabs = JSON.parse(window.sessionStorage.getItem('INTELLIMES_PAGE_TABS'))
    if (pageTabs.showing?.length) {
      $_SHOWING.value = pageTabs.showing
    }
    if (pageTabs.hidden?.length) {
      $_HIDDEN.value = pageTabs.hidden
    }
  } catch (e) {}

  Event.on(PAGE_TABS_EVENTS.ACTIVE, async (type, menu, open) => {
    active(menu)
  })
  Event.on(PAGE_TABS_EVENTS.CLOSE, async (type, menu, to) => {
    let index = $_SHOWING.value.findIndex(item => item.name === menu.name)
    if (index > -1) {
      close(index, { fromHidden: false, to })
    } else {
      index = $_HIDDEN.value.findIndex(item => item.name === menu.name)
      if (index > -1) {
        close(index, { fromHidden: true, to })
      }
    }
  })
  Event.on(PAGE_TABS_EVENTS.CLEAR, () => {
    window.sessionStorage.removeItem('INTELLIMES_PAGE_TABS')
    $_SHOWING.value = []
    $_HIDDEN.value = []
  })
})

onBeforeUnmount(() => {
  Event.remove(PAGE_TABS_EVENTS.CLOSE)
  Event.remove(PAGE_TABS_EVENTS.ACTIVE)
  Event.remove(PAGE_TABS_EVENTS.CLEAR)
})
</script>
