import { Restful, RestfulRequest, IntelliApi } from '@/services/intelli-api'

/**
 * IntelliApi Class
 * @class dashboard
  **/
@Restful({
  name: 'dashboard',
  path: 'dashboard'
})
class DashboardService extends IntelliApi {
  @RestfulRequest({
    method: 'GET',
    path: '/sqllist/execute/#{id}',
    parameters: [{ id: '-' }]
  })
  getData (id) {}

  @RestfulRequest({
    method: 'GET',
    path: '/sqllist/execute/#{id}',
    parameters: [{ id: '-' }, 'data']
  })
  getSqlData (id, data) {}

  @RestfulRequest({
    method: 'GET',
    path: '/board/#{id}',
    parameters: [{ id: '-' }]
  })
  getConfig (id) {
    return (data) => {
      return !data.config || data.config === '' ? {} : JSON.parse(data.config)
    }
  }

  @RestfulRequest({
    method: 'POST',
    path: '/board',
    parameters: ['data']
  })
  test (data) {}
}

export default new DashboardService()
