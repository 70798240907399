<template>
  <div v-if="hasDashboard" class="dropdown">
    <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" aria-expanded="false">
      <div class="btn btn-icon btn-clear w-auto me-1 btn-dropdown">
        <icon-svg size="lg" class="px-2" variant="primary" name="Devices.Diagnostics" />
        <span class="text-primary font-weight-bolder font-size-base me-3">看板大屏</span>
      </div>
    </div>
    <!--end::Toggle-->
    <!--begin::Dropdown-->
    <div class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
      <!--begin::Header-->
      <div class="d-flex flex-column py-6 bgi-no-repeat rounded-top" style="background: var(--primary);">
        <!--begin::Title-->
        <h4 class="rounded-top px-8 my-0">
          <span class="text-white">看板大屏</span>
        </h4>
      </div>
      <div class="p-8">
        <div class="scroll pe-7 me-n7 ps" data-scroll="true" data-height="300">
          <a href="#" @click.prevent="openDashboard(menu)" :key="`dashboard-menu-${menu.type}-${menu.id}`" v-for="menu in dashboardMenus" class="d-flex align-items-center mb-6">
            <div class="symbol symbol-40 symbol-light-primary me-5">
              <span class="symbol-label">
                <icon-svg variant="primary" size="lg" name="Shopping.Chart-line1" />
              </span>
            </div>
            <div class="d-flex flex-column font-weight-bold">
              <span class="text-dark text-hover-primary mb-1 font-size-lg">{{ menu.meta.title }}</span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!--end::Dropdown-->
  </div>
</template>

<script>
import { Component, Vue } from '@min/vue-compatible-decorator'
import Event from '@/utils/event'
import AccountService from '@/services/account'

export default
@Component()
class DashboardHeaderMenu extends Vue {
  hasDashboard = false
  dashboardMenus = []

  openDashboard (menu) {
    Event.emit('dashboard.open', 'default', menu)
  }

  async created () {
    const dashboardList = await AccountService.getDashboardList()
    this.hasDashboard = dashboardList.has_dashboard || false
    const menus = dashboardList.list

    if (dashboardList.custom_permission) {
      // 请求sql配置与dashboard-app中的看板页
      // const dashboardMenus = await Promise.all([AccountService.getDashboardMenu(), AccountService.getExternalDashboardMenu()])
      // 只请求dashboard-app中的看板页
      const dashboardMenus = await Promise.all([AccountService.getExternalDashboardMenu()])
      if (dashboardMenus[0]) {
        for (const n in dashboardMenus[0]) {
          menus.push(dashboardMenus[0][n])
        }
      }
      if (dashboardMenus[1]) {
        for (const n in dashboardMenus[1]) {
          menus.push(dashboardMenus[1][n])
        }
      }
    }
    this.dashboardMenus = menus
  }
}
</script>
