<template>
  <div :class="wrapperClass">
    <dashboard v-if="type === 'id'" name="dashboard" :dashboard-id="dashboardId" />
    <component v-if="type === 'component'" :is="dashboardComponent" />
  </div>
</template>

<script>
// 修饰器依赖
import { Component, Vue } from '@min/vue-compatible-decorator'
import Dashboard from './dashboard'
import DeviceOverview from '../pages/device-overview'

export default
@Component({
  components: { Dashboard, DeviceOverview }
})
class DashboardWrapper extends Vue {
  dashboards = [
    'device-overview'
  ]

  get type () {
    let type = 'id'

    if (this.dashboards.includes(this.$route.params.id)) {
      type = 'component'
    }

    return type
  }

  get dashboardComponent () {
    return this.$route.params.id
  }

  get dashboardId () {
    return parseInt(this.$route.params.id)
  }

  get wrapperClass () {
    const classObject = []
    let theme = window.localStorage.getItem('intellimes-dashboard-theme')
    const queryTheme = this.$route.query.theme

    if (queryTheme && queryTheme !== '') {
      theme = queryTheme
    }

    if (theme === '') {
      classObject.push('dashboard-light')
    } else {
      classObject.push(theme)
    }

    return classObject
  }
}
</script>
