import { RestfulRequest, Restful } from '@min/axios/decorators'
import { RestfulApi } from '@min/axios'

let RewriteRules = {}

function setRewriteRules (rules) {
  RewriteRules = Object.assign(RewriteRules, rules)
}

function all (...services) {
  return Promise.all(services)
}

function typeOf (o) {
  const s = Object.prototype.toString.call(o)
  return s.match(/\[object (.*)\]/)[1].toLowerCase()
}

function startWith (str, search, pos) {
  pos = !pos || pos < 0 ? 0 : +pos
  return str.substring(pos, pos + search.length) === search
}

class IntelliApi extends RestfulApi {
  $setPrefix (prefix, parameters, values, options) {
    return prefix
  }

  $setPath (methodName, parameters, values, options) {
    let path = super.$setPath(methodName, parameters, values, options)
    if (path.charAt(path.length - 1) === '/') {
      path = path.substr(0, path.length - 1)
    }
    return this._pathRewrite(path, RewriteRules)
  }

  _pathRewrite (path, rules) {
    if (Object.keys(rules).length > 0) {
      for (const n in rules) {
        if (n === 'default') break

        switch (typeOf(n)) {
          case 'string': {
            if (startWith(path, n)) {
              let rewritePath = path
              switch (typeOf(rules[n])) {
                case 'string':
                  rewritePath = rules[n].replace('$0', path)
                  break
                case 'function':
                  rewritePath = rules[n](path, n)
                  break
              }
              return typeOf(rewritePath) === 'boolean' ? path : rewritePath
            }
            break
          }
          case 'regex': {
            const matches = path.match(n)
            if (matches) {
              let rewritePath = path
              switch (typeOf(rules[n])) {
                case 'string': {
                  rewritePath = rules[n]
                  for (const k in matches) {
                    rewritePath = rewritePath.replace(`$${k}`, matches[k])
                  }
                  break
                }
                case 'function':
                  rewritePath = rules[n](path, matches, n)
                  break
              }
              return typeOf(rewritePath) === 'boolean' ? path : rewritePath
            }
            break
          }
        }
      }
    }

    return path
  }

  $setMethod (method, parameters, values, options) {
    return method
  }

  $setHeaders (headers, parameters, values, options) {
    const token = localStorage.getItem('access_token')
    if (token) {
      headers.Authorization = 'Bearer ' + token
    }
    headers['X-Authorization-Platform'] = 'web'
    return super.$setHeaders(headers, parameters, values, options)
  }

  $setContentType (contentType, parameters, values) {
    if (this.$options.contentType === 'multipart/form-data') {
      return undefined
    }
  }

  $setData (methodName, parameters, values, options) {
    const data = {}
    for (const n in parameters) {
      if (parameters[n] === 'data') {
        return values[n]
      }
      if (typeOf(parameters[n]) === 'object') {
        for (const k in parameters[n]) {
          if (![undefined, null, '', '-'].includes(parameters[n][k])) {
            data[parameters[n][k]] = values[n]
          }
        }
      } else {
        data[parameters[n]] = values[n]
      }
    }

    return data
  }

  $setResponse (promise, resolve, reject, returns) {
    return promise.then(
      response => {
        if (response.data.data) {
          resolve(response.data.data)
        } else {
          if (response.data.code !== 0) {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        }
      }).catch(
      error => {
        reject(error.response)
      }
    )
  }

  /* getList (page, size, options) {
    if (typeOf(page) === 'object') {
      return this._getList(...page)
    }
  } */

  @RestfulRequest({
    method: 'GET',
    parameters: ['data']
  })
  getList (data) {}

  async getAllList () {
    try {
      const list = []
      const data = (await this.getList({ page: 1, size: 1000 })).records
      for (const i in data) {
        if (data[i].status !== undefined && data[i].status === 0) {
          list.push(
            { id: data[i].id, label: (data[i].code ? data[i].code + '/' : '') + data[i].name }
          )
        }
      }
      return list
    } catch (e) {
      console.log(e)
    }
  }

  async getAllListNoStatus () {
    try {
      const list = []
      const data = (await this.getList({ page: 1, size: 1000 })).records
      for (const i in data) {
        list.push(
          { id: data[i].id, label: (data[i].code ? data[i].code + '/' : '') + data[i].name }
        )
      }
      return list
    } catch (e) {
      console.log(e)
    }
  }

  @RestfulRequest({
    method: 'GET',
    path: '#{id}',
    parameters: [{ id: '-' }]
  })
  getDetail (id) {}

  @RestfulRequest({
    method: 'POST',
    path: '',
    parameters: ['data']
  })
  add (info) {}

  @RestfulRequest({
    method: 'DELETE',
    path: '#{id}',
    parameters: [{ id: '-' }]
  })
  delete (id) {}

  @RestfulRequest({
    method: 'PUT',
    path: '#{id}',
    parameters: [{ id: '-' }, 'data']
  })
  change (id, info) {}

  @RestfulRequest({
    method: 'POST',
    path: '/import-excel',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    parameters: ['data']
  })
  upload (data) {}

  @RestfulRequest({
    method: 'GET',
    path: '?keywords=#{keywords}&page=1&size=200',
    parameters: [{ keywords: '-' }]
  })
  asyncSearch (keywords) {}

  getAsyncSearch () {
    return (keyword) => {
      return this.asyncSearch(keyword) // 返回搜索结果
    }
  }

  getGetDetail () {
    return (id) => {
      return this.getDetail(id)
    }
  }
}

export {
  IntelliApi,
  RestfulRequest,
  Restful,
  RestfulApi,
  setRewriteRules,
  all
}
