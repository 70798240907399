<template>
  <div :id="title"></div>
</template>

<script>
import { Component, Prop, Vue } from '@min/vue-compatible-decorator'
import * as echarts from 'echarts'
import DashboardService from '@/services/dashboard'

export default
@Component()
class ZOriginalEcharts extends Vue {
  @Prop() options

  @Prop() title

  chart

  async mounted () {
    await this.initChart()
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
  }

  resize () {
    const myChart = echarts.getInstanceByDom(document.getElementById(this.title))
    myChart.resize()
  }

  async initChart () {
    const options = this.options
    const dom = document.getElementById(this.title)
    this.chart = echarts.init(dom)
    if (options.dataSource) {
      const info = await this.initData()
      if (info.length > 0 || Object.keys(info).length > 0) {
        options.dataset.source = info
      }
    }
    this.options && this.chart.setOption(this.options)
  }

  async initData (config = this.options.dataSource) {
    if (config.type && config.type === 'sql') {
      return await DashboardService.getData(config.sql)
    } else if (config.type && config.type === 'function') {
      return config.function()
    } else if (config.type && config.type === 'promise') {
      return await config.promise()
    }
  }

  async updateData (data) {
    if (data) {
      this.chart.setOption({
        dataset: {
          source: data
        }
      })
      return
    }
    const info = await this.initData()
    if (info.length > 0) {
      this.chart.setOption({
        dataset: {
          source: await this.initData()
        }
      })
    }
  }
}
</script>
