<template>
  <p v-if="props.static" class="form-control-plaintext text-gray-700" :class="props.class ?? []">{{ data }}</p>
  <div
    v-else
    class="number-input form-control"
    :class="{
      'number-input--center': center,
      'number-input--controls': controls,
      [`number-input--${size}`]: size,
      'is-invalid': invalid
    }"
    v-bind="attributes"
  >
    <input
      ref="numberInput"
      class="number-input__input"
      v-bind="attrs"
      type="number"
      :name="name"
      :value="currentValue"
      :min="min"
      :max="max"
      :step="step"
      :readonly="readonly || !inputtable"
      :disabled="disabled || (!decreasable && !increasable)"
      :placeholder="placeholder"
      autocomplete="off"
      @change="change"
      @paste="paste"
    >
    <div class="number-input__controls d-flex align-items-center">
      <button
        v-if="controls"
        type="button"
        :disabled="disabled || readonly || !decreasable"
        @click="decrease"
      >
        <icon type="ri" name="subtract" mode="fill" size="base" />
      </button>
      <div class="w-1px h-15px bg-gray-200" />
      <button
        v-if="controls"
        type="button"
        :disabled="disabled || readonly || !increasable"
        @click="increase"
      >
        <icon type="ri" name="add" mode="fill" size="base" />
      </button>
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps, defineEmits, useAttrs, ref, watch, onMounted, defineExpose } from 'vue'
import { isEmpty } from '../../utils/common'

const isNaN = Number.isNaN || window.isNaN
const REGEXP_NUMBER = /^-?(?:\d+|\d+\.\d+|\.\d+)(?:[eE][-+]?\d+)?$/
const REGEXP_DECIMALS = /\.\d*(?:0|9){10}\d*$/
const normalizeDecimalNumber = (value, times = 100000000000) => (
  REGEXP_DECIMALS.test(value) ? (Math.round(value * times) / times) : value
)

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  class: {},
  display: { type: String, default: 'static' },
  static: { type: Boolean, default: false },
  attrs: { type: Object, default: undefined },
  controls: { type: Boolean, default: true },
  center: Boolean,
  disabled: Boolean,
  inputtable: { type: Boolean, default: true },
  inline: Boolean,
  max: { type: Number, default: Infinity },
  min: { type: Number, default: -Infinity },
  name: { type: String, default: undefined },
  placeholder: { type: String, default: undefined },
  readonly: Boolean,
  rounded: Boolean,
  size: { type: String, default: undefined },
  step: { type: Number, default: 1 },
  required: { type: Boolean, default: false },
  label: { type: String, default: '' },
  for: { type: String, default: '' },
  modelValue: { type: [Number, String], default: NaN },
  data: {}
})

const invalid = ref(false)
const numberInput = ref()
const currentValue = ref(NaN)
const increasable = computed(() => {
  const num = currentValue.value
  return isNaN(num) || num < props.max
})
const decreasable = computed(() => {
  const num = currentValue.value
  return isNaN(num) || num > props.min
})
const attributes = computed(() => {
  const attrs = { ...useAttrs() }
  delete attrs.onChange
  return attrs
})

watch(() => props.modelValue, (newValue, oldValue) => {
  if (
    // Avoid triggering change event when created
    !(isNaN(newValue) && typeof oldValue === 'undefined') &&
    // Avoid infinite loop
    newValue !== currentValue.value
  ) {
    setValue(newValue)
  }
})

/**
 * Change event handler.
 * @param {string} value - The new value.
 */
function change (event) {
  setValue(Math.min(props.max, Math.max(props.min, event.target.value)))
}

/**
 * Paste event handler.
 * @param {Event} event - Event object.
 */
function paste (event) {
  const clipboardData = event.clipboardData || window.clipboardData

  if (clipboardData && !REGEXP_NUMBER.test(clipboardData.getData('text'))) {
    event.preventDefault()
  }
}

/**
 * Decrease the value.
 */
function decrease () {
  if (decreasable.value) {
    let value = currentValue.value

    if (isNaN(value)) {
      value = 0
    }

    setValue(Math.min(props.max, Math.max(
      props.min,
      normalizeDecimalNumber(value - props.step)
    )))
  }
}

/**
 * Increase the value.
 */
function increase () {
  if (increasable.value) {
    let value = currentValue.value

    if (isNaN(value)) {
      value = 0
    }

    setValue(Math.min(props.max, Math.max(
      props.min,
      normalizeDecimalNumber(value + props.step)
    )))
  }
}

/**
 * Set new value and dispatch change event.
 * @param {number} value - The new value to set.
 */
function setValue (value) {
  const oldValue = currentValue.value
  let newValue = props.rounded ? Math.round(value) : value

  if (props.min <= props.max) {
    newValue = Math.min(props.max, Math.max(props.min, newValue))
  }

  currentValue.value = newValue

  if (newValue === oldValue) {
    // Force to override the number in the input box (#13).
    if (numberInput.value) {
      numberInput.value.value = newValue
    }
  }

  emit('update:modelValue', newValue)
}

onMounted(() => {
  currentValue.value = props.modelValue - 0
})

/**
 *
 * @returns { Object } data
 * @returns { number } data.status 0: 正常, 1: error, 2: warning
 * @returns { string } data.message
 * @returns { any } data.data
 */
function validate () {
  if (props.required && isEmpty(props.modelValue)) {
    invalid.value = true
    return { status: 1, message: `${props.label ?? props.for ?? '此项'}必填` }
  } else {
    invalid.value = false
    return { status: 0, message: '' }
  }
}

defineExpose({ validate })
</script>
