<template>
  <div id="kt_app_header" class="app-header">
    <div id="kt_app_header_container" class="app-container container-fluid d-flex align-items-stretch flex-stack">
      <div class="d-flex flex-stack flex-lg-row-fluid align-items-baseline" id="kt_app_header_wrapper">
        <div class="page-title d-flex justify-content-start gap-4 my-auto" data-kt-swapper="true" data-kt-swapper-mode="{default: 'prepend', lg: 'prepend'}" data-kt-swapper-parent="{default: '#kt_app_content_container', lg: '#kt_app_header_wrapper'}">
          <div class="app-header-logo align-self-center h-100 position-relative pe-2" id="kt_app_sidebar_logo">
            <a href="/"><img alt="Logo" src="@/assets/img/logo-brand.svg" class="h-40px"></a>
          </div>
          <div class="app-header-brand ps-6 d-flex align-items-center">
            <h1 class="fw-normal text-gray-300 lh-1 fs-4 m-0">{{ brandName }}</h1>
          </div>
        </div>
        <div class="app-navbar flex-shrink-0 mt-1">
          <div class="d-flex align-items-center me-2 cursor-pointer" @click="goBack">
            <img src="/mes3/assets/img/portal/back.svg" style="width: 16px;height: 16px;" alt="">
          </div>
          <dashboard-card />
          <app-download />
          <notification />
          <timearea />
          <profile-card v-if="showProfile" :profile="profile" :tenant="tenant" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from '@min/vue-compatible-decorator'
import { useTenantStore } from '@/stores/tenant'
// #ifdef HAS_SUITE_DASHBOARD
import DashboardHeaderMenu from '@/modular/dashboard/layout/header-menu'
// #endif
import Notification from './header-components/notification'
import Timearea from './header-components/timearea'
import ThemeSwitch from './header-components/theme-switch'
import AppDownload from './header-components/app-download'
import ProfileCard from './header-components/profile-card'
import DashboardCard from './header-components/dashboard-card'
import About from './about'
import { useVersionStore } from '@/stores/version'
import ActionItem from './header-components/action-item'
import { useMenuStore } from '@/stores/menu'

export default
@Component({
  components: {
    ActionItem,
    // #ifdef HAS_SUITE_DASHBOARD
    DashboardHeaderMenu,
    // #endif
    Notification,
    Timearea,
    AppDownload,
    ThemeSwitch,
    ProfileCard,
    DashboardCard,
    About
  }
})
class PageHeader extends Vue {
  @Prop({ type: [Array, Object], default: () => [] }) messages
  @Prop({ type: Array, default: [] }) breadCrumb
  @Prop({ type: Object, default: () => {} }) profile
  @Prop({ type: Object, default: () => {} }) tenant

  showProfile = true
  pkg = useVersionStore().data

  get brandName () {
    return useTenantStore().data.extJson?.appName ?? useTenantStore().data.name
  }

  get appName () {
    return useTenantStore().data.extJson && useTenantStore().data.extJson.appName ? useTenantStore().data.extJson.appName : this.pkg['app-name']
  }

  get currentMenu () {
    return this.breadCrumb[this.breadCrumb.length - 1]
  }

  get backMenu () {
    return useMenuStore().backMenu
  }

  hasBackRoute () {
    if (!this.currentMenu) {
      return false
    }
    if (this.currentMenu?.meta?.show !== true) {
      return true
    }
    return this.currentMenu?.backRouter && this.currentMenu?.backRouter !== ''
  }

  back () {
    if (this.currentMenu?.backRouter && this.currentMenu?.backRouter !== '') {
      this.$router.push(this.currentMenu.backRouter)
    } else if (this.backMenu) {
      this.$router.push(this.backMenu)
    } else {
      const pathArr = this.$route.path.split('/')
      if (pathArr.includes('change') || pathArr.includes('detail')) {
        pathArr.pop()
      }
      // 将pathArr最后一个值替换为list
      pathArr[pathArr.length - 1] = 'list'
      this.$router.push(pathArr.join('/'))
    }
  }

  goBack () {
    // 返回
    window.location.href = '/'
  }

  info () {
    this.$router.push('/account/change')
  }
}
</script>
