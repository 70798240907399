import ZDrawer from './z-drawer.vue'

function install (Vue, options) {
  Vue.component('ZeroDrawer', ZDrawer)
  Vue.component('BDrawer', ZDrawer)
}

export default {
  install
}
