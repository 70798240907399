import EventEmitter from 'eventemitter3'
import { v4 as uuid } from 'uuid'

const event = new EventEmitter()
const ready = {}
const callbacks = {}

class TimerTick {
  constructor (condition, callback, time) {
    this.condition = condition
    this.callback = callback
    this.time = time || 10
  }

  run (callback) {
    if (this.condition) {
      (callback || this.callback)()
    } else {
      const timer = setInterval(() => {
        if (this.condition) {
          (callback || this.callback)()
          clearInterval(timer)
        }
      }, this.time)
    }
  }
}

function when (condition, time) {
  return new TimerTick(condition, time)
}

export { when }

export default {
  once (eventName, condition, callback) {
    this.condition(eventName, condition)
    event.once(eventName, () => {
      callback()
    })
  },

  on (eventName, condition, callback) {
    const eventId = uuid()
    if (arguments.length <= 2) {
      this.condition(eventName, ['default'])
      this.setCallback(eventName, eventId, condition)
    } else {
      this.condition(eventName, condition)
      this.setCallback(eventName, eventId, callback)
    }
    event.on(eventName, this.getCallback(eventName, eventId))
    return eventId
  },

  setCallback (eventName, id, callback) {
    if (!callbacks[eventName]) {
      callbacks[eventName] = {}
    }
    callbacks[eventName][id] = callback
  },

  getCallback (eventName, id) {
    if (!callbacks[eventName]) {
      return undefined
    }
    if (id) {
      return callbacks[eventName][id]
    } else {
      return callbacks[eventName]
    }
  },

  removeCallback (eventName, id) {
    if (id) {
      delete callbacks[eventName][id]
    } else {
      delete callbacks[eventName]
    }
  },

  condition (eventName, condition, status) {
    if (!ready[eventName]) {
      ready[eventName] = {}
    }

    condition.forEach(n => {
      if (!ready[eventName][n]) {
        ready[eventName][n] = status || false
      }
    })
  },

  emit (eventName, type, ...args) {
    if (!ready[eventName]) {
      this.condition(eventName, [type], true)
    }

    if (undefined !== ready[eventName].default) {
      ready[eventName].default = true
      args = [type].concat(args)
    }

    if (undefined !== ready[eventName][type]) {
      ready[eventName][type] = true
    }

    for (const n in ready[eventName]) {
      if (!ready[eventName][n]) {
        return
      }
    }

    if (event.eventNames().includes(eventName)) {
      event.emit(eventName, ...args)
    }
  },

  trigger (eventName, type, ...args) {
    this.emit(eventName, type, ...args)
  },

  remove (eventName, eventId) {
    if (!eventId) {
      delete ready[eventName]
      for (const k in callbacks[eventName]) {
        event.removeListener(eventName, this.getCallback(eventName, k))
      }
      this.removeCallback(eventName)
    } else {
      event.removeListener(eventName, this.getCallback(eventName, eventId))
      this.removeCallback(eventName, eventId)
    }
  }

  /* remove (eventName, condition, callback) {
    delete ready[eventName]

    if (arguments.length <= 2) {
      event.removeListener(eventName, condition)
    } else {
      event.removeListener(eventName, callback)
    }
  } */
}
