<template>
  <wrapper :toolbar="true">
    <!-- 表格组件 -->
    <!-- config为配置项字段 -->
    <!-- init为列表初始化函数 -->
    <!-- search为列表搜索函数 -->
    <zero-table ref="zeroTable" v-bind="zeroTableConfig">
      <template v-slot:do="data">
        <cell-option :route="`/basic/quality/class/change/${data.data.item.id}`">修改</cell-option>
        <cell-option v-if="data.data.item.status === 0" @function="remove(data.data.item)">禁用</cell-option>
        <cell-option v-else @function="remove(data.data.item)">启用</cell-option>
      </template>
    </zero-table>
  </wrapper>
</template>

<script>
// 修饰器依赖
import { Component, Vue } from '@min/vue-compatible-decorator'
import QualityClassService from '../../../services/quality/class'
// 提示组件引入
import notification from '@/utils/notification'

export default
@Component()
class QualityClassList extends Vue {
  zeroTableConfig = {
    asyncService: QualityClassService,
    config: {
      filter: {
        code: {
          title: '类别编码',
          type: 'zero-input'
        },
        name: {
          title: '类别名称',
          type: 'zero-input'
        }
      },
      table: {
        fields: [
          {
            key: 'code',
            label: '质检类别编码',
            width: 240
          },
          {
            key: 'name',
            label: '质检类别名称',
            width: 240
          },
          {
            key: 'qa_item_names',
            label: '包含质检项',
            format: value => value.map(item => item.name).join(',')
          },
          {
            key: 'status',
            label: '状态',
            format: this.translateStatus
          },
          {
            key: 'do',
            label: '操作'
          }
        ]
      }
    }
  }

  async remove (item) {
    const confirm = await notification.confirm(`确定${item.status === 0 ? '禁用' : '启用'}此质检项类别吗`)
    if (confirm.value === true) {
      try {
        await QualityClassService.change(item.id, item.status === 0 ? { status: 1 } : { status: 0 })
        await notification.success(`${item.status === 0 ? '禁用' : '启用'}成功`, '')
        await this.$refs.zeroTable.initData()
      } catch (e) {
        notification.error(`${item.status === 0 ? '禁用' : '启用'}失败,` + e.data.message)
      }
    }
  }

  translateStatus (data) {
    switch (data) {
      case 0:
        return '启用'
      case 1:
        return '禁用'
      default:
        return '无'
    }
  }
}
</script>
