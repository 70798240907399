import ZeroForm from './components/form.vue'
import ZeroFormStatic from './components/form-static.vue'
import ZeroFormBlock from './components/form-block.vue'
import ZeroFormBlockStatic from './components/form-block-static.vue'
import ZeroTable from './components/table.vxe.vue'
import ZeroGrid from './components/grid.vue'
import ZeroSqlTable from './components/sql-table.vue'
import ZeroSearch from './components/search.vue'
import Icon from './components/icon.vue'
import IconSvg from './components/icon-svg.vue'
import BLabel from './components/b-label.vue'
import ZeroCard from './components/card.vue'
import ZeroWidget from './components/widget.vue'
import FormTableInline from './components/form-table-inline.vue'
import FormTableInlineStatic from './components/form-table-inline-static.vue'
import ZeroFormTable from './components/form-table.vue'
import ZeroFormTableStatic from './components/form-table-static.vue'
import ZeroFormTab from './components/form-tab.vue'
import ZeroFormTabStatic from './components/form-tab-static.vue'
import ZeroFormInfoBlock from './components/form-info-block.vue'
import ZeroFormModal from './components/form-modal.vue'
import ZeroFileUpload from './components/file-upload.vue'
import ZeroTag from './components/tag.vue'
import ZeroTagGroup from './components/tag-group.vue'
import CellOption from './components/cell-option.vue'
import { setVm } from './utils/vm'
import { setNotification } from './utils/notification'
import InlineSvg from 'vue-inline-svg'
import ZeroBootstrap from '@/components/zero-bootstrap'
import { VxeUI, VxeTable, VxeColumn, VxeColgroup } from 'vxe-table'
import { VxeIcon, VxeLoading, VxeTooltip } from 'vxe-pc-ui'
import zhCN from 'vxe-pc-ui/lib/language/zh-CN'
import VueWithChangeable from './classes/vue-with-changeable'
import VxeUIPluginExportXLSX from '@vxe-ui/plugin-export-xlsx'
import ExcelJS from 'exceljs'
import './scss/zero-vue.scss'
import { useFormInput } from './components/form-input'
import { vZTooltip } from '@/components/zero-vue/directives/z-tooltip'
import '@vue-js-cron/light/dist/light.css'
import { CronLight } from '@vue-js-cron/light'

function install (Vue, options) {
  Vue.component('ZeroSearch', ZeroSearch)
  Vue.component('ZeroForm', ZeroForm)
  Vue.component('ZeroFormStatic', ZeroFormStatic)
  Vue.component('ZeroFormBlock', ZeroFormBlock)
  Vue.component('ZeroFormBlockStatic', ZeroFormBlockStatic)
  Vue.component('ZeroTable', ZeroTable)
  Vue.component('ZeroGrid', ZeroGrid)
  Vue.component('ZeroSqlTable', ZeroSqlTable)
  // eslint-disable-next-line vue/multi-word-component-names
  Vue.component('Icon', Icon)
  Vue.component('IconSvg', IconSvg)
  Vue.component('InlineSvg', InlineSvg)
  Vue.component('BLabel', BLabel)
  Vue.component('ZeroCard', ZeroCard)
  Vue.component('ZeroWidget', ZeroWidget)
  Vue.component('ZeroFormTable', ZeroFormTable)
  Vue.component('ZeroFormTableStatic', ZeroFormTableStatic)
  Vue.component('ZeroFormTableInline', FormTableInline)
  Vue.component('ZeroFormTableInlineStatic', FormTableInlineStatic)
  Vue.component('FormTableInline', FormTableInline)
  Vue.component('ZeroFormModal', ZeroFormModal)
  Vue.component('ZeroFileUpload', ZeroFileUpload)
  Vue.component('ZeroFormTab', ZeroFormTab)
  Vue.component('ZeroFormInfoBlock', ZeroFormInfoBlock)
  Vue.component('ZeroFormTabStatic', ZeroFormTabStatic)
  Vue.component('ZeroTag', ZeroTag)
  Vue.component('ZeroTagGroup', ZeroTagGroup)
  Vue.component('CellOption', CellOption)
  Vue.component('CronLight', CronLight)
  // Vue.component('DatePicker', DatePicker)
  Vue.directive('ZTooltip', vZTooltip)

  if (options?.notification) {
    setNotification(options.notification)
  }
  useFormInput(Vue)
  useTable(Vue)
  Vue.use(ZeroBootstrap)
  VxeUI.use(VxeUIPluginExportXLSX, { ExcelJS })
}

function useTable (vue) {
  // 按需加载的方式默认是不带国际化的，自定义国际化需要自行解析占位符 '{0}'，例如：
  VxeUI.setI18n('zh-CN', zhCN)
  VxeUI.setLanguage('zh-CN')
  VxeUI.setConfig({
    zIndex: 0,
    table: {
      size: 'small'
    }
  })
  vue.use(VxeTable)
    .use(VxeLoading)
    .use(VxeIcon)
    .use(VxeColumn)
    .use(VxeColgroup)
    .use(VxeTooltip)
}

export default {
  install,
  setVm,
  setNotification
}

export {
  VueWithChangeable
}
