<template>
  <slot :form="$_CONFIG" :config="$_CONFIG" :data="$_VALUE">
    <template v-if="$_CONFIG.forms">
      <b-row v-for="(rowConfig, index) in $_CONFIG.forms" :key="`form-block-row-${index}`" class="mx-n4">
        <template v-for="form in rowConfig" :key="`form-block-row-form-${form.key}`">
          <b-col :cols="(rowConfig.length === 1 && form.col) ? form.col : 6" class="pt-4 px-4" v-if="!$_HIDDEN_STATUS[form.key]">
            <label class="d-flex align-items-center h-20px mb-1 text-gray-700">
              <span v-if="form.required" class="required">{{ form.label }}</span>
              <span v-else>{{ form.label && form.label !== '' ? form.label : ' ' }}</span>
            </label>
            <slot :name="form.key" :form="form" :data="$_VALUE" :edit="true" :components="components">
              <component
                :ref="el => components[form.key] = el"
                :is="form.component"
                :required="!!form.required"
                :validation="form.validation"
                :for="form.key"
                :label="form.label"
                :incremental-mode="props.incrementalMode"
                v-bind="form.binding"
                v-on="form.action || {}"
                v-model="$_VALUE[form.key]"
              />
            </slot>
          </b-col>
        </template>
      </b-row>
    </template>
    <slot name="plain-block" :data="$_VALUE" :form="$_CONFIG" />
  </slot>
</template>
<script setup>
import { defineProps, defineExpose, defineEmits, ref, watch, onBeforeMount } from 'vue'
import { getComponent } from './internal-types'
import { typeOf, isPromise, display, isEmpty, isArray, isEqual } from '../utils/common'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  for: { type: String },
  // form配置（主要为页面内表单的类型等信息）
  config: { type: Object, default: () => { return {} } },
  // 最后提交的数据是否为增量数据(修改模式下)
  incrementalMode: { default: false },
  // 全量表单数据
  fullData: { type: Object, default: () => {} },
  debug: { type: Boolean, default: false },
  modelValue: { default: () => { return {} } }
})

const components = ref({})

const $_CONFIG = ref({})
const $_VALUE = ref(Object.assign({}, props.modelValue))
const $_KEY_LIST = ref({})
const $_HIDDEN_STATUS = ref({})

watch(() => props.modelValue, value => {
  if (!isEmpty(value) && !isEqual(value, $_VALUE.value)) {
    for (const key in $_VALUE.value) {
      if (value[key] === undefined || value[key] === null) {
        value[key] = $_VALUE.value[key]
      }
    }
    $_VALUE.value = { ...value }
  }
}, { deep: true })

watch($_VALUE, value => {
  if (!isEmpty(value)) {
    emit('update:modelValue', value)
  }
}, { deep: true })

watch($_VALUE, value => {
  parseDisplayStatus(value)
}, { deep: true, immediate: true })

/**
 *
 * @type { Object<{ required: boolean, display: boolean }>}
 */
// const keyList = ref({})
const defaultForm = {
  label: '',
  key: '',
  type: getComponent('input'),
  required: false,
  display: () => true
}

/* const computedEventBlock = computed(() => {
  return {
    ...(computedConfig.value.on ?? computedConfig.value.action ?? {})
  }
}) */

/* const computedEvent = computed(() => formIndex => {
  const hasForm = (computedConfig.value.forms && computedConfig.value.forms[formIndex])
  return {
    ...(hasForm ? computedConfig.value.forms[formIndex].on ?? computedConfig.value.forms[formIndex].action : {})
  }
}) */

function parseDisplayStatus (value) {
  const status = {}
  for (const n in $_KEY_LIST.value) {
    status[n] = !$_KEY_LIST.value[n].display(value ?? $_VALUE.value)
  }
  $_HIDDEN_STATUS.value = { ...status }
}

function parseValidationConfig (config) {
  return (typeOf(config) !== 'array') ? [config] : config
}
/**
 *
 * @returns { Object } data
 * @returns { number } data.status 0: 正常, 1: error, 2: warning
 * @returns { string } data.message
 * @returns { any } data.data
 */
function validate () {
  const validationResult = {
    status: 0,
    message: ''
  }
  const validationList = {}
  for (const k in $_HIDDEN_STATUS.value) {
    // 判断是否display数据
    if (!$_HIDDEN_STATUS.value[k]) {
      // 判断子组件是否有verify方法
      if (components.value[k] && components.value[k].validate) {
        validationList[k] = components.value[k].validate()
        if (validationList[k].status !== 0) {
          validationResult.status = 1
        }
      } else {
        if ($_KEY_LIST.value[k].required && [null, undefined, '', []].includes($_VALUE.value[k])) {
          validationList[k] = {
            status: 1,
            message: '此项必填'
          }
          validationResult.status = 1
        } else {
          validationList[k] = {
            status: 0,
            message: ''
          }
        }
      }
      // 处理自定义verify方法
      if ($_KEY_LIST.value[k].validation) {
        for (const n in $_KEY_LIST.value[k].validation) {
          if ($_KEY_LIST.value[k].validation[n].func($_VALUE.value[k])) {
            validationList[k] = {
              status: 0,
              message: ''
            }
          } else {
            validationList[k] = {
              status: 1,
              message: $_KEY_LIST.value[k].validation[n].label
            }
            break
          }
        }
      }
    }
  }
  validationResult.data = validationList
  return validationResult
}

function init () {
  const rawConfig = { ...props.config }
  const config = { ...props.config }
  const value = { ...$_VALUE.value }

  if (isEmpty(rawConfig.component) && !isEmpty(rawConfig.forms)) {
    const rowsConfig = []
    let currentRow = 0
    const keyListRaw = {}
    for (const row in rawConfig.forms) {
      if (isArray(rawConfig.forms[row])) {
        if (!isEmpty(rowsConfig[currentRow])) {
          currentRow++
        }
        for (const n in rawConfig.forms[row]) {
          if (!rowsConfig[currentRow]) {
            rowsConfig[currentRow] = []
          }
          const { formConfig, keyListItem, defaultValue } = parseFormConfig(rawConfig.forms[row][n], value, currentRow, rowsConfig[currentRow].length)
          // const form = await initFormConfig(rawConfig.forms[row][n])
          // data[form.key] = initFormValue(form, data)
          keyListRaw[formConfig.key] = keyListItem
          rowsConfig[currentRow].push(formConfig)
          value[formConfig.key] = value[formConfig.key] ?? defaultValue
        }
        currentRow++
      } else {
        if (!rowsConfig[currentRow]) {
          rowsConfig[currentRow] = []
        }
        const { formConfig, keyListItem, defaultValue } = parseFormConfig(rawConfig.forms[row], value, currentRow, rowsConfig[currentRow].length)
        // const form = await initFormConfig(rawConfig.forms[row][n])
        // data[form.key] = initFormValue(form, data)
        keyListRaw[formConfig.key] = keyListItem
        rowsConfig[currentRow].push(formConfig)
        value[formConfig.key] = value[formConfig.key] ?? defaultValue
      }
    }
    config.forms = rowsConfig

    $_VALUE.value = { ...value, ...$_VALUE.value }
    $_KEY_LIST.value = keyListRaw
    $_CONFIG.value = config
  }
}

/* function parseBinding (config) {
  const binding = {
    ...(config.config ?? {})
  }
  /!* if (config.options) {
    binding.options = config.options
  } *!/
  return binding
} */

function parseFormConfig (config, value, rowIndex, itemIndex) {
  const formConfig = { ...defaultForm, ...config }

  formConfig.componentRaw = formConfig.component ?? formConfig.type
  formConfig.component = getComponent(formConfig.componentRaw)
  const rawOptions = formConfig.options ?? formConfig.config?.options ?? []
  if (!formConfig.config) {
    formConfig.config = {}
  }
  delete formConfig.config.options
  if (isPromise(rawOptions)) {
    // console.log(formConfig.config.options)
    // formConfig.config.options = await formConfig.config.options
    rawOptions.then(options => {
      if (
        $_CONFIG.value.forms &&
        $_CONFIG.value.forms[rowIndex] &&
        $_CONFIG.value.forms[rowIndex][itemIndex] &&
        $_CONFIG.value.forms[rowIndex][itemIndex].config
      ) {
        $_CONFIG.value.forms[rowIndex][itemIndex].config.options = options
      }
    })
  } else {
    formConfig.config.options = rawOptions
  }
  /*
  // 判断是否有需要接口请求的选项
  if (formConfig.options) {
    if (!formConfig.config) {
      formConfig.config = {}
    }
    formConfig.config.options = formConfig.options
  } */

  if (formConfig.action) {
    for (const eventName in formConfig.action) {
      const func = formConfig.action[eventName]
      formConfig.action[eventName] = function (param, ...rest) {
        func(param)
      }
    }
  }
  // formConfig.binding = parseBinding(formConfig)
  formConfig.binding = formConfig.config
  formConfig.display = display(formConfig.display)
  // 转换label文字
  if (formConfig.label && typeOf(formConfig.label) === 'object') {
    formConfig.label = formConfig.label.text ?? ''
  }

  const defaultValue = ($_VALUE[formConfig.key] === undefined) ? (formConfig.value?.default ?? formConfig.default) : value[formConfig.key]
  const keyListItem = {
    display: display(formConfig.display),
    required: formConfig.required,
    validation: formConfig.validation ? parseValidationConfig(formConfig.validation) : undefined
  }

  return { formConfig, keyListItem, defaultValue }
}

onBeforeMount(() => {
  init()
})

defineExpose({ validate })
</script>
