import JSEncrypt from 'jsencrypt'
import PublicService from '@/services/public'

export default class Encrypt {
  instance = null
  key = null
  ready = false

  constructor () {
    if (!this.instance) {
      this.instance = new JSEncrypt()
      PublicService.getPublicKey()
        .then((data) => {
          this.key = data.public_key
          this.instance.setPublicKey(`-----BEGIN PUBLIC KEY-----
${data.public_key_base64}
-----END PUBLIC KEY-----`)
          this.ready = true
        })
    }
  }

  getKey () {
    return this.key
  }

  encode (message) {
    return new Promise(resolve => {
      if (this.ready) {
        resolve(this.instance.encrypt(message.toString()))
      } else {
        const timer = setInterval(() => {
          if (this.ready) {
            clearInterval(timer)
            resolve(this.instance.encrypt(message.toString()))
          }
        })
      }
    }, 10)
  }
}

/* export default async function (message) {
  // getPublicKey
  const encrypt = new JSEncrypt()
  const { key, publicKey } = await PublicService.getPublicKey()
  encrypt.setPublicKey(publicKey)
  return {
    key,
    data: encrypt.encrypt(message)
  }
} */
