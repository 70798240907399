<template>
  <div class="zero-table d-flex flex-column">
    <div class="card flex-grow-1">
      <div id="kt_app_table_header" v-if="title && title !== ''" class="card-header">
        <h4 class="card-title">{{ title }}</h4>
      </div>
      <ZeroSearch id="kt_app_table_search" v-if="method !== 'pure'" ref="zeroSearch" v-model="filterInfo" v-model:collapse="isSearchCollapse"
                   :class="title && title !== '' ? '' : 'pt-4'"
                   :columns="searchColumns" :filter="config.filter || {}"
                   :handleSearch="handleSearch" :no-reset="noSearchReset" :responsive="true" @search="initData($event, 'new')">
        <template v-for="(filter, i) in config.filter" #[`${i}-filter`]="{ data }">
          <slot :name="i + '-filter'" :data="data" :class-list="filter.class" :configs="filter.configs">
          </slot>
        </template>
      </ZeroSearch>
      <div class="separator" />
      <div id="kt_app_table_toolbar" class="card-header">
        <div class="card-title">
          <TableConfig v-model:column="tableColumnConfig" :column-list="config?.table?.fields" :table-name="$route.name" />
        </div>
        <div v-if="hasActionButtons" class="card-toolbar">
          <slot v-if="$slots['left-action-buttons']" name="left-action-buttons" />
          <template v-if="defaultImport">
            <b-button class="w-80px ms-2" variant="light-primary" @click="openFileImportModal">
              导入
            </b-button>
          </template>
          <template v-if="defaultExport">
            <b-button class="w-80px ms-2" variant="light-primary" @click="download">
              导出
            </b-button>
          </template>
          <template v-if="defaultAdd">
            <router-link :to="changePathToAdd()" class="btn btn-primary min-w-80px ms-2">
              <icon type="ri" name="add" mode="fill" class="p-0" />{{ defaultAddText }}
            </router-link>
          </template>
        </div>
      </div>
      <div ref="tableWrapper" class="card-body py-0 px-4 z-index-0">
        <slot :config="config" />
      </div>
    </div>
  </div>
  <b-modal v-model="fileImportModalShow" ref="modal-import" size="md" id="modal-import" title="文件导入" no-close-on-backdrop centered>
    <div class="form">
      <div class="form-group row">
        <label class="col-3">导入文件上传</label>
        <div class="col-9">
          <button type="button" @click="selectFile" class="btn btn-light-primary font-weight-bold btn-sm">{{ file ? file.name : '请选择需要导入的文件' }}</button>
          <input type="file" ref="file-import-input" style="display: none" accept=".xlsx" @change="getFile"/>
          <div class="form-text text-muted mt-3">请根据导入模板进行信息整理后上传.</div>
          <a v-if="importTemplateFile" :href="importTemplateFile" class="form-text mt-3">模版文件下载</a>
        </div>
      </div>
    </div>
    <template #footer>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button variant="primary" class="w-100px me-2" @click.prevent="upload">确定</b-button>
          <b-button variant="dark" class="w-90px" @click.prevent="closeFileImportModal">取消</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script setup>
import {
  defineEmits,
  onMounted,
  ref,
  watch,
  defineProps,
  defineExpose,
  onBeforeMount,
  onBeforeUnmount,
  nextTick
} from 'vue'
import ZeroSearch from './search.vue'
import event from '@/utils/event'
import { useRoute } from 'vue-router'
import { typeOf } from '../utils/common'

const route = useRoute()

const props = defineProps({
  // 表格配置，包含filter、table两个组件配置
  config: Object,
  // 是否显示搜索重置按钮
  noSearchReset: { type: Boolean, default: false },
  // 是否显示操作按钮
  hasActionButtons: { type: Boolean, default: true },
  // 表格标题内容
  title: { type: String },
  // search组件中一行显示的搜索项数量
  searchColumns: { type: [Number, String], default: 4 },
  // 是否开启导入
  defaultImport: { type: Boolean, default: false },
  // 是否开启导出
  defaultExport: { type: Boolean, default: false },
  // 是否开启新增
  defaultAdd: { type: Boolean, default: true },
  // 新增按钮文字
  defaultAddText: { type: String, default: '新增' },
  // 新增页面跳转路由
  addPath: { type: String, default: '' },
  // 导入模版文件
  importTemplateFile: { type: String, default: '' },
  // 获取表格内容的service，会默认调用其getList方法
  asyncService: Object,
  // 处理get前的param参数
  handleGet: Function,
  // 处理search前的filter参数
  handleSearch: Function,
  // 处理接口获取的的result数据
  handleResult: Function
})

const emits = defineEmits(['upload', 'download'])

const tableWrapper = ref()
const tableHeight = ref(400)
// 是否搜索折叠
const isSearchCollapse = ref(true)
// 定义table列配置
const fields = ref([])
// 定义table配置
const tableConfig = ref({})
// 定义table列配置
const tableColumnConfig = ref([])
// 定义表单内容项
const $_VALUE = ref([])
// 分页配置
const pagination = ref({
  total: 10,
  size: props.size,
  pages: 2,
  current: 1
})
const emptyText = ref(props.noItemLabel)

const $_INDEX_KEY = ref('index')

const computedFields = ref([])
const zeroSearch = ref(null)
const filterInfo = ref({})
const cellOptionWrapper = ref([])
const cellOptionWidth = ref(100)

watch(fields, fields => {
  event.emit('ZERO_TABLE.FIELD_CHANGED', 'FIELD_CHANGED')
})

watch(tableColumnConfig, tableColumnConfig => {
  event.emit('ZERO_TABLE.FIELD_CHANGED', 'CONFIG_CHANGED')
})

event.on('ZERO_TABLE.FIELD_CHANGED', ['CONFIG_CHANGED', 'FIELD_CHANGED'], () => {
  const resultFields = []
  const fieldsClone = [...fields.value]
  const tableConfig = [...tableColumnConfig.value]

  for (const n in tableConfig) {
    const index = fieldsClone.findIndex(item => item.key === tableConfig[n].key)
    if (index !== -1) {
      const config = fieldsClone[index]
      fieldsClone.splice(index, 1)
      switch (tableConfig[n].width) {
        case 'inherit': break
        case 'auto':
          config.width = 'auto'
          break
        default: {
          const width = parseInt(tableConfig[n].width)
          if (!isNaN(width)) {
            config.width = width.toString() + 'px'
          }
          break
        }
      }
      resultFields.push(config)
    }
  }

  for (const k in fields.value) {
    if (fields.value[k].key === 'index') {
      resultFields.unshift(fields.value[k])
      break
    }
  }
  computedFields.value = resultFields
})

const initData = async (orderParam, type) => {
  if (type && type === 'new') {
    pagination.value.current = 1
  }
  if (props.asyncService) {
    let filterInfoBack = Object.assign({}, filterInfo.value)
    if (filterInfoBack) {
      for (const i in filterInfoBack) {
        if (filterInfoBack[i] === null || filterInfoBack[i] === undefined || filterInfoBack[i] === '') {
          delete filterInfoBack[i]
        }
      }
      if (props.handleSearch && Object.keys(filterInfoBack).length > 0) filterInfoBack = props.handleSearch(filterInfoBack)
    }
    let param = { page: pagination.value.current, size: pagination.value.size, ...orderParam, ...filterInfoBack }
    param = props.handleGet ? props.handleGet(param) : param
    try {
      emptyText.value = props.noItemLabel
      const data = await props.asyncService.getList(param)
      if (data.records && props.handleResult) {
        data.records = props.handleResult(data.records)
      }
      if (typeOf(data) === 'array') {
        if (props.handleResult) {
          $_VALUE.value = props.handleResult(data)
          return
        }
        $_VALUE.value = data || []
      } else {
        $_VALUE.value = data?.records || []
      }
      pagination.value = data.pagination || pagination.value
    } catch (error) {
      emptyText.value = [`获取数据出错，${error?.data?.message}`, `${error?.data?.exception_clazz}`]
    }
  }
}

/**
 * @name reload
 * @description 重新加载数据
 * @return void
 */
const reload = () => {
  fields.value = []
  tableConfig.value = {}
  $_VALUE.value = []
  initData()
}

let file = ''
// 是否打开文件导入模态框
const fileImportModalShow = ref(false)

/**
 * @name upload
 * @description 上传文件
 * @return void
 */
const upload = async () => {
  // 上传文件
  const formData = new FormData()
  formData.append('file', file)
  // 触发上传事件
  emits('upload', formData)
  // 关闭模态框
  fileImportModalShow.value = false
}

/**
 * @name download
 * @description 下载文件
 * @return void
 */
const download = () => {
  // 不再进行事件触发，直接内部处理函数（待进行）
  emits('download')
}

// 用于文件选择的type为file的input
const fileImportInput = ref()

/**
 * @name selectFile
 * @description 选择文件
 * @return void
 */
const selectFile = () => {
  // 选择文件
  fileImportInput.value?.click()
}

/**
 * @name getFile
 * @description 获取文件
 * @param e input事件
 * @return void
 */
const getFile = (e) => {
  const aElement = e.target
  file = aElement.files?.[0] || ''
}

/**
 * @name openFileImportModal
 * @description 打开文件导入模态框
 * @return void
 */
const openFileImportModal = () => {
  // 打开文件导入模态框
  fileImportModalShow.value = true
}

const closeFileImportModal = () => {
  // 关闭文件导入模态框
  fileImportModalShow.value = false
}

/**
 * @name changePathToAdd
 * @description 新增地址转换
 * @description 用于新增按钮的跳转
 * @return string 新增地址
 */
const changePathToAdd = () => {
  // 新增地址转换
  return route.path.replace(/\/\w+$/, '/add')
}

function debounce (func, delay) {
  let timeoutId
  return () => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(func, delay)
  }
}

function parseIndexColumn () {
  for (const n in computedFields.value) {
    if (computedFields.value[n].key === 'index' || computedFields.value[n].type === 'index') {
      $_INDEX_KEY.value = computedFields.value[n].key
      return
    }
  }
}

onBeforeMount(() => {
  parseIndexColumn()
  window.addEventListener('resize', tableHeightListener())
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', tableHeightListener())
})

function tableHeightListener () {
  return debounce(() => {
    if (tableWrapper.value) {
      tableHeight.value = getTableHeight()
    }
  }, 10)
}

watch(cellOptionWrapper, value => {
  const minWidth = 100
  let width = 0
  for (const i in value) {
    const options = value[i].querySelectorAll('.cell-option')
    let currentWidth = 0
    for (const n in options) {
      if (options[n].clientWidth) {
        currentWidth += options[n].clientWidth + 12
      }
    }
    currentWidth += 32
    width = (currentWidth > width ? currentWidth : width)
  }
  cellOptionWidth.value = (minWidth > width ? minWidth : width)
  // tableInstance.value.refreshColumn()
}, { deep: true, once: true })

function getTableHeight () {
  const bodyHeight = document.body.clientHeight
  const heights = {
    pageHeader: document.querySelector('#kt_app_header')?.clientHeight ?? 0,
    breadcrumb: 32,
    tableHeader: document.querySelector('#kt_app_table_header')?.clientHeight ?? 0,
    search: document.querySelector('#kt_app_table_search')?.clientHeight ?? 0,
    searchPadding: document.querySelector('#kt_app_table_search') ? 16 : 0,
    tableToolbar: document.querySelector('#kt_app_table_toolbar')?.clientHeight ?? 0,
    tableFooter: 48,
    bottom: 16,
    separator: 4
  }
  let widgetHeight = 0
  for (const n in heights) {
    widgetHeight += heights[n]
  }
  return bodyHeight - widgetHeight
}

watch(isSearchCollapse, async value => {
  await nextTick()
  tableHeight.value = getTableHeight()
})

onMounted(() => {
  tableHeight.value = getTableHeight()
  changePathToAdd()
})

defineExpose({ reload, initData, fields: computedFields })
</script>
