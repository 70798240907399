export default [
  {
    path: '/knife/query/get',
    name: 'knife-query-get',
    component: () => import('../pages/query/get/list')
  },
  {
    path: '/knife/query/install',
    name: 'knife-query-install',
    component: () => import('../pages/query/install/list')
  },
  {
    path: '/knife/query/replace',
    name: 'knife-query-replace',
    component: () => import('../pages/query/replace/list')
  },
  {
    path: '/knife/query/enter-factory',
    name: 'knife-query-enter-factory',
    component: () => import('../pages/query/enter-factory')
  },
  {
    path: '/knife/query/check',
    name: 'knife-query-check',
    component: () => import('../pages/query/check')
  },
  {
    path: '/knife/query/scrap/list',
    name: 'knife-query-scrap-list',
    component: () => import('../pages/query/scrap/list.vue')
  },
  {
    path: '/knife/query/scrap/detail/:id',
    name: 'knife-query-scrap-detail',
    component: () => import('../pages/query/scrap/detail.vue')
  },
  {
    path: '/knife/query/scrap/change/:id',
    name: 'knife-query-scrap-change',
    component: () => import('../pages/query/scrap/change.vue')
  },
  {
    path: '/knife/exist/stock/list',
    name: 'knife-exist-stock-list',
    component: () => import('../pages/exist/stock/list')
  },
  {
    path: '/knife/exist/stock/adjustment/:id',
    name: 'knife-exist-stock-adjustment',
    component: () => import('../pages/exist/stock/adjustment')
  },
  {
    path: '/knife/exist/code/list',
    name: 'knife-exist-code-list',
    component: () => import('../pages/exist/code/list')
  },
  {
    path: '/knife/exist/code/adjustment/:id',
    name: 'knife-exist-code-adjustment',
    component: () => import('../pages/exist/code/adjustment')
  },
  {
    path: '/knife/statement/life/list',
    name: 'knife-statement-life-list',
    component: () => import('../pages/statement/life/list')
  },
  {
    path: '/knife/statement/life/detail',
    name: 'knife-statement-life-detail',
    component: () => import('../pages/statement/life/detail')
  }
]
