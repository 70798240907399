import { Restful, RestfulRequest, IntelliApi } from '@/services/intelli-api'

/**
 * IntelliApi Class
 * @class user
  **/
@Restful({
  name: 'user',
  path: 'system/user'
})
class UserService extends IntelliApi {
  @RestfulRequest({
    method: 'PUT',
    path: 'resetPwd/#{id}',
    parameters: [{ id: '-' }]
  })
  changePassByAdmin (id) {}

  @RestfulRequest({
    method: 'PUT',
    path: 'changeStatus/#{id}',
    parameters: [{ id: '-' }, 'data']
  })
  changeStatus (id, data) {}

  async getAllList () {
    try {
      const list = []
      const data = (await this.getList({ page: 1, size: 1000 })).records
      for (const i in data) {
        if (data[i].status !== undefined && data[i].status === 0) {
          list.push(
            { id: data[i].id, label: data[i].name }
          )
        }
      }
      return list
    } catch (e) {
      console.log(e)
    }
  }

  async getAllListNoStatus () {
    try {
      const list = []
      const data = (await this.getList({ page: 1, size: 1000 })).records
      for (const i in data) {
        list.push(
          { id: data[i].id, label: data[i].name }
        )
      }
      return list
    } catch (e) {
      console.log(e)
    }
  }
}

export default new UserService()
