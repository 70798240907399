const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
}

const resizeFunctions = []

function onResize (func) {
  resizeFunctions.push(func)
}

function getWidth () {
  return window.document.documentElement.clientWidth
}

function query () {
  let breakpoint = 'xs'
  const currentWidth = getWidth()
  for (const n in breakpoints) {
    if (currentWidth > breakpoints[n]) {
      breakpoint = n
    } else {
      break
    }
  }
  return breakpoint
}

function isXs () {
  return getWidth() < breakpoints.sm
}

function isSm () {
  return getWidth() > breakpoints.sm && getWidth() <= breakpoints.md
}

function isMd () {
  return getWidth() > breakpoints.md && getWidth() <= breakpoints.lg
}

function isLg () {
  return getWidth() > breakpoints.lg && getWidth() <= breakpoints.xl
}

function isXl () {
  return getWidth() > breakpoints.xl && getWidth() <= breakpoints.xxl
}
function isXxl () {
  return getWidth() >= breakpoints.xxl
}

function install (Vue, options) {
  window.onresize = () => {
    if (resizeFunctions.length > 0) {
      for (const n in resizeFunctions) {
        resizeFunctions[n](getWidth(), options)
      }
    }
  }
}

export { query, isXs, isSm, isMd, isLg, isXl, isXxl, onResize }

export default { install }
