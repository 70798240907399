const KEEPALIVE_EVENT = {
  ADD_INCLUDE: 'KEEPALIVE_EVENT:ADD_INCLUDE',
  REMOVE_INCLUDE: 'KEEPALIVE_EVENT:REMOVE_INCLUDE'
}

const includes = []
const cached = {}

export function setKeepAlive (routes) {
  for (const n in routes) {
    if (!routes[n].meta) {
      routes[n].meta = {}
    }
    routes[n].meta.keepAlive = routes[n].meta.keepAlive ?? true
    includes.push(routes[n].name)
  }
  return routes
}

export function setCached (key, item) {
  cached[key] = item
}

export function removeCached (key) {
  delete cached[key]
}

export function getCached (key) {
  return cached[key]
}

export function getIncludes () {
  return [...includes]
}

export function setComponentName (component, route) {
  component.type.name = route.name
  return component
}

export {
  KEEPALIVE_EVENT
}
