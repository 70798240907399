export default [
  {
    path: '/sop/sop-files/list',
    name: 'sop-sop-files-list',
    component: () => import('../pages/sop-files/list')
  },
  {
    path: '/sop/sop-files/send/:id',
    name: 'sop-sop-files-send',
    component: () => import('../pages/sop-files/send.vue')
  },
  {
    path: '/sop/sop-files/add/:id',
    name: 'sop-sop-files-add',
    component: () => import('../pages/sop-files/add')
  },
  {
    path: '/sop/sop-recovery/list',
    name: 'sop-sop-recovery-list',
    component: () => import('../pages/sop-recovery/list')
  },
  {
    path: '/sop/sop-history/:id',
    name: 'sop-sop-history',
    component: () => import('../pages/sop-history/list')
  },
  {
    path: '/sop/sop-device/list',
    name: 'sop-sop-device-list',
    component: () => import('../pages/sop-device/list')
  },
  {
    path: '/sop/sop-send',
    name: 'sop-sop-send',
    component: () => import('../pages/sop-history/send.vue')
  },
  {
    path: '/sop/sop-document/list',
    name: 'sop-sop-document-list',
    component: () => import('../pages/sop-document/list')
  },
  {
    path: '/sop/sop-document/add',
    name: 'sop-sop-document-add',
    component: () => import('../pages/sop-document/add')
  },
  {
    path: '/sop/sop-document/change/:id',
    name: 'sop-sop-document-change',
    component: () => import('../pages/sop-document/change')
  },
  {
    path: '/sop/sop-approval/list',
    name: 'sop-sop-approval-list',
    component: () => import('../pages/sop-approval/list')
  },
  {
    path: '/sop/sop-approval/add/:id',
    name: 'sop-sop-approval-add',
    component: () => import('../pages/sop-approval/add')
  },
  {
    path: '/sop/sop-authorize/list',
    name: 'sop-sop-authorize-list',
    component: () => import('../pages/sop-authorize/list')
  },
  {
    path: '/sop/sop-authorize/add',
    name: 'sop-sop-authorize-add',
    component: () => import('../pages/sop-authorize/add')
  },
  {
    path: '/sop/sop-authorize/change/:id',
    name: 'sop-sop-authorize-change',
    component: () => import('../pages/sop-authorize/change')
  },
  {
    path: '/sop/sop-upload/list',
    name: 'sop-sop-upload-list',
    component: () => import('../pages/sop-upload/list')
  },
  {
    path: '/sop/sop-upload/add',
    name: 'sop-sop-upload-add',
    component: () => import('../pages/sop-upload/add')
  },
  {
    path: '/sop/sop-upload/change/:id',
    name: 'sop-sop-upload-change',
    component: () => import('../pages/sop-upload/change')
  },
  {
    path: '/sop/sop-upload/detail/:id',
    name: 'sop-sop-upload-detail',
    component: () => import('../pages/sop-upload/detail')
  }
]
