<template>
  <p v-if="props.static" class="form-control-plaintext text-gray-800" :class="props.class ?? []">{{ computedValue?.text ?? computedValue?.value ?? '' }}</p>
<!--  <b-form-radio-group v-else v-bind="computedAttrs" v-model="data"></b-form-radio-group>-->
  <b-form-radio-group v-else :state="!invalid" :options="options" v-model="modelValue" />
</template>

<script setup>
import { defineProps, defineModel, computed, defineExpose, ref } from 'vue'
import { isEmpty } from '@/components/zero-vue/utils/common'

const props = defineProps({
  class: {},
  static: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  label: { type: String, default: '' },
  name: { type: String, default: '' },
  options: { type: Array, required: true },
  data: {}
})

const modelValue = defineModel()
const invalid = ref(false)

const computedValue = computed(() => {
  return props.options.find(option => option.value === props.data)
})

function validate () {
  if (props.required && isEmpty(modelValue.value)) {
    invalid.value = true
    return { status: 1, message: `${props.label ?? props.name ?? '此项'}必填` }
  } else {
    invalid.value = false
    return { status: 0, message: '' }
  }
}

defineExpose({ validate })
</script>
