import { BasicOverwriteRoutes, BasicRoutes } from '@/modular/custom/modular/basic/routes'
import { ProductionOverwriteRoutes, ProductionRoutes } from '@/modular/custom/modular/production/routes'
import { MaterialRoutes, MaterialOverwriteRoutes } from '@/modular/custom/modular/material/routes'

const CustomRoutes = [
  ...BasicRoutes,
  ...ProductionRoutes,
  ...MaterialRoutes
]
const CustomOverwriteRoutes = [
  ...BasicOverwriteRoutes,
  ...ProductionOverwriteRoutes,
  ...MaterialOverwriteRoutes
]

export {
  CustomRoutes,
  CustomOverwriteRoutes
}
