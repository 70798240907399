import { createRouter, createWebHistory } from 'vue-router'
import { overwrite } from './overwrite'
import Layout from '@/components/layout'
import AppContainer from '@/components/container/app'
import AccountLogin from '@/pages/account/login'
import ConsoleLogin from '@/pages/console/login'
import Overview from '@/pages/overview'
import Notification from '@/pages/notification'
import AccountChange from '@/pages/account/change'
import RoutesPortal from '@/modular/portal/routes'
import BaseApp from '@/modular/base/routes'

import { DashboardApp, FullscreenDashboard } from '@/modular/dashboard/routes'

import { CustomRoutes } from '@/modular/custom/routes'

const routeList = [
  {
    path: '/account/login',
    name: 'Login',
    component: AccountLogin
  },
  {
    path: '/console/login',
    name: 'ConsoleLogin',
    component: ConsoleLogin
  },

  FullscreenDashboard,

  ...RoutesPortal,
  {
    path: '/',
    // name: 'Home',
    component: Layout,
    meta: {
      title: '云MES'
    },
    children: [
      {
        path: '',
        alias: '/overview',
        name: 'overview',
        component: Overview,
        meta: {
          title: '工作台'
        }
      },
      /* {
        path: '/overview',
        name: 'overview',
        component: Overview,
        meta: {
          title: '工作台'
        }
      }, */
      {
        path: '/notification',
        name: 'notification',
        component: Notification,
        meta: {
          title: '通知管理'
        },
        children: [
          {
            path: '/notification/list',
            name: 'notification-list',
            component: Notification,
            meta: {
              title: '通知列表'
            }
          }
        ]
      },
      {
        path: '/account/change',
        name: 'account-change',
        component: AccountChange
      },
      ...BaseApp,
      ...CustomRoutes,

      DashboardApp,

      {
        path: '/:path(.*)',
        name: 'Application',
        component: AppContainer
      }
    ]
  }
]

function createWebHistoryWithBase () {
  const pathStack = location.pathname.split('/')
  let base = ''

  if (pathStack.length > 1 && pathStack[1] === process.env.APP_PREFIX) {
    base = process.env.APP_PREFIX
  }
  return createWebHistory(base)
}

export const router = createRouter({
  history: createWebHistoryWithBase(),
  routes: overwrite(routeList)
})

export function setupRouter (app) {
  app.use(router)
}

export const routes = router.getRoutes()

export default router
