<template>
  <router-view />
</template>
<script>
import { Component, Vue } from '@min/vue-compatible-decorator'

export default
@Component()
class AppContainer extends Vue {
}
</script>
