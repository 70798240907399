<template>
  <action-item
    ref="action-item"
    id="kt_header_user_menu_toggle"
    hover-width="200"
    expand-width="350"
    expand-height="310px"
    action-thumb-class="d-flex"
    action-expand-class="py-0"
    variant="color-state">
    <template #action-thumb>
      <div class="symbol symbol-circle symbol-30px me-2 align-self-center">
        <div class="symbol-label fs-4 fw-normal bg-primary text-light" :style="{ width: '2rem', height: '2rem' }">{{ profileFirstName }}</div>
      </div>
      <div class="w-150px d-flex flex-column flex-shrink-0 hover-show active-show">
        <span class="text-gray-300 me-3 text-end">您好, <span class="text-gray-600 fw-bold">{{ profile.name }}</span></span>
        <span v-if="profile.authorities && profile.authorities[0]" class="me-3 text-gray-300 text-end">{{ profile.authorities[0].authority }}</span>
      </div>
    </template>
    <template #action-expand>
      <div class="menu-item p-4 bg-primary background-with-logo">
        <div class="menu-content position-relative d-flex justify-content-between p-0">
          <div>
            <div class="fs-4 text-gray-100">
              {{ profile.name }}
            </div>
            <span class="badge badge-light-primary fs-8 w-auto px-2 py-1 my-1">{{ tenant.extJson?.brandName || '' }}</span>
          </div>
          <div class="symbol symbol-circle symbol-40px">
            <div class="symbol-label fs-4 fw-normal bg-primary border-gray-200 border border-1 text-light-primary">{{ profileFirstName }}</div>
          </div>
        </div>
        <div class="menu-content position-relative p-0 d-flex flex-column">
          <span class="text-light-primary pt-2">工厂账号<span class="ms-10">{{ tenant.account }}</span></span>
          <span class="text-light-primary pt-2">用户账号<span class="ms-10">{{ profile.account }}</span></span>
        </div>
      </div>
      <div class="menu-item p-0">
        <a href="#" @click.prevent="showProfile('overview')" class="menu-link">账号信息</a>
        <a href="#" @click.prevent="showProfile('role')" class="menu-link">
          <span class="menu-title">账号权限</span>
          <span v-if="profile.authorities && profile.authorities[0]" class="menu-badge">
            <span class="badge badge-light-primary">{{ profile.authorities[0].authority }}</span>
          </span>
        </a>
      </div>
      <div class="separator my-0"></div>
      <!--begin::Menu item-->
      <div class="menu-item p-0">
        <a href="#" @click.prevent="showProfile('config')" class="menu-link">
          账号设置
        </a>
        <a href="#" class="menu-link" @click.prevent="showAbout">关于</a>
      </div>
      <div class="separator my-0"></div>
      <div class="menu-item p-0">
        <a href="#" class="menu-link" @click.prevent="logout">退出登录</a>
      </div>
    </template>
  </action-item>
  <profile-modal ref="profileModal" :profile="profile" :tenant="tenant" />
  <about ref="about" />
</template>

<script>
import { Component, Prop, Vue } from '@min/vue-compatible-decorator'
import ActionItem from '@/components/layout/header-components/action-item'
import AccountService from '@/services/account'
import notification from '@/utils/notification'
import About from '@/components/layout/about.vue'
import ProfileModal from '@/components/layout/profile-modal.vue'
import { clear } from '@/components/layout/page-tabs'

@Component({
  components: { ActionItem, About, ProfileModal }
})
class ProfileCard extends Vue {
  @Prop({ type: Object, default: () => {} }) profile
  @Prop({ type: Object, default: () => {} }) tenant

  active = false

  get profileFirstName () {
    if (this.profile.name) {
      return this.profile.name.substring(0, 1)
    }
    return ''
  }

  /**
   * @name logout
   * @description 退出登录
   * @returns {Promise<void>}
   */
  async logout () {
    this.actionExpandHide()
    notification.confirm('确定退出登录').then(
      function (isConfirm) {
        if (isConfirm.value === true) {
          clear()
          AccountService.logout()
        }
      }
    )
  }

  showAbout () {
    this.$refs.about.show()
    this.actionExpandHide()
  }

  showProfile (action) {
    this.$refs.profileModal.show(action)
    this.actionExpandHide()
  }

  actionExpandHide () {
    this.$refs['action-item'].hide()
  }
}

export default ProfileCard
</script>
