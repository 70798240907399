<template>
  <template v-if="modalComponent">
    <slot />
  </template>
  <div v-else id="kt_app_content" ref="wrapper" :class="['app-content flex-column-fluid footer-sticky-bottom header-sticky-top', stickiedBottom ? 'sticky-bottom-on' : '', stickiedTop ? 'sticky-top-on' : '']">
    <div class="d-flex flex-row flex-column-fluid app-container-wrapper h-100" :class="iframe ? 'p-0' : ''">
      <div class="app-container container-fluid" id="kt_app_content_container" :class="iframe ? 'p-0' : ''">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from '@min/vue-compatible-decorator'
import { useMenuStore } from '@/stores/menu'

export default @Component({
  provide () {
    return {
      modalComponentStatus: this.modalComponent
    }
  }
})
class Wrapper extends Vue {
  @Prop({ type: Boolean, default: false }) toolbar
  @Prop({ type: Boolean, default: false }) iframe
  @Prop({ type: Boolean, default: false }) noHeader
  @Prop({ type: Boolean, default: true }) headerStickyTop
  @Prop({ type: Boolean, default: false }) defaultImport
  @Prop({ type: Boolean, default: false }) defaultExport
  @Prop() back
  @Prop({ type: Boolean, default: false })
  modalComponent

  /**
   * @Provide('modalComponentStatus')
   * modalComponentStatus = this.modalComponent
   */

  file = null
  stickiedTop = false
  stickiedBottom = true
  breadcrumb = []
  currentPage = {}

  mounted () {
    const menu = useMenuStore()
    menu.setBackMenu(this.back)
  }

  scrollHandle (e) {
    const top = e.target.documentElement.scrollTop || e.target.body.scrollTop
    const bottom = (e.target.documentElement.scrollTop || e.target.body.scrollTop) + (e.target.documentElement.clientHeight || e.target.body.clientHeight) - (e.target.documentElement.scrollHeight || e.target.body.scrollHeight)

    if (top > 145) {
      this.stickiedTop = true
    } else {
      this.stickiedTop = false
    }

    if (bottom < -50) {
      this.stickiedBottom = true
    } else {
      this.stickiedBottom = false
    }
  }

  selectFile () {
    this.$refs['file-import-input'].$refs.input.click()
  }

  upload () {
    const formData = new FormData()
    formData.append('file', this.file)
    this.$emit('upload', formData)
    this.$refs['modal-import'].hide()
  }

  download () {
    this.$emit('download')
  }

  /* async mounted () {
    let data = {
      parents: [],
      route: {}
    }
    const promise = new Promise((resolve, reject) => {
      if (useProfileStore().isInited) {
        useMenuStore().getMenuByNameWithParents(this.$route.name).then(response => {
          data = response
          resolve()
        })
      } else {
        const timer = setInterval(async () => {
          if (useProfileStore().isInited) {
            try {
              data = useMenuStore().getMenuByNameWithParents(this.$route.name)
            } catch (e) {}
            clearInterval(timer)
            resolve()
          }
        }, 10)
      }
    })

    // when(Profile.isInited)
    //   .run(() => {
    //     data = Menu.getMenuByNameWithParents(this.$route.name)
    //     this.breadcrumb = data.parents
    //     this.currentPage = data.route
    //   })

    promise.then(() => {
      this.breadcrumb = data.parents
      this.currentPage = data.route
    })
    // this.breadcrumb.push(data.route)

    window.addEventListener('scroll', this.scrollHandle)
  }

  destroyed () {
    window.removeEventListener('scroll', this.scrollHandle)
  } */
}
</script>
