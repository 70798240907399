/*
 * @Author: xuzhitao
 * @Date: 2023-01-04 17:11:03
 * @LastEditors: xuzhitao
 * @LastEditTime: 2023-02-20 14:47:17
 * @FilePath: /intellidevice-web-app/src/components/registerGlobComp.ts
 */

import VueSweetalert2 from 'vue-sweetalert2'
import BootstrapVue from 'bootstrap-vue-next'
import ZeroVue from '@/components/zero-vue'
import Wrapper from './layout/wrapper'
import SvgIcon from '@/components/zero-vue/components/svg-icon' // 引入组件
import VueQrcode from '@chenfengyuan/vue-qrcode' // 引入二维码组件
import MediaQuery from './layout/media-query'
import VueDiff from 'vue-diff'
import VueClipboard from 'vue-clipboard3'
import Pro from '@/components/layout/pro'
import UserGroupSelect from '@/components/public/user-group-select.vue'
// import wandEnum from '@/enums/util/index'

// VueClipboard.config.autoSetContainer = true

export function registerGlobalComponents (app) {
  app
    .use(BootstrapVue)
    .use(VueSweetalert2)
    .use(ZeroVue)
    .use(MediaQuery)
    .use(VueClipboard)
    .use(VueDiff, { componentName: 'VueDiff' })
    // .use(wandEnum)

  // eslint-disable-next-line vue/multi-word-component-names
  app.component('Wrapper', Wrapper)
  app.component('Pro', Pro)
  app.component('SvgIcon', SvgIcon)
  app.component('UserGroupSelect', UserGroupSelect)
  app.component(VueQrcode.name, VueQrcode)
}
