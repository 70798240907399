/*
 * @Author: xuzhitao
 * @Date: 2023-01-04 16:36:41
 * @LastEditors: xuzhitao
 * @LastEditTime: 2023-01-05 08:53:23
 * @FilePath: /mirrors.in-mes.com-ui/src/router/guard/permissionGuard.ts
 */
import AccountService from '@/services/account'

export function createPermissionGuard (router) {
  // 路由 鉴权
  router.beforeResolve(async (to, from, next) => {
    // next()
    if (['/account/login', '/account/sso/login', '/console/login'].includes(to.path)) {
      next()
    } else if (await AccountService.check()) {
      next()
    } else {
      next('/account/login')
    }
  })
}
