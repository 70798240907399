<template>
<div class="w-100 h-100">
  <router-view />
</div>
</template>

<script>
// 修饰器依赖
import { Component, Vue } from '@min/vue-compatible-decorator'
export default
@Component()
class LayoutFullscreen extends Vue {}
</script>
