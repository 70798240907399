<template>
  <b-modal id="modal-profile" v-model="modalProfileShow" centered size="lg" hide-footer>
    <b-row class="mb-6">
      <b-col cols="12">
        <div class="d-flex flex-wrap">
          <div class="symbol symbol-100px symbol-fixed position-relative me-7">
            <div class="symbol-label fs-4x fw-semibold bg-secondary bg-hover-primary text-gray-500 text-hover-light">{{ profileFirstName }}</div>
            <div class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
          </div>
          <div class="flex-grow-1">
            <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center mb-2">
                  <a href="#" class="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{{ profile.name }}</a>
                </div>
                <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                  <a v-if="profile.authorities && profile.authorities[0]" href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                    <icon name="profile-circle" class="fs-4 me-1" paths="3" />{{ profile.authorities[0].authority }}
                  </a>
                  <a v-if="profile.tel && profile.tel !== ''" href="#" class="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                    <icon name="sms" class="fs-4 me-1" paths="2" />{{ profile.tel }}
                  </a>
                  <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                    <icon name="geolocation" paths="2" class="fs-4 me-1" />
                    <template v-if="profile.last_login_time && profile.last_login_time !== ''">
                      上次登录: {{ profile.last_login_time }}
                    </template>
                    <template v-else>
                      首次登录
                    </template>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="h-500px">
      <b-col>
        <ul class="nav nav-tabs nav-line-tabs mb-5 fs-5">
          <li class="nav-item">
            <a :class="['nav-link', action === 'overview'?'active':'']" data-bs-toggle="tab" href="#tab-profile-base">账号信息</a>
          </li>
          <li class="nav-item">
            <a :class="['nav-link', action === 'config'?'active':'']" data-bs-toggle="tab" href="#tab-profile-config">账号设置</a>
          </li>
        </ul>
            <div class="tab-content">
              <div :class="['tab-pane fade', action === 'overview'?'show active':'']" id="tab-profile-base" role="tabpanel">
                <b-row>
                  <b-col cols="10" offset="1">
                    <b-row class="my-7">
                      <label class="col-lg-4 fw-semibold text-muted">工厂名称</label>
                      <div class="col-lg-8">
                        <span class="fw-bold fs-6 text-gray-800">{{ tenant.name }}</span>
                      </div>
                    </b-row>
                    <b-row class="mb-7">
                      <label class="col-lg-4 fw-semibold text-muted">工厂账号</label>
                      <div class="col-lg-8">
                        <span class="fw-bold fs-6 text-gray-800">{{ tenant.account }}</span>
                      </div>
                    </b-row>
                    <b-row class="mb-7">
                      <label class="col-lg-4 fw-semibold text-muted">用户账号</label>
                      <div class="col-lg-8">
                        <span class="fw-bold fs-6 text-gray-800">{{ profile.username }}</span>
                      </div>
                    </b-row>
                    <b-row class="mb-7">
                      <label class="col-lg-4 fw-semibold text-muted">姓名</label>
                      <div class="col-lg-8">
                        <span class="fw-bold fs-6 text-gray-800">{{ profile.name }}</span>
                      </div>
                    </b-row>
                    <b-row class="mb-7">
                      <label class="col-lg-4 fw-semibold text-muted">用户角色</label>
                      <div class="col-lg-8">
                        <span :key="`authority-${index}`" v-for="(authority, index) in profile.authorities" class="fw-bold fs-6 text-gray-800 me-2">{{ authority.authority }}</span>
                      </div>
                    </b-row>
                    <b-row class="mb-7">
                      <label class="col-lg-4 fw-semibold text-muted">电话</label>
                      <div class="col-lg-8">
                        <span class="fw-bold fs-6 text-gray-800">{{ profile.phone }}</span>
                      </div>
                    </b-row>
                    <b-row class="mb-7">
                      <label class="col-lg-4 fw-semibold text-muted">邮箱</label>
                      <div class="col-lg-8">
                        <span class="fw-bold fs-6 text-gray-800">{{ profile.email }}</span>
                      </div>
                    </b-row>
                    <b-row class="mb-7">
                      <label class="col-lg-4 fw-semibold text-muted">状态</label>
                      <div class="col-lg-8">
                        <span v-if="profile.status === 0" class="badge badge-success">正常</span>
                        <span v-else class="badge badge-danger">停用</span>
                      </div>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
              <div :class="['tab-pane fade', action === 'config'?'show active':'']" id="tab-profile-config" role="tabpanel">
                <div class="d-flex flex-wrap align-items-center my-10">
                  <div v-if="changingPassword" class="flex-row-fluid">
                    <!--begin::Form-->
                    <form class="form fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                      <div class="row mb-4">
                        <div class="col-lg-4">
                          <div class="fv-row mb-0 fv-plugins-icon-container">
                            <label for="currentpassword" class="form-label fs-6 fw-bold mb-3">当前密码</label>
                            <input type="password" class="form-control form-control-solid" v-model="passwordField.currentPassword" name="currentpassword" id="currentpassword">
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="fv-row mb-0 fv-plugins-icon-container">
                            <label for="newpassword" class="form-label fs-6 fw-bold mb-3">新密码</label>
                            <input type="password" class="form-control form-control-solid" v-model="passwordField.newPassword" name="newpassword" id="newpassword" aria-autocomplete="list">
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="fv-row mb-0 fv-plugins-icon-container">
                            <label for="confirmpassword" class="form-label fs-6 fw-bold mb-3">确认密码</label>
                            <input type="password" class="form-control form-control-solid" v-model="passwordField.confirmPassword" name="confirmpassword" id="confirmpassword">
                          </div>
                        </div>
                      </div>
                      <div class="d-flex">
                        <button id="kt_password_submit" type="button" class="btn btn-primary me-2 px-6" @click.prevent="changePassword">更新密码</button>
                        <button id="kt_password_cancel" type="button" class="btn btn-color-gray-400 btn-active-light-primary px-6"  @click.prevent="changingPassword = false">取消</button>
                      </div>
                    </form>
                  </div>
                  <template v-else>
                    <div>
                      <div class="fs-6 fw-bold mb-1">密码</div>
                      <div class="fw-semibold text-gray-600">************</div>
                    </div>
                    <div class="ms-auto">
                      <button class="btn btn-light btn-active-light-primary" @click.prevent="changingPassword = true">修改密码</button>
                    </div>
                  </template>
                </div>
              </div>
            </div>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { Vue, Component, Prop } from '@min/vue-compatible-decorator'
import Encrypt from '@/utils/encrypt'
import AccountService from '@/services/account'
import notification from '@/utils/notification'

@Component()
class Profile extends Vue {
  @Prop({ type: Object })
  profile

  @Prop({ type: Object })
  tenant

  // @Prop({ type: String, default: 'base' })
  action = 'overview'

  modalProfileShow = false
  changingPassword = false

  passwordField = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  }

  get profileFirstName () {
    if (this.profile.name) {
      return this.profile.name.substring(0, 1)
    }
    return ''
  }

  show (action) {
    this.modalProfileShow = true
    this.action = action
  }

  async changePassword () {
    try {
      const encrypt = new Encrypt()
      console.log(this.passwordField)
      const password = {
        password: await encrypt.encode(this.passwordField.currentPassword),
        new_password: await encrypt.encode(this.passwordField.newPassword),
        repeat_password: await encrypt.encode(this.passwordField.confirmPassword),
        public_key: encrypt.getKey()
      }
      console.log(password)
      await AccountService.changePassword(password)
      await notification.success('修改成功', '')
      await AccountService.logout()
    } catch (e) {
      notification.error('修改失败', e.data.message)
    }
  }
}

export default Profile
</script>
