<template>
  <div>
    <button v-if="!disabled" type="button" @click="selectFile" class="btn btn-light-primary font-weight-bold btn-sm">请选择文件</button>
    <b-form-file v-model="file" ref="file-import-input" style="display: none" @cancel.stop />
    <div v-for="(item, i) in fileList" class="form-text m-2 d-flex" :key="i">
      <div class="me-3" style="word-break: break-all;">{{ item.name }}</div>
      <cell-option v-if="!disabled" variant="danger" @function="deleteOne(i)" style="width: 2rem;">删除</cell-option>
      <a :href="item.fullUrl" variant="success" target="_blank" style="width: 2.5rem;">查看</a>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from '@min/vue-compatible-decorator'
import FileService from '@/services/file'
import notification from '@/utils/notification'

@Component({})
class FileUpload extends Vue {
  @Prop() modelValue
  @Prop({ default: false }) disabled

  file = null
  fileList = []

  /**
   * 选择文件
   */
  selectFile () {
    this.$refs['file-import-input'].$refs.input.click()
  }

  deleteOne (i) {
    this.fileList.splice(i, 1)
  }

  async detailOne (i) {
    try {
      await FileService.downloadFile(this.fileList[i].url)
    } catch (e) {
      notification.error('下载失败', '')
    }
  }

  /**
   * 监听上传文件
   */
  @Watch('file', { deep: true })
  async uploadFile () {
    if (this.file) {
      try {
        const formData = new FormData()
        formData.append('file', this.file)
        const url = await FileService.uploadFile(formData)
        this.fileList.push({ name: this.file.name.toString(), url: url })
        notification.toast.success('上传成功', '', 2000, { position: 'top-end' })
        this.file = null
      } catch (e) {
        notification.error('上传失败', '上传失败，请重试！')
      }
    }
  }

  @Watch('fileList', { deep: true })
  fileListChange () {
    const list = this.fileList.map(item => item.url).join(',')
    this.$emit('update:modelValue', list)
  }

  @Watch('modelValue', { immediate: true })
  async onChangeValue () {
    console.log(this.modelValue)
    if (this.modelValue && this.modelValue.length > 0) {
      const list = this.modelValue.split(',')
      const fileList = []
      for (const item of list) {
        const info = await FileService.getFileDetail(item)
        fileList.push({ name: info.file_origin_name, url: item, fullUrl: info.file_path })
      }
      this.fileList = fileList
    }
  }
}

export default FileUpload
</script>

<style scoped>

</style>
