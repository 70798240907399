import typeOf from 'typeof'
import { v4 as uuid4 } from 'uuid'

function uuid () {
  return uuid4()
}

function isEmpty (value) {
  try {
    if ([null, undefined, '', NaN].includes(value)) {
      return true
    }
    switch (typeOf(value)) {
      case 'number':
      case 'boolean':
      case 'string':
        return false
      case 'array':
        return value.length === 0
      case 'object':
      default:
        return JSON.stringify(value) === '{}'
    }
  } catch (e) {
    console.error('%O %O', e, value)
  }
}

function isArray (value) {
  return Array.isArray(value)
}

function isNumber (value) {
  return typeOf(value) === 'number'
}

// 判断是否是promise函数
function isPromise (object) {
  return object && Object.prototype.toString.call(object) === '[object Promise]'
}

function isEqual (value1, value2) {
  try {
    const type1 = typeOf(value1)
    const type2 = typeOf(value2)
    if (type1 !== type2) return false
    switch (type1) {
      case 'number':
      case 'boolean':
      case 'string':
        return value1 === value2
      case 'array':
        if (value1.length !== value2.length) return false
        for (const n in value1) {
          if (!isEqual(value1[n], value2[n])) { return false }
        }
        return true
      case 'object': {
        const valueKeys1 = Object.keys(value1)
        const valueKeys2 = Object.keys(value2)
        if (valueKeys1.length !== valueKeys2.length) return false
        for (const n in value1) {
          if (!isEqual(value1[n], value2[n])) return false
        }
        return true
      }
      default:
        return value1 === value2
    }
  } catch (e) {
    console.error('%O %O %O', e, value1, value2)
  }
}

function display (displayFunction) {
  if (typeOf(displayFunction) === 'function') {
    return displayFunction
  } else {
    return () => true
  }
}

export { typeOf, isArray, isNumber, isEmpty, isEqual, isPromise, display, uuid }
