export default [
  {
    path: '/device/repair/report-order/list',
    name: 'device-repair-report-order-list',
    component: () => import('../pages/repair/report-order/list')
  },
  {
    path: '/device/repair/report-order/add',
    name: 'device-repair-report-order-add',
    component: () => import('../pages/repair/report-order/add')
  },
  {
    path: '/device/repair/report-order/change/:id',
    name: 'device-repair-report-order-change',
    component: () => import('../pages/repair/report-order/change')
  },
  {
    path: '/device/repair/report-order/detail/:id',
    name: 'device-repair-report-order-detail',
    component: () => import('../pages/repair/report-order/detail')
  },
  {
    path: '/device/repair/repair-order/list',
    name: 'device-repair-repair-order-list',
    component: () => import('../pages/repair/repair-order/list')
  },
  {
    path: '/device/repair/repair-order/add',
    name: 'device-repair-repair-order-add',
    component: () => import('../pages/repair/repair-order/add')
  },
  {
    path: '/device/repair/repair-order/create/:id',
    name: 'device-repair-repair-order-create',
    component: () => import('../pages/repair/repair-order/create')
  },
  {
    path: '/device/repair/repair-order/change/:id',
    name: 'device-repair-repair-order-change',
    component: () => import('../pages/repair/repair-order/change')
  },
  {
    path: '/device/repair/repair-order/detail/:id',
    name: 'device-repair-repair-order-detail',
    component: () => import('../pages/repair/repair-order/detail')
  },
  {
    path: '/device/repair/repair-scheme/list',
    name: 'device-repair-repair-scheme-list',
    component: () => import('../pages/repair/repair-scheme/list')
  },
  {
    path: '/device/repair/repair-scheme/add',
    name: 'device-repair-repair-scheme-add',
    component: () => import('../pages/repair/repair-scheme/add')
  },
  {
    path: '/device/repair/repair-scheme/change/:id',
    name: 'device-repair-repair-scheme-change',
    component: () => import('../pages/repair/repair-scheme/change')
  },
  {
    path: '/device/maintain/items/list',
    name: 'device-maintain-items-list',
    component: () => import('../pages/maintain/items/list')
  },
  {
    path: '/device/maintain/items/add',
    name: 'device-maintain-items-add',
    component: () => import('../pages/maintain/items/add')
  },
  {
    path: '/device/maintain/items/change/:id',
    name: 'device-maintain-items-change',
    component: () => import('../pages/maintain/items/change')
  },
  {
    path: '/device/maintain/items/detail/:id',
    name: 'device-maintain-items-detail',
    component: () => import('../pages/maintain/items/detail')
  },
  {
    path: '/device/maintain/rule/list',
    name: 'device-maintain-rule-list',
    component: () => import('../pages/maintain/rule/list')
  },
  {
    path: '/device/maintain/rule/add',
    name: 'device-maintain-rule-add',
    component: () => import('../pages/maintain/rule/add')
  },
  {
    path: '/device/maintain/rule/change/:id',
    name: 'device-maintain-rule-change',
    component: () => import('../pages/maintain/rule/change')
  },
  {
    path: '/device/maintain/rule/detail/:id',
    name: 'device-maintain-rule-detail',
    component: () => import('../pages/maintain/rule/detail')
  },
  {
    path: '/device/maintain/plan/list',
    name: 'device-maintain-plan-list',
    component: () => import('../pages/maintain/plan/list')
  },
  {
    path: '/device/maintain/plan/add',
    name: 'device-maintain-plan-add',
    component: () => import('../pages/maintain/plan/add')
  },
  {
    path: '/device/maintain/plan/change/:id',
    name: 'device-maintain-plan-change',
    component: () => import('../pages/maintain/plan/change')
  },
  {
    path: '/device/maintain/plan/detail/:id',
    name: 'device-maintain-plan-detail',
    component: () => import('../pages/maintain/plan/detail')
  },
  {
    path: '/device/maintain/bill/list',
    name: 'device-maintain-bill-list',
    component: () => import('../pages/maintain/bill/list')
  },
  {
    path: '/device/maintain/bill/add',
    name: 'device-maintain-bill-add',
    component: () => import('../pages/maintain/bill/add')
  },
  {
    path: '/device/maintain/bill/change/:id',
    name: 'device-maintain-bill-change',
    component: () => import('../pages/maintain/bill/change')
  },
  {
    path: '/device/maintain/bill/detail/:id',
    name: 'device-maintain-bill-detail',
    component: () => import('../pages/maintain/bill/detail')
  },
  {
    path: '/device/maintain/record/list',
    name: 'device-maintain-record-list',
    component: () => import('../pages/maintain/record/list')
  },
  {
    path: '/device/maintain/record/detail/:id',
    name: 'device-maintain-record-detail',
    component: () => import('../pages/maintain/record/detail')
  },
  {
    path: '/device/spare/archives/list',
    name: 'device-spare-archives-list',
    component: () => import('../pages/spare/archives/list')
  },
  {
    path: '/device/spare/archives/add',
    name: 'device-spare-archives-add',
    component: () => import('../pages/spare/archives/add')
  },
  {
    path: '/device/spare/archives/change/:id',
    name: 'device-spare-archives-change',
    component: () => import('../pages/spare/archives/change')
  },
  {
    path: '/device/spare/archives/detail/:id',
    name: 'device-spare-archives-detail',
    component: () => import('../pages/spare/archives/detail')
  },
  {
    path: '/device/spare/spare-stock/list',
    name: 'device-spare-spare-stock-list',
    component: () => import('../pages/spare/spare-stock/list')
  },
  {
    path: '/device/spare/into/list',
    name: 'device-spare-into-list',
    component: () => import('../pages/spare/into/list')
  },
  {
    path: '/device/spare/into/add',
    name: 'device-spare-into-add',
    component: () => import('../pages/spare/into/add')
  },
  {
    path: '/device/spare/into/detail/:id',
    name: 'device-spare-into-detail',
    component: () => import('../pages/spare/into/detail')
  },
  {
    path: '/device/spare/out/list',
    name: 'device-spare-out-list',
    component: () => import('../pages/spare/out/list')
  },
  {
    path: '/device/spare/out/add',
    name: 'device-spare-out-add',
    component: () => import('../pages/spare/out/add')
  },
  {
    path: '/device/spare/out/detail/:id',
    name: 'device-spare-out-detail',
    component: () => import('../pages/spare/out/detail')
  },
  {
    path: '/device/spot/items/list',
    name: 'device-spot-items-list',
    component: () => import('../pages/spot/items/list')
  },
  {
    path: '/device/spot/items/add',
    name: 'device-spot-items-add',
    component: () => import('../pages/spot/items/add')
  },
  {
    path: '/device/spot/items/change/:id',
    name: 'device-spot-items-change',
    component: () => import('../pages/spot/items/change')
  },
  {
    path: '/device/spot/items/detail/:id',
    name: 'device-spot-items-detail',
    component: () => import('../pages/spot/items/detail')
  },
  {
    path: '/device/spot/rule/list',
    name: 'device-spot-rule-list',
    component: () => import('../pages/spot/rule/list')
  },
  {
    path: '/device/spot/rule/add',
    name: 'device-spot-rule-add',
    component: () => import('../pages/spot/rule/add')
  },
  {
    path: '/device/spot/rule/change/:id',
    name: 'device-spot-rule-change',
    component: () => import('../pages/spot/rule/change')
  },
  {
    path: '/device/spot/rule/detail/:id',
    name: 'device-spot-rule-detail',
    component: () => import('../pages/spot/rule/detail')
  },
  {
    path: '/device/spot/plan/list',
    name: 'device-spot-plan-list',
    component: () => import('../pages/spot/plan/list')
  },
  {
    path: '/device/spot/plan/add',
    name: 'device-spot-plan-add',
    component: () => import('../pages/spot/plan/add')
  },
  {
    path: '/device/spot/plan/change/:id',
    name: 'device-spot-plan-change',
    component: () => import('../pages/spot/plan/change')
  },
  {
    path: '/device/spot/plan/detail/:id',
    name: 'device-spot-plan-detail',
    component: () => import('../pages/spot/plan/detail')
  },
  {
    path: '/device/spot/bill/list',
    name: 'device-spot-bill-list',
    component: () => import('../pages/spot/bill/list')
  },
  {
    path: '/device/spot/bill/add',
    name: 'device-spot-bill-add',
    component: () => import('../pages/spot/bill/add')
  },
  {
    path: '/device/spot/bill/change/:id',
    name: 'device-spot-bill-change',
    component: () => import('../pages/spot/bill/change')
  },
  {
    path: '/device/spot/bill/detail/:id',
    name: 'device-spot-bill-detail',
    component: () => import('../pages/spot/bill/detail')
  },
  {
    path: '/device/spot/record/list',
    name: 'device-spot-record-list',
    component: () => import('../pages/spot/record/list')
  },
  {
    path: '/device/spot/record/detail/:id',
    name: 'device-spot-record-detail',
    component: () => import('../pages/spot/record/detail')
  },
  {
    path: '/device/loop/items/list',
    name: 'device-loop-items-list',
    component: () => import('../pages/loop/items/list')
  },
  {
    path: '/device/loop/items/add',
    name: 'device-loop-items-add',
    component: () => import('../pages/loop/items/add')
  },
  {
    path: '/device/loop/items/change/:id',
    name: 'device-loop-items-change',
    component: () => import('../pages/loop/items/change')
  },
  {
    path: '/device/loop/items/detail/:id',
    name: 'device-loop-items-detail',
    component: () => import('../pages/loop/items/detail')
  },
  {
    path: '/device/loop/rule/list',
    name: 'device-loop-rule-list',
    component: () => import('../pages/loop/rule/list')
  },
  {
    path: '/device/loop/rule/add',
    name: 'device-loop-rule-add',
    component: () => import('../pages/loop/rule/add')
  },
  {
    path: '/device/loop/rule/change/:id',
    name: 'device-loop-rule-change',
    component: () => import('../pages/loop/rule/change')
  },
  {
    path: '/device/loop/rule/detail/:id',
    name: 'device-loop-rule-detail',
    component: () => import('../pages/loop/rule/detail')
  },
  {
    path: '/device/loop/plan/list',
    name: 'device-loop-plan-list',
    component: () => import('../pages/loop/plan/list')
  },
  {
    path: '/device/loop/plan/add',
    name: 'device-loop-plan-add',
    component: () => import('../pages/loop/plan/add')
  },
  {
    path: '/device/loop/plan/change/:id',
    name: 'device-loop-plan-change',
    component: () => import('../pages/loop/plan/change')
  },
  {
    path: '/device/loop/plan/detail/:id',
    name: 'device-loop-plan-detail',
    component: () => import('../pages/loop/plan/detail')
  },
  {
    path: '/device/loop/bill/list',
    name: 'device-loop-bill-list',
    component: () => import('../pages/loop/bill/list')
  },
  {
    path: '/device/loop/bill/add',
    name: 'device-loop-bill-add',
    component: () => import('../pages/loop/bill/add')
  },
  {
    path: '/device/loop/bill/change/:id',
    name: 'device-loop-bill-change',
    component: () => import('../pages/loop/bill/change')
  },
  {
    path: '/device/loop/bill/detail/:id',
    name: 'device-loop-bill-detail',
    component: () => import('../pages/loop/bill/detail')
  },
  {
    path: '/device/loop/way/list',
    name: 'device-loop-way-list',
    component: () => import('../pages/loop/way/list')
  },
  {
    path: '/device/loop/way/add',
    name: 'device-loop-way-add',
    component: () => import('../pages/loop/way/add')
  },
  {
    path: '/device/loop/way/change/:id',
    name: 'device-loop-way-change',
    component: () => import('../pages/loop/way/change')
  },
  {
    path: '/device/loop/way/detail/:id',
    name: 'device-loop-way-detail',
    component: () => import('../pages/loop/way/detail')
  },
  {
    path: '/device/loop/record/list',
    name: 'device-loop-record-list',
    component: () => import('../pages/loop/record/list')
  },
  {
    path: '/device/loop/record/detail/:id',
    name: 'device-loop-record-detail',
    component: () => import('../pages/loop/record/detail')
  },
  {
    path: '/device/repair/repair-scheme/detail/:id',
    name: 'device-repair-repair-scheme-detail',
    component: () => import('../pages/repair/repair-scheme/detail')
  },
  {
    path: '/device/mould/archives/list',
    name: 'device-mould-archives-list',
    component: () => import('../pages/mould/archives/list')
  },
  {
    path: '/device/mould/archives/add',
    name: 'device-mould-archives-add',
    component: () => import('../pages/mould/archives/add')
  },
  {
    path: '/device/mould/archives/change/:id',
    name: 'device-mould-archives-change',
    component: () => import('../pages/mould/archives/change')
  },
  {
    path: '/device/mould/archives/detail/:id',
    name: 'device-mould-archives-detail',
    component: () => import('../pages/mould/archives/detail')
  },
  {
    path: '/device/mould/into/list',
    name: 'device-mould-into-list',
    component: () => import('../pages/mould/into/list')
  },
  {
    path: '/device/mould/into/add',
    name: 'device-mould-into-add',
    component: () => import('../pages/mould/into/add')
  },
  {
    path: '/device/mould/into/detail/:id',
    name: 'device-mould-into-detail',
    component: () => import('../pages/mould/into/detail')
  },
  {
    path: '/device/mould/out/list',
    name: 'device-mould-out-list',
    component: () => import('../pages/mould/out/list')
  },
  {
    path: '/device/mould/out/add',
    name: 'device-mould-out-add',
    component: () => import('../pages/mould/out/add')
  },
  {
    path: '/device/mould/out/detail/:id',
    name: 'device-mould-out-detail',
    component: () => import('../pages/mould/out/detail')
  },
  {
    name: 'device-history-list',
    path: '/device/history/list',
    component: () => import('../pages/history/list')
  },
  {
    name: 'device-history-detail',
    path: '/device/history/detail/:id',
    component: () => import('../pages/history/detail')
  },
  {
    name: 'device-report-fault-list',
    path: '/device/report/fault/list',
    component: () => import('../pages/report/fault/list')
  }
]
