const MaterialRoutes = [
  {
    path: '/material/inventory/production-transfer/list',
    name: 'material-inventory-production-transfer-list',
    component: () => import('../pages/inventory/production-transfer/list')
  },
  {
    path: '/material/inventory/production-transfer/detail/:id',
    name: 'material-inventory-production-transfer-detail',
    component: () => import('../pages/inventory/production-transfer/detail')
  },
  {
    path: '/material/inventory/production-transfer/add',
    name: 'material-inventory-production-transfer-add',
    component: () => import('../pages/inventory/production-transfer/add')
  },
  {
    path: '/material/inventory/production-transfer/change/:id',
    name: 'material-inventory-production-transfer-change',
    component: () => import('../pages/inventory/production-transfer/change')
  }
]

const MaterialOverwriteRoutes = [
  {
    name: 'material-inventory-material-preparation-add',
    component: () => import('../pages/inventory/material-preparation/add')
  },
  {
    name: 'material-inventory-material-preparation-change',
    component: () => import('../pages/inventory/material-preparation/change')
  }
]

export {
  MaterialRoutes,
  MaterialOverwriteRoutes
}
