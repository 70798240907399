<template>
  <action-item id="kt_header_timearea" no-action-expand width="min-80" hover-width="min-80" action-thumb-class="d-flex" variant="color-state">
    <template #action-thumb>
<!--      <a href="#" class="btn btn-icon btn-text-light btn-icon-light position-relative">
        <icon-svg type="duotune" name="general.gen060" size="2x" />
        <span class="position-absolute fs-7" style="margin-top: .4rem;">{{ currentDay }}</span>
      </a>-->
      <div class="d-flex flex-column me-3 me-0 justify-content-center">
        <span class="text-gray-500 text-end">{{ currentTime }}</span>
        <span class="text-gray-500 text-end">{{ currentDate }}</span>
      </div>
    </template>
  </action-item>
</template>

<script>
import { Component, Vue } from '@min/vue-compatible-decorator'
import ActionItem from '@/components/layout/header-components/action-item'
import dayjs from 'dayjs'

@Component({
  components: { ActionItem }
})
class Timearea extends Vue {
  datetime = dayjs().format()

  get currentDate () {
    return dayjs(this.datetime).format('YYYY-MM-DD')
  }

  get currentTime () {
    return dayjs(this.datetime).format('HH:mm')
  }

  get currentDay () {
    return dayjs(this.datetime).format('D')
  }

  mounted () {
    setInterval(() => {
      this.datetime = dayjs().format()
    }, 1000)
  }
}

export default Timearea
</script>
