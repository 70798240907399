<template>
  <span v-bind="$attrs" :class="computedClass">
    <slot />
  </span>
</template>

<script>
import { Component, Prop, Vue } from '@min/vue-compatible-decorator'

@Component()
class BLabel extends Vue {
  @Prop({ type: String, default: '' }) type // ['', 'rounded', 'square', 'pill']
  @Prop({ type: String, default: '' }) variant
  @Prop({ type: String, default: '' }) size
  @Prop({ type: Boolean, default: false }) light
  @Prop({ type: Boolean, default: false }) outline
  @Prop({ type: Boolean, default: false }) inline

  get computedClass () {
    const classObject = ['label']
    let added = ''
    switch (this.type) {
      case 'rounded':
      case 'square':
        classObject.push(`label-${this.type}`)
        break
      default:
        break
    }

    if (this.light) {
      added = 'light-'
    }

    if (this.outline) {
      added = 'outline-'
    }

    if (this.variant !== 'default') {
      classObject.push(`label-${added}${this.variant}`)
    }

    if (this.inline) {
      classObject.push('label-inline')
    }

    classObject.push(this.size || '')
    if (this.$attrs.class) {
      classObject.push(this.$attrs.class.toString())
    }

    return classObject
  }
}

export default BLabel
</script>
