<template>
  <div>
    <div v-if="config.mode === 'device'" class="d-flex justify-content-center">
      <div class="d-flex">
        <icon-svg class="me-2" size="lg" :name="translateIconName('1')" :variant="translateIconVariant('1')"/>
        <a href="#" class="text-dark-75 text-hover-primary font-weight-bolder font-size-h5">正常运行</a>
        <icon-svg class="ms-5 me-2" size="lg" :name="translateIconName('2')" :variant="translateIconVariant('2')"/>
        <a href="#" class="text-dark-75 text-hover-primary font-weight-bolder font-size-h5">换装调试</a>
        <icon-svg class="ms-5 me-2" size="lg" :name="translateIconName('3')" :variant="translateIconVariant('3')"/>
        <a href="#" class="text-dark-75 text-hover-primary font-weight-bolder font-size-h5">故障</a>
        <icon-svg class="ms-5 me-2" size="lg" :name="translateIconName('4')" :variant="translateIconVariant('4')"/>
        <a href="#" class="text-dark-75 text-hover-primary font-weight-bolder font-size-h5">停机</a>
      </div>
    </div>
    <div class="row">
      <div :class="[`col-${ config.options.cols }`]" v-for="(s, i) in config.data" :key="i">
        <div class="card card-custom">
          <!--begin::Body-->
          <div v-if="config.mode === 'device'" class="card-body">
            <div class="d-flex align-items-center justify-content-center flex-grow-1">
              <icon-svg class="me-2" size="lg" :name="translateIconName(s.status)" :variant="translateIconVariant(s.status)"/>
              <a href="#" class="text-dark-75 text-hover-primary font-weight-bolder font-size-h5">{{ s.name }}</a>
            </div>
          </div>
          <div v-else class="card-body">
            <div class="d-flex align-items-center justify-content-center flex-grow-1">
              <icon-svg class="me-2" size="lg" :name="translateIconNameFactory(s.status)" :variant="translateIconVariantFactory(s.status)"/>
              <a href="#" class="text-dark-75 text-hover-primary font-weight-bolder font-size-h5">{{ s.factory }}</a>
            </div>
            <div class="d-flex justify-content-center text-muted mt-1">
              <div class="me-1 text-light-success">运行：{{ s.running }}</div><div class="ms-1">维修：{{ s.repair }}</div>
            </div>
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from '@min/vue-compatible-decorator'

export default
@Component()
class ZDeviceCard extends Vue {
  @Prop() data
  @Prop() config

  translateIconName (status) {
    switch (status) {
      case '1':
        return 'General.Settings-2'
      case '2':
        return 'Tools.Screwdriver'
      case '3':
        return 'Code.Warning-2'
      case '4':
        return 'Code.Thunder-circle'
      default:
        return ''
    }
  }

  translateIconNameFactory (status) {
    switch (status) {
      case '1':
        return 'General.Settings-2'
      case '2':
        return 'Code.Warning-2'
      default:
        return ''
    }
  }

  translateIconVariant (status) {
    switch (status) {
      case '1':
        return 'success'
      case '2':
        return 'warning'
      case '3':
        return 'danger'
      case '4':
        return 'secondary'
      default:
        return ''
    }
  }

  translateIconVariantFactory (status) {
    switch (status) {
      case '1':
        return 'success'
      case '2':
        return 'danger'
      default:
        return ''
    }
  }
}
</script>
