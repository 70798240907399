<template>
  <div class="app-sidebar-primary background-placeholder">
    <div class="app-sidebar-menu flex-grow-1 d-flex justify-content-between m-0" id="kt_aside_menu_wrapper">
      <div
        class="nav-menu-wrapper hover-scroll-overlay-y w-100 position-relative"
        data-kt-scroll="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-wrappers="#kt_aside_menu_wrapper"
        data-kt-scroll-offset="0px"
        data-kt-scroll-dependencies="#kt_app_header_wrapper"
      >
        <ul id="kt_aside_menu" class="nav nav-menu menu menu-column menu-title-gray-300 menu-arrow-gray-300 fw-semibold fs-6 px-0 pt-2">
          <li v-if="adminType !== '1'" class="nav-item menu-item m-0 p-0">
            <a href="#" @click.prevent="toWorkbench" :class="['menu-link rounded-0 px-0 d-flex justify-content-between', isOverviewActive() ? 'active text-gray-100' : 'text-gray-300', isMenuHover(workBenchItem) ? 'hover' : '']">
              <span class="menu-icon m-0">
                <icon type="mi-menu" size="5" name="workbench" />
              </span>
              <span class="menu-text flex-grow-1 ms-6">工作台</span>
            </a>
          </li>
          <li class="nav-item menu-item m-0 p-0">
            <div class="separator my-2 w-100 border-gray-800" />
          </li>
          <li v-for="(item, index) in categories" :key="`category-${index}`" class="nav-item menu-item m-0 p-0">
            <a :class="['menu-link', 'rounded-0', 'justify-content-between', isMenuActive(item, 0) ? 'active text-gray-100' : 'text-gray-300']" data-bs-toggle="tab" :data-bs-target="`#aside-secondary-${item.id}`" :href="`#aside-secondary-${item.id}`" @click.prevent='switchMenu(item.id)'>
               <span class="menu-icon m-0">
                <inline-svg class="menu-icon-white" v-if="item.meta?.icon?.type === 'svg'" :src="`${item.meta?.icon?.content}`" />
                <icon v-else-if="item.meta?.icon?.type" size="5" :class="[isMenuActive(item, 0) ? 'text-gray-100' : 'text-gray-300']" :type="item.meta?.icon?.type" :name="item.meta?.icon?.content" :paths="item.meta?.icon?.stacks && item.meta?.icon?.stacks[2] ? item.meta?.icon?.stacks[2] : '2'"></icon>
              </span>
              <span class="menu-text flex-grow-1 ms-6">{{ item.meta.title }}</span>
              <i class="menu-arrow me-2"></i>
            </a>
          </li>
        </ul>
      </div>
      <aside-secondary :bread-crumb="breadCrumb" :show="showSidebarSecondary" />
    </div>
    <div class="app-sidebar-footer flex-column-auto pt-2 pb-4 px-4 z-index-3" id="kt_app_sidebar_footer">
      <a href="#" @click.prevent="togglePrimaryCollapse" class="menu-link rounded-0 justify-content-start text-gray-300">
        <span class="menu-icon me-4">
          <i :class="['ki-solid', collapse ? 'ki-black-right-line' : 'ki-black-left-line', 'fs-1']" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import Event from '@/utils/event'
import { Component, Prop, Vue, Watch, nextTick } from '@min/vue-compatible-decorator'
// #ifdef HAS_SUITE_DASHBOARD
import DashboardAsideMenu from '@/modular/dashboard/layout/aside-menu'
// #endif
import { useMenuStore } from '@/stores/menu'
// import moment from 'moment/moment'
import AsideSecondary from '@/components/layout/aside-secondary.vue'

export default
@Component({
  components: {
    AsideSecondary,
    // #ifdef HAS_SUITE_DASHBOARD
    DashboardAsideMenu
    // #endif
  }
})
class AsidePrimary extends Vue {
  @Prop({ type: Boolean, default: false }) isSuperAdmin
  @Prop({ type: Array, default: () => [], required: true }) breadCrumb
  @Prop({ type: Boolean, default: false }) collapse
  @Prop({ type: Boolean, default: false }) showSidebarSecondary

  adminType = localStorage.getItem('admin_type')

  hoverMenu = {}
  flatStatus = {
    icon: false,
    menu: false
  }

  currentTabId = ''

  workBenchItem = {
    id: 100000010,
    path: '/overview',
    name: 'overview',
    meta: {
      title: '工作台'
    }
  }

  @Watch('showSidebarSecondary')
  watchShowSidebarSecondary (value) {
    if (!value) {
      this.currentTabId = ''
    }
  }

  @Watch('categories')
  async watchCategories (value) {
    const ids = []
    for (const i in value) {
      ids.push(value[i].id)
    }
    this.menuIds = ids
    await nextTick()
    // KTMenu.createInstances()
    // eslint-disable-next-line no-undef
    KTScroll.createInstances()
  }

  @Watch('breadCrumb')
  watchBreadCrumb (value) {
    if (!value.length) {
      this.currentTabId = 0
      Event.emit('sidebar.secondary.hide')
    }
  }

  get categories () {
    return useMenuStore().categories
  }

  togglePrimaryCollapse () {
    Event.emit('sidebar.primary.collapse-toggle')
  }

  switchMenu (menuId) {
    this.currentTabId = menuId
    Event.emit('sidebar.secondary.show')
    Event.emit('aside-primary.click', menuId)
  }

  hideAppSidebarSecondary (isActive = false) {
    if (!isActive) {
      Event.emit('sidebar.secondary.hide')
    } else {
      Event.emit('sidebar.secondary.show')
    }
  }

  toWorkbench () {
    this.currentTabId = 0
    Event.emit('sidebar.secondary.hide')
    this.to(this.workBenchItem)
  }

  isMenuClick (menu) {
    return menu.id === this.currentTabId
  }

  isMenuActive (menu, level) {
    return this.breadCrumb[level] && (menu.id === this.breadCrumb[level].id)
  }

  isOverviewActive () {
    return this.$route.name === 'overview'
  }

  isMenuHover (menu) {
    return this.hoverMenu && (menu.id === this.hoverMenu.id)
  }

  mouseenterMenu (menu, isIcon = false) {
    this.hoverMenu = menu
  }

  mouseleaveMenu () {
    this.hoverMenu = {}
  }

  mouseenterMenuIcon (area) {
    if (this.collapse) {
      if (area === 'icon') {
        this.flatStatus.icon = true
        this.flatStatus.menu = false
        Event.emit('sidebar.primary.flat.show')
      } else {
        if (this.flatStatus.icon) {
          this.flatStatus.menu = true
          Event.emit('sidebar.primary.flat.show')
        } else {
          Event.emit('sidebar.primary.flat.hide')
        }
      }
    }
  }

  mouseleaveMenuIcon (area) {
    if (this.collapse) {
      if (area === 'icon') {
        setTimeout(() => {
          if (!this.flatStatus.menu) {
            Event.emit('sidebar.primary.flat.hide')
            this.flatStatus.icon = false
          }
        }, 300)
      } else {
        Event.emit('sidebar.primary.flat.hide')
      }
    }
  }

  to (menu) {
    /* Event.emit('tab.insert', 'default', {
      name: menu.name.replace(/_/g, '-'),
      title: menu.meta.title,
      icon: menu.meta.icon,
      route: menu.path,
      params: {
        timestamp: moment().unix()
      },
      hidden: false,
      active: true
    }) */
    /* setTimeout(() => {
      // eslint-disable-next-line no-undef
      KTLayoutAsideToggle.getToggle().toggle()
    }, 300) */
    this.$router.push(menu.path)
  }
}
</script>
