<template>
  <p v-if="props.static" class="form-control-plaintext text-gray-800">{{ modelValue }}</p>
  <b-form-input :class="[props.class, invalid ? 'is-invalid' : '']" v-else class="form-control" :disabled="props.static" v-model="modelValue" />
</template>

<script setup>
import { defineProps, defineModel, defineExpose, ref, watch } from 'vue'
import { isEmpty } from '../../utils/common'

const props = defineProps({
  static: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  label: { type: String, default: '' },
  for: { type: String, default: '' },
  validation: { type: Object },
  data: [String, Number],
  value: [String, Number]
})
const modelValue = defineModel()
const invalid = ref(false)

watch(() => props.value, value => {
  modelValue.value = value
})

watch(() => props.data, value => {
  modelValue.value = value
})
/* watch(modelValue, (newValue, oldValue) => {
  if (invalid.value === true && newValue !== oldValue) {
    invalid.value = false
  }
}) */

/**
 *
 * @returns { Object } data
 * @returns { number } data.status 0: 正常, 1: error, 2: warning
 * @returns { string } data.message
 * @returns { any } data.data
 */
function validate () {
  if (props.required && isEmpty(modelValue.value)) {
    invalid.value = true
    return { status: 1, message: `${props.label ?? props.for ?? '此项'}必填` }
  }
  if (props.validation) {
    if (props.validation.func && !props.validation.func(modelValue.value)) {
      invalid.value = true
      return { status: 1, message: props.validation.label || `${props.label ?? props.for ?? '此项'}不符要求` }
    }
    if (props.validation.regExp && !props.validation.regExp.test(modelValue.value)) {
      invalid.value = true
      return { status: 1, message: props.validation.label || `${props.label ?? props.for ?? '此项'}不符要求` }
    }
  }
  invalid.value = false
  return { status: 0, message: '' }
}

// onMounted(() => {
//   modelValue.value = props.data ?? props.value
// })

defineExpose({ validate })
</script>
