import { getVm } from '@/utils/vm'
import _typeof from 'typeof'

const defaults = {
  buttonsStyling: false,
  allowOutsideClick: false,
  allowEscapeKey: false,
  customClass: {
    confirmButton: 'btn btn-lg btn-primary w-80px m-0 ms-4',
    cancelButton: 'btn btn-lg btn-dark w-80px m-0 ms-4',
    actions: 'd-flex justify-content-end w-100 mb-0 mt-10',
    popup: 'p-6 rounded-0',
    title: 'fs-4 text-start text-gray-800 fw-normal p-0 ps-2',
    htmlContainer: 'mx-0 fs-6 text-gray-700 text-start'
  }
}

const toast = {
  _options (options, type, ttl = 0, content, title) {
    /* return Object.assign({
      closeButton: true,
      debug: false,
      newestOnTop: true,
      progressBar: true,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      onclick: null,
      showDuration: 300,
      hideDuration: 100,
      timeOut: ttl,
      extendedTimeOut: 0,
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
      tapToDismiss: false
    }, options) */
    return Object.assign({
      animation: false,
      customClass: {
        container: 'alert-toast'
        // popup: 'animate__animated animate__fadeIn'
      },
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: ttl || 5000,
      icon: type,
      title: title || options.title || '',
      text: content || options.content || ''
    }, options)
  },
  show (title, content, type = 'info', ttl = 2000, options = {}) {
    if (_typeof(title) === 'object') {
      type = title.type || 'info'
      options = title
      title = options.title || ''
      content = options.content || ''
    }
    options = this._options(options, ttl)

    switch (type) {
      case 'info':
        this.info(content, title, options)
        break
      case 'warning':
        this.warning(content, title, options)
        break
      case 'error':
        this.error(content, title, options)
        break
      case 'success':
        this.success(content, title, options)
        break
    }
  },
  info (title, content, ttl = 2000, options = {}) {
    options = this._options(options, 'info', ttl, content, title)
    getVm().$swal(options)
  },
  success (title, content, ttl = 2000, options = {}) {
    options = this._options(options, 'success', ttl, content, title)
    getVm().$swal(options)
  },
  error (title, content, ttl = 0, options = {}) {
    options = this._options(options, 'error', ttl, content, title)
    getVm().$swal(options)
  },
  warning (title, content, ttl = 0, options = {}) {
    options = this._options(options, 'warning', ttl, content, title)
    getVm().$swal(options)
  }
}

export default {
  show (title, content, options = {}) {
    if (_typeof(title) !== 'object') {
      options = Object.assign({
        title: title,
        text: content
      }, options)
    }
    return getVm().$swal(Object.assign({}, options, defaults))
  },
  warning (title, content, ttl = undefined, options = {}) {
    return getVm().$swal(Object.assign({
      icon: 'warning',
      title: title,
      text: content,
      timer: ttl,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: '关闭'
    }, options, defaults))
  },
  error (title, content, ttl = undefined, options = {}) {
    return getVm().$swal(Object.assign({
      icon: 'error',
      title: title,
      text: content,
      timer: ttl,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: '关闭'
    }, options, defaults))
  },
  confirm (title, content, ttl = undefined, options = {}) {
    return getVm().$swal(Object.assign({
      icon: 'question',
      title: title,
      text: content,
      timer: ttl,
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: '取消',
      confirmButtonText: '确定'
    }, defaults, options))
  },
  success (title, content, ttl = undefined, options = {}) {
    return getVm().$swal(Object.assign({
      icon: 'success',
      title: title,
      text: content,
      timer: ttl,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: '关闭'
    }, options, defaults))
  },
  info (title, content, ttl = undefined, options = {}) {
    return getVm().$swal(Object.assign({
      icon: 'info',
      title: title,
      text: content,
      timer: ttl,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: '关闭'
    }, options, defaults))
  },
  toast
}
