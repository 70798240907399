<!--
 * @Author: xuzhitao
 * @Date: 2022-12-26 15:16:25
 * @LastEditors: xuzhitao
 * @LastEditTime: 2022-12-29 14:20:35
 * @FilePath: /mirrors.in-mes.com-ui/src/app.vue
-->
<template>
  <router-view />
</template>

<script>
import { Component, Vue, Watch } from '@min/vue-compatible-decorator'
import { useBreadCrumbStore } from '@/stores/bread-crumb'
import Package from '@root/package.json'
@Component()
class App extends Vue {
  mounted () {
    // eslint-disable-next-line no-undef
    // const theme = localStorage.getItem('data-bs-theme') || KTThemeMode.getSystemMode()
    const theme = localStorage.getItem('data-bs-theme') || 'light'
    // eslint-disable-next-line no-undef
    KTThemeMode.setMode(theme)
  }

  @Watch('$route')
  watchRoute () {
    this.changePageTitle()
  }

  changePageTitle () {
    const prefixTitle = `${Package.about.name || '数联智造'} - ${Package.about.type}`
    useBreadCrumbStore().initialized.run(() => {
      const breadCrumb = useBreadCrumbStore().breadCrumb
      if (breadCrumb.length > 0) {
        window.document.title = `${prefixTitle} - ${breadCrumb[breadCrumb.length - 1].meta.title}`
      } else {
        window.document.title = prefixTitle
      }
    }, 'APP_CHANGE_PAGE_TITLE')
  }
}

export default App
</script>
