export default [
  {
    path: '/sale/sale-order/add',
    name: 'sale-sale-order-add',
    component: () => import('../pages/sale-order/add')
  },
  {
    path: '/sale/sale-order/list',
    name: 'sale-sale-order-list',
    component: () => import('../pages/sale-order/list')
  },
  {
    path: '/sale/sale-order/child/:id',
    name: 'sale-sale-order-child',
    component: () => import('../pages/sale-order/child')
  },
  {
    path: '/sale/sale-order/change/:id',
    name: 'sale-sale-order-change',
    component: () => import('../pages/sale-order/change')
  },
  {
    path: '/sale/sale-order/detail/:id',
    name: 'sale-sale-order-detail',
    component: () => import('../pages/sale-order/detail')
  },
  {
    path: '/sale/delivery-invoice/add',
    name: 'sale-delivery-invoice-add',
    component: () => import('../pages/delivery-invoice/add')
  },
  {
    path: '/sale/delivery-invoice/list',
    name: 'sale-delivery-invoice-list',
    component: () => import('../pages/delivery-invoice/list')
  },
  {
    path: '/sale/delivery-invoice/change/:id',
    name: 'sale-delivery-invoice-change',
    component: () => import('../pages/delivery-invoice/change')
  },
  {
    path: '/sale/delivery-invoice/detail/:id',
    name: 'sale-delivery-invoice-detail',
    component: () => import('../pages/delivery-invoice/detail')
  },
  {
    path: '/sale/return-invoice/add',
    name: 'sale-return-invoice-add',
    component: () => import('../pages/return-invoice/add')
  },
  {
    path: '/sale/return-invoice/list',
    name: 'sale-return-invoice-list',
    component: () => import('../pages/return-invoice/list')
  },
  {
    path: '/sale/return-invoice/change/:id',
    name: 'sale-return-invoice-change',
    component: () => import('../pages/return-invoice/change')
  },
  {
    path: '/sale/return-invoice/detail/:id',
    name: 'sale-return-invoice-detail',
    component: () => import('../pages/return-invoice/detail')
  },
  {
    path: '/sale/invoice-apply/add',
    name: 'sale-invoice-apply-add',
    component: () => import('../pages/invoice-apply/add')
  },
  {
    path: '/sale/invoice-apply/list',
    name: 'sale-invoice-apply-list',
    component: () => import('../pages/invoice-apply/list')
  },
  {
    path: '/sale/invoice-apply/change/:id',
    name: 'sale-invoice-apply-change',
    component: () => import('../pages/invoice-apply/change')
  },
  {
    path: '/sale/invoice-apply/detail/:id',
    name: 'sale-invoice-apply-detail',
    component: () => import('../pages/invoice-apply/detail')
  }
]
