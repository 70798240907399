import DashboardWrapper from '../layout/wrapper'
import AppContainer from '@/components/container/app.vue'
import LayoutFullscreen from '@/components/layout/fullscreen'
import ProductionOverview from '../pages/production-overview.vue'
import StockOverview from '../pages/stock-overview.vue'
import DeviceOverview from '../pages/device-overview.vue'
import OrderOverview from '../pages/glxcl-order-overview.vue'
import XXZGProductionOverview from '../pages/xxzg-production-overview.vue'

const RoutesDashboard = [
  {
    path: ':id',
    // name: '数据看板',
    component: DashboardWrapper
  },
  {
    path: '/dashboard/production-overview',
    component: ProductionOverview
  },
  {
    path: '/dashboard/stock-overview',
    component: StockOverview
  },
  {
    path: '/dashboard/device-overview',
    component: DeviceOverview
  },
  {
    path: '/dasboard/glxcl-order-overview',
    component: OrderOverview
  },
  {
    path: '/dasboard/xxzg-production-overview',
    component: XXZGProductionOverview
  }
]

const DashboardApp = {
  path: '/dashboard',
  name: '工厂看板',
  component: AppContainer,
  children: RoutesDashboard
}

const FullscreenDashboard = {
  path: '/dashboard/fullscreen',
  name: '工厂看板可视化',
  component: LayoutFullscreen,
  children: RoutesDashboard
}

export {
  FullscreenDashboard,
  DashboardApp
}
