export default [
  {
    path: '/system/configuration',
    name: 'system-configuration',
    component: () => import('../pages/configuration')
  },
  {
    path: '/system/tenant/add',
    name: 'system-tenant-add',
    component: () => import('../pages/tenant/add')
  },
  {
    path: '/system/tenant/list',
    name: 'system-tenant-list',
    component: () => import('../pages/tenant/list')
  },
  {
    path: '/system/tenant/change/:id',
    name: 'system-tenant-change',
    component: () => import('../pages/tenant/change')
  },
  {
    path: '/system/tenant/authorize',
    name: 'system-tenant-authorize',
    component: () => import('../pages/tenant/authorize')
  },
  {
    path: '/system/user/add',
    name: 'system-user-add',
    component: () => import('../pages/user/add')
  },
  {
    path: '/system/user/list',
    name: 'system-user-list',
    component: () => import('../pages/user/list')
  },
  {
    path: '/system/user/change/:id',
    name: 'system-user-change',
    component: () => import('../pages/user/change')
  },
  {
    path: '/system/user/detail/:id',
    name: 'system-user-detail',
    component: () => import('../pages/user/detail')
  },
  {
    path: '/system/user-group/add',
    name: 'system-user-group-add',
    component: () => import('../pages/user-group/add')
  },
  {
    path: '/system/user-group/list',
    name: 'system-user-group-list',
    component: () => import('../pages/user-group/list')
  },
  {
    path: '/system/user-group/change/:id',
    name: 'system-user-group-change',
    component: () => import('../pages/user-group/change')
  },
  {
    path: '/system/role/add',
    name: 'system-role-add',
    component: () => import('../pages/role/add')
  },
  {
    path: '/system/role/list',
    name: 'system-role-list',
    component: () => import('../pages/role/list')
  },
  {
    path: '/system/role/change/:id',
    name: 'system-role-change',
    component: () => import('../pages/role/change')
  },
  {
    path: '/system/role/authorize',
    name: 'system-role-authorize',
    component: () => import('../pages/role/authorize')
  },
  // 组织模块，暂不使用
  // {
  //   path: '/system/organization/add',
  //   name: 'system-organization-add',
  //   component: () => import('../pages/organization/add')
  // },
  // {
  //   path: '/system/organization/list',
  //   name: 'system-organization-list',
  //   component: () => import('../pages/organization/list')
  // },
  // {
  //   path: '/system/organization/change/:id',
  //   name: 'system-organization-change',
  //   component: () => import('../pages/organization/change')
  // },
  {
    path: '/system/code-rule/add',
    name: 'system-code-rule-add',
    component: () => import('../pages/code-rule/add')
  },
  {
    path: '/system/code-rule/list',
    name: 'system-code-rule-list',
    component: () => import('../pages/code-rule/list')
  },
  {
    path: '/system/code-rule/change/:id',
    name: 'system-code-rule-change',
    component: () => import('../pages/code-rule/change')
  },
  {
    path: '/system/code-rule/detail/:id',
    name: 'system-code-rule-detail',
    component: () => import('../pages/code-rule/detail')
  },
  {
    path: '/system/code-rule/type/add',
    name: 'system-code-rule-type-add',
    component: () => import('../pages/code-rule/type/add')
  },
  {
    path: '/system/notification-management/list',
    name: 'system-notification-management-list',
    component: () => import('../pages/notification/management/list')
  },
  {
    path: '/system/notification-management/change/:id',
    name: 'system-notification-management-change',
    component: () => import('../pages/notification/management/change')
  },
  {
    path: '/system/notification-config/list',
    name: 'system-notification-config-list',
    component: () => import('../pages/notification/config/list')
  },
  {
    path: '/system/notification-config/change/:id',
    name: 'system-notification-config-change',
    component: () => import('../pages/notification/config/change')
  },
  {
    path: '/system/code-rule/type/list',
    name: 'system-code-rule-type-list',
    component: () => import('../pages/code-rule/type/list')
  },
  {
    path: '/system/code-rule/type/change/:id',
    name: 'system-code-rule-type-change',
    component: () => import('../pages/code-rule/type/change')
  },
  {
    path: '/system/event/abnormal/list',
    name: 'system-event-abnormal-list',
    component: () => import('../pages/event/abnormal/list')
  },
  {
    path: '/system/event/abnormal/detail/:id',
    name: 'system-event-abnormal-detail',
    component: () => import('../pages/event/abnormal/detail')
  },
  {
    path: '/system/menu/list',
    name: 'system-menu-list',
    component: () => import('../pages/menu/list')
  },
  {
    path: '/system/menu/add',
    name: 'system-menu-add',
    component: () => import('../pages/menu/add')
  },
  {
    path: '/system/menu/change/:id',
    name: 'system-menu-change',
    component: () => import('../pages/menu/change')
  },
  {
    path: '/system/menu/detail/:id',
    name: 'system-menu-detail',
    component: () => import('../pages/menu/detail')
  },
  {
    path: '/system/app-update/list',
    name: 'system-app-update-list',
    component: () => import('../pages/app-update/list')
  },
  {
    path: '/system/app-update/add',
    name: 'system-app-update-add',
    component: () => import('../pages/app-update/add')
  },
  {
    path: '/system/app-update/change/:id',
    name: 'system-app-update-change',
    component: () => import('../pages/app-update/change')
  },
  {
    path: '/system/app-update/history-version',
    name: 'system-app-update-history-version',
    component: () => import('../pages/app-update/history-version')
  },
  {
    path: '/system/dictionary',
    name: 'system-dictionary',
    component: () => import('../pages/dictionary/index.vue')
  },
  {
    path: '/system/data-permission/add',
    name: 'system-data-permission-add',
    component: () => import('../pages/data-permission/add')
  },
  {
    path: '/system/data-permission/list',
    name: 'system-data-permission-list',
    component: () => import('../pages/data-permission/list')
  },
  {
    path: '/system/data-permission/change/:id',
    name: 'system-data-permission-change',
    component: () => import('../pages/data-permission/change')
  },
  {
    path: '/system/data-permission/detail/:id',
    name: 'system-data-permission-detail',
    component: () => import('../pages/data-permission/detail')
  },
  {
    path: '/system/examination/list',
    name: 'system-examination-list',
    component: () => import('../pages/examination/list')
  },
  {
    path: '/system/examination/add',
    name: 'system-examination-add',
    component: () => import('../pages/examination/add')
  },
  {
    path: '/system/examination/change/:id',
    name: 'system-examination-change',
    component: () => import('../pages/examination/change')
  },
  {
    path: '/system/examination/detail/:id',
    name: 'system-examination-detail',
    component: () => import('../pages/examination/detail')
  },
  {
    path: '/system/config',
    name: 'system-config',
    component: () => import('../pages/config')
  },
  {
    path: '/system/dispatch-center/application/list',
    name: 'system-dispatch-center-application-list',
    component: () => import('../pages/dispatch-center/application/list')
  },
  {
    path: '/system/dispatch-center/application/add',
    name: 'system-dispatch-center-application-add',
    component: () => import('../pages/dispatch-center/application/add')
  },
  {
    path: '/system/dispatch-center/application/change/:id',
    name: 'system-dispatch-center-application-change',
    component: () => import('../pages/dispatch-center/application/change')
  },
  {
    path: '/system/dispatch-center/tenant/authorize',
    name: 'system-dispatch-center-tenant-authorize',
    component: () => import('../pages/dispatch-center/tenant/authorize')
  },
  {
    path: '/system/dispatch-center/role/authorize',
    name: 'system-dispatch-center-role-authorize',
    component: () => import('../pages/dispatch-center/role/authorize')
  },
  {
    path: '/system/5g-sign/tenant/list',
    name: 'system-5g-sign-tenant-list',
    component: () => import('../pages/5g-sign/tenant/list')
  },
  {
    path: '/system/5g-sign/tenant/add',
    name: 'system-5g-sign-tenant-add',
    component: () => import('../pages/5g-sign/tenant/add')
  },
  {
    path: '/system/5g-sign/tenant/change/:id',
    name: 'system-5g-sign-tenant-change',
    component: () => import('../pages/5g-sign/tenant/change')
  },
  {
    path: '/system/5g-sign/user/list',
    name: 'system-5g-sign-user-list',
    component: () => import('../pages/5g-sign/user/list')
  },
  {
    path: '/system/5g-sign/record/list',
    name: 'system-5g-sign-record-list',
    component: () => import('../pages/5g-sign/record/list')
  }
]
