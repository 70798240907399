<template>
  <template v-for="(item, index) in items" :key="`tag-item-${index}`">
    <zero-tag class="me-1" v-if="ellipsis === 0 || index < ellipsis" :variant="item.variant ?? (typeOf(variant) === 'function' ? variant(item) : variant)" :class="tagClass">
      {{ formatValue(item) }}
    </zero-tag>
  </template>
  <zero-tag id="more-tag" :ref="more" @click.prevent="togglePopover" v-if="ellipsis > 0 && items.length > ellipsis">+{{ items.length - ellipsis }}</zero-tag>
  <b-popover target="more-tag" id="more-popover" placement="top" custom-class="popover-inverse" v-model="showPopover" v-if="ellipsis > 0 && items.length > ellipsis">
<!--    <zero-tag v-for="(item, index) in items" id="more-tags" :key="`tag-item-${index}`" class="me-1 mb-1" :variant="item.variant ?? (typeOf(variant) === 'function' ? variant(item) : variant)" :class="tagClass">
      {{ props.format ? props.format(item) : (item[props.valueKey] ?? '' ) }}
    </zero-tag>-->
    <template v-for="(item, index) in items" :key="`tag-item-${index}`">{{ props.format ? props.format(item) : (item[props.valueKey] ?? '' ) }}&nbsp;|&nbsp;</template>
  </b-popover>
</template>
<script setup>
import { defineProps, ref } from 'vue'
import { typeOf } from '../utils/common'

const props = defineProps({
  variant: { type: [String, Function] },
  items: { type: Array },
  tagClass: {},
  valueKey: { type: String, default: 'value' },
  format: { type: Function },
  ellipsis: { type: [Number, String], default: 0 }
})

const more = ref()
const showPopover = ref(false)

function formatValue (item) {
  if (props.format) {
    return props.format(item)
  } else {
    if (typeOf(item) !== 'object') {
      return item
    } else {
      return item[props.valueKey] ?? ''
    }
  }
}

function togglePopover () {
  showPopover.value = !showPopover.value
}
</script>
