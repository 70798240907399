<template>
  <icon-svg :class="props.class" :style="props.style" v-if="type === 'mi'" :name="name" :variant="variant" :type="type" />
  <i v-else :class="computedClass" :style="props.style">
    <template v-if="type === 'duotone'">
      <span :key="`path-${n}`" v-for="n in parseInt(paths)" :class="`path${n}`" />
    </template>
  </i>
</template>

<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  type: { type: String, default: 'duotone' },
  mode: { type: String, default: 'line' },
  variant: { type: String, default: 'default' },
  size: { type: String, default: '' },
  name: { type: String, default: '', required: true },
  paths: { type: [String, Number], default: '2' },
  class: {},
  style: {}
})

const computedClass = computed(() => {
  const classObject = [props.class]
  switch (props.type) {
    case 'duotone':
    case 'outline':
    case 'solid':
      classObject.push(`ki-${props.type}`)
      classObject.push(`ki-${props.name}`)
      break
    case 'ri':
      classObject.push('ri')
      if (props.mode !== '') {
        classObject.push(`ri-${props.name}-${props.mode}`)
      } else {
        classObject.push(`ri-${props.name}`)
      }
      break
    case 'mi':
      classObject.push('mi')
      classObject.push(`mi-${props.name}`)
      break
    case 'mi-menu':
      classObject.push('mi')
      classObject.push('mi-menu')
      classObject.push(`mi-${props.name}`)
      break
    case 'la':
    case 'las':
      classObject.push('las')
      classObject.push(`la-${props.name}`)
      break
    case 'far':
      classObject.push('far')
      classObject.push(`fa-${props.name}`)
      break
    case 'fab':
      classObject.push('fab')
      classObject.push(`fa-${props.name}`)
      break
    case 'fa':
    case 'fas':
    default:
      classObject.push('fas')
      classObject.push(`fa-${props.name}`)
      break
  }

  if (props.variant !== 'default') {
    classObject.push(`text-${props.variant}`)
  }

  if (props.size !== '') {
    classObject.push(`fs-${props.size}`)
  }

  /* if (this.$attrs.class) {
    classObject.push(this.$attrs.class)
  } */

  return classObject
})
</script>
