import { usePermissionStore } from '@/stores/permissions'
import { useProfileStore } from '@/stores/profile'
// 是否存在权限，auth，传字符串或列表
export function hasAuth (auth) {
  if (useProfileStore().isSuperAdmin) {
    // 如果有超管权限，不进行过滤
    return true
  }
  const permissionList = usePermissionStore().list
  if (auth && auth.length > 0) {
    let flag = false
    // 配置权限时要从权限字符串里找到对应配置的权限才允许显示
    if (auth instanceof Array) {
      auth.forEach(res => {
        const temp = permissionList.includes(res)
        // 有一个权限字符串符合匹配时就可以赋值并允许显示该字段
        if (temp) {
          flag = true
        }
      })
    }
    if (typeof auth === 'string') {
      const temp = permissionList.includes(auth)
      // 当auth是字符串时
      if (temp) {
        flag = true
      }
    }
    return flag
  }
  return true
}
