<template>
  <div :style="computedStyle">
    <slot name="area"></slot>
    <slot />
  </div>
</template>

<script>
import { Component, Prop, Vue } from '@min/vue-compatible-decorator'

export default
@Component()
class ZChart extends Vue {
  @Prop({ type: String }) width
  @Prop({ type: String }) height

  get computedStyle () {
    return {
      width: (this.width || this.containerWidth) + 'px',
      height: (this.height || this.containerHeight) + 'px'
    }
  }

  setSize () {
    if (!this.width) {
      this.containerWidth = this.$el.parentElement.clientWidth
    }
    if (!this.height) {
      this.containerHeight = this.$el.parentElement.clientHeight
    }
  }

  mounted () {
    this.setSize()
  }
}
</script>
