export default [
  {
    path: '/quality/request/technique-inspect/list',
    name: 'quality-request-technique-inspect-list',
    component: () => import('../pages/request/technique-inspect/list')
  },
  {
    path: '/quality/request/technique-inspect/add',
    name: 'quality-request-technique-inspect-add',
    component: () => import('../pages/request/technique-inspect/add')
  },
  {
    path: '/quality/request/technique-inspect/change/:id',
    name: 'quality-request-technique-inspect-change',
    component: () => import('../pages/request/technique-inspect/change')
  },
  {
    path: '/quality/request/technique-inspect/detail/:id',
    name: 'quality-request-technique-inspect-detail',
    component: () => import('../pages/request/technique-inspect/detail')
  },
  {
    path: '/quality/request/process-inspect/list',
    name: 'quality-request-process-inspect-list',
    component: () => import('@/modular/base/quality/pages/request/process-inspect/list')
  },
  {
    path: '/quality/request/process-inspect/add',
    name: 'quality-request-process-inspect-add',
    component: () => import('@/modular/base/quality/pages/request/process-inspect/add')
  },
  {
    path: '/quality/request/process-inspect/change/:id',
    name: 'quality-request-process-inspect-change',
    component: () => import('@/modular/base/quality/pages/request/process-inspect/change')
  },
  {
    path: '/quality/request/process-inspect/detail/:id',
    name: 'quality-request-process-inspect-detail',
    component: () => import('@/modular/base/quality/pages/request/process-inspect/detail')
  },
  {
    path: '/quality/request/process/list',
    name: 'quality-request-process-list',
    component: () => import('../pages/request/process/list')
  },
  {
    path: '/quality/request/process/add',
    name: 'quality-request-process-add',
    component: () => import('../pages/request/process/add')
  },
  {
    path: '/quality/request/process/change/:id',
    name: 'quality-request-process-change',
    component: () => import('../pages/request/process/change')
  },
  {
    path: '/quality/request/process/detail/:id',
    name: 'quality-request-process-detail',
    component: () => import('../pages/request/process/detail')
  },
  {
    path: '/quality/request/product/list',
    name: 'quality-request-product-list',
    component: () => import('../pages/request/product/list')
  },
  {
    path: '/quality/request/product/add',
    name: 'quality-request-product-add',
    component: () => import('../pages/request/product/add')
  },
  {
    path: '/quality/request/product/change/:id',
    name: 'quality-request-product-change',
    component: () => import('../pages/request/product/change')
  },
  {
    path: '/quality/request/product/detail/:id',
    name: 'quality-request-product-detail',
    component: () => import('../pages/request/product/detail')
  },
  {
    path: '/quality/request/in-factory/list',
    name: 'quality-request-in-factory-list',
    component: () => import('../pages/request/in-factory/list')
  },
  {
    path: '/quality/request/in-factory/detail/:id',
    name: 'quality-request-in-factory-detail',
    component: () => import('../pages/request/in-factory/detail')
  },
  {
    path: '/quality/request/in-factory/add',
    name: 'quality-request-in-factory-add',
    component: () => import('../pages/request/in-factory/add')
  },
  {
    path: '/quality/request/in-factory/change/:id',
    name: 'quality-request-in-factory-change',
    component: () => import('../pages/request/in-factory/change')
  },
  {
    path: '/quality/request/out-factory/list',
    name: 'quality-request-out-factory-list',
    component: () => import('../pages/request/out-factory/list')
  },
  {
    path: '/quality/request/out-factory/detail/:id',
    name: 'quality-request-out-factory-detail',
    component: () => import('../pages/request/out-factory/detail')
  },
  {
    path: '/quality/request/out-factory/add',
    name: 'quality-request-out-factory-add',
    component: () => import('../pages/request/out-factory/add')
  },
  {
    path: '/quality/request/out-factory/change/:id',
    name: 'quality-request-out-factory-change',
    component: () => import('../pages/request/out-factory/change')
  },
  {
    path: '/quality/task/process/list',
    name: 'quality-task-process-list',
    component: () => import('../pages/task/process/list')
  },
  {
    path: '/quality/task/process/change/:id',
    name: 'quality-task-process-change',
    component: () => import('../pages/task/process/change')
  },
  {
    path: '/quality/task/process/detail/:id',
    name: 'quality-task-process-detail',
    component: () => import('../pages/task/process/detail')
  },
  {
    path: '/quality/task/process/execute/:id',
    name: 'quality-task-process-execute',
    component: () => import('../pages/task/process/execute')
  },
  {
    path: '/quality/task/product/list',
    name: 'quality-task-product-list',
    component: () => import('../pages/task/product/list')
  },
  {
    path: '/quality/task/product/change/:id',
    name: 'quality-task-product-change',
    component: () => import('../pages/task/product/change')
  },
  {
    path: '/quality/task/product/detail/:id',
    name: 'quality-task-product-detail',
    component: () => import('../pages/task/product/detail')
  },
  {
    path: '/quality/task/product/execute/:id',
    name: 'quality-task-product-execute',
    component: () => import('../pages/task/product/execute')
  },
  {
    path: '/quality/task/process-inspect/list',
    name: 'quality-task-process-inspect-list',
    component: () => import('../pages/task/process-inspect/list')
  },
  {
    path: '/quality/task/process-inspect/change/:id',
    name: 'quality-task-process-inspect-change',
    component: () => import('../pages/task/process-inspect/change')
  },
  {
    path: '/quality/task/process-inspect/detail/:id',
    name: 'quality-task-process-inspect-detail',
    component: () => import('../pages/task/process-inspect/detail')
  },
  {
    path: '/quality/task/process-inspect/execute/:id',
    name: 'quality-task-process-inspect-execute',
    component: () => import('../pages/task/process-inspect/execute')
  },
  {
    path: '/quality/task/technique-inspect/list',
    name: 'quality-task-technique-inspect-list',
    component: () => import('../pages/task/technique-inspect/list')
  },
  {
    path: '/quality/task/technique-inspect/change/:id',
    name: 'quality-task-technique-inspect-change',
    component: () => import('../pages/task/technique-inspect/change')
  },
  {
    path: '/quality/task/technique-inspect/detail/:id',
    name: 'quality-task-technique-inspect-detail',
    component: () => import('../pages/task/technique-inspect/detail')
  },
  {
    path: '/quality/task/technique-inspect/execute/:id',
    name: 'quality-task-technique-inspect-execute',
    component: () => import('../pages/task/technique-inspect/execute')
  },
  {
    path: '/quality/task/in-factory/list',
    name: 'quality-task-in-factory-list',
    component: () => import('../pages/task/in-factory/list')
  },
  {
    path: '/quality/task/in-factory/detail/:id',
    name: 'quality-task-in-factory-detail',
    component: () => import('../pages/task/in-factory/detail')
  },
  {
    path: '/quality/task/in-factory/execute/:id',
    name: 'quality-task-in-factory-execute',
    component: () => import('../pages/task/in-factory/execute')
  },
  {
    path: '/quality/task/out-factory/list',
    name: 'quality-task-out-factory-list',
    component: () => import('../pages/task/out-factory/list')
  },
  {
    path: '/quality/task/out-factory/detail/:id',
    name: 'quality-task-out-factory-detail',
    component: () => import('../pages/task/out-factory/detail')
  },
  {
    path: '/quality/task/out-factory/execute/:id',
    name: 'quality-task-out-factory-execute',
    component: () => import('../pages/task/out-factory/execute')
  },
  {
    path: '/quality/task/general/list',
    name: 'quality-task-general-list',
    component: () => import('../pages/task/general/list')
  },
  {
    path: '/quality/task/general/add',
    name: 'quality-task-general-add',
    component: () => import('../pages/task/general/add')
  },
  {
    path: '/quality/task/general/change/:id',
    name: 'quality-task-general-change',
    component: () => import('../pages/task/general/change')
  },
  {
    path: '/quality/task/general/detail/:id',
    name: 'quality-task-general-detail',
    component: () => import('../pages/task/general/detail')
  },
  {
    path: '/quality/task/general/execute/:id',
    name: 'quality-task-general-execute',
    component: () => import('../pages/task/general/execute')
  },
  {
    path: '/quality/task/general/execute-no-material/:id',
    name: 'quality-task-general-execute-no-material',
    component: () => import('../pages/task/general/execute-no-material')
  },
  {
    path: '/quality/task/patrol/list',
    name: 'quality-task-patrol-list',
    component: () => import('../pages/task/patrol/list')
  },
  {
    path: '/quality/task/patrol/add',
    name: 'quality-task-patrol-add',
    component: () => import('../pages/task/patrol/add')
  },
  {
    path: '/quality/task/patrol/change/:id',
    name: 'quality-task-patrol-change',
    component: () => import('../pages/task/patrol/change')
  },
  {
    path: '/quality/task/patrol/detail/:id',
    name: 'quality-task-patrol-detail',
    component: () => import('../pages/task/patrol/detail')
  },
  {
    path: '/quality/task/patrol/execute/:id',
    name: 'quality-task-patrol-execute',
    component: () => import('../pages/task/patrol/execute')
  },
  {
    path: '/quality/inspection/report/list',
    name: 'quality-inspection-report-list',
    component: () => import('../pages/inspection/report/list')
  },
  {
    path: '/quality/inspection/report/detail/:id',
    name: 'quality-inspection-report-detail',
    component: () => import('../pages/inspection/report/detail')
  },
  {
    path: '/quality/inspection/in-factory/list',
    name: 'quality-inspection-in-factory-list',
    component: () => import('../pages/inspection/in-factory/list')
  },
  {
    path: '/quality/inspection/in-factory/detail/:id',
    name: 'quality-inspection-in-factory-detail',
    component: () => import('../pages/inspection/in-factory/detail')
  },
  {
    path: '/quality/inspection/out-factory/list',
    name: 'quality-inspection-out-factory-list',
    component: () => import('../pages/inspection/out-factory/list')
  },
  {
    path: '/quality/inspection/out-factory/detail/:id',
    name: 'quality-inspection-out-factory-detail',
    component: () => import('../pages/inspection/out-factory/detail')
  },
  {
    path: '/quality/judgment/emergency-release/list',
    name: 'quality-judgment-emergency-release-list',
    component: () => import('../pages/judgment/emergency-release/list')
  },
  {
    path: '/quality/judgment/emergency-release/add',
    name: 'quality-judgment-emergency-release-add',
    component: () => import('../pages/judgment/emergency-release/add')
  },
  {
    path: '/quality/judgment/quality-adjustment/list',
    name: 'quality-judgment-quality-adjustment-list',
    component: () => import('../pages/judgment/quality-adjustment/list')
  },
  {
    path: '/quality/judgment/quality-adjustment/add',
    name: 'quality-judgment-quality-adjustment-add',
    component: () => import('../pages/judgment/quality-adjustment/add')
  },
  {
    path: '/quality/judgment/quality-adjustment/detail/:id',
    name: 'quality-judgment-quality-adjustment-detail',
    component: () => import('../pages/judgment/quality-adjustment/detail')
  },
  {
    path: '/quality/judgment/defective-product-handling/list',
    name: 'quality-judgment-defective-product-handling-list',
    component: () => import('../pages/judgment/defective-product-handling/list')
  },
  {
    path: '/quality/judgment/defective-product-handling/add',
    name: 'quality-judgment-defective-product-handling-add',
    component: () => import('../pages/judgment/defective-product-handling/add')
  },
  {
    path: '/quality/judgment/defective-product-handling/change/:id',
    name: 'quality-judgment-defective-product-handling-change',
    component: () => import('../pages/judgment/defective-product-handling/change')
  },
  {
    path: '/quality/judgment/defective-product-handling/detail/:id',
    name: 'quality-judgment-defective-product-handling-detail',
    component: () => import('../pages/judgment/defective-product-handling/detail')
  },
  {
    path: '/quality/report/project-quality/list',
    name: 'quality-report-project-quality-list',
    component: () => import('../pages/report/project-quality')
  },
  {
    path: '/quality/report/inbound-quality-inspection/list',
    name: 'quality-report-inbound-quality-inspection-list',
    component: () => import('../pages/report/inbound-quality-inspection')
  },
  {
    path: '/quality/report/bad-product/list',
    name: 'quality-report-bad-product-list',
    component: () => import('../pages/report/bad-product')
  },
  {
    path: '/quality/machine/quality-record/list',
    name: 'quality-machine-quality-record-list',
    component: () => import('../pages/machine/quality-record/list')
  },
  {
    path: '/quality/machine/quality-record/detail/:id',
    name: 'quality-machine-quality-record-detail',
    component: () => import('../pages/machine/quality-record/detail')
  },
  {
    path: '/quality/machine/coordinate/list',
    name: 'quality-machine-coordinate-list',
    component: () => import('../pages/machine/coordinate/list')
  },
  {
    path: '/quality/machine/coordinate/add',
    name: 'quality-machine-coordinate-add',
    component: () => import('../pages/machine/coordinate/add')
  }
]
