export default [
  {
    path: '/integration/task/list',
    name: 'integration-task-list',
    component: () => import('../pages/task/list')
  },
  {
    path: '/integration/warn/list',
    name: 'integration-warn-list',
    component: () => import('../pages/warn/list')
  },
  {
    path: '/integration/task-manage/list',
    name: 'integration-task-manage-list',
    component: () => import('../pages/task-manage/list')
  }
]
