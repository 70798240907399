/*
import { createApp } from 'vue'
import App from './App.vue'

createApp(App).mount('#app')
*/

/* 引入 css */
import '@/assets/scss/app.scss'
/* 引入 js */
import { createApp } from 'vue'
import { setupStore } from '@/stores'
import { setupRouter, router } from '@/router'
import App from './app.vue'
import ZeroVue from '@/components/zero-vue'
import { setVm } from '@/utils/vm'
// project bootstrap funtion
// axios配置
import { setAxiosConfig } from './utils/axios-config'
// 路由守卫
import { setupRouterGuard } from '@/router/guard'
// 注册全局组件
import { registerGlobalComponents } from '@/components/register-global-components'
// 注册全局函数
import { registerGlobalMethods } from '@/utils/register-global-methods'

// vue
const app = createApp(App)
// 配置路由
setupRouter(app)
// 注册全局组件
registerGlobalComponents(app)
// 注册全局函数
registerGlobalMethods(app)
// vue实例
const vue = app.mount('#app')
// 配置 store
setupStore(app)
// 配置 vue实例
setVm(vue)
ZeroVue.setVm(vue)
// 配置axios
setAxiosConfig(vue)
// 路由 鉴权
setupRouterGuard(router)
