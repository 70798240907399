<template>
  <div :class="computedClass">
    <div class="card-header border-0">
      <h3 v-if="title" :class="['card-title', summary ? 'align-items-start flex-column' : '']">
        <span class="card-label font-weight-bolder text-dark">{{ title }}</span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">{{ summary }}</span>
      </h3>
      <div v-if="showToolbar" class="card-toolbar">
        <slot name="toolbar">
          <div class="dropdown dropdown-inline">
            <a href="#" class="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="ki ki-bold-more-hor"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
              <!--begin::Navigation-->
              <ul class="navi navi-hover py-5">
                <slot name="tool-items"></slot>
              </ul>
              <!--end::Navigation-->
            </div>
          </div>
        </slot>
      </div>
    </div>
    <div class="card-body p-0 position-relative overflow-hidden">
      <slot />
      <div v-if="showSpacer" class="card-spacer">
        <slot name="spacer" />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from '@min/vue-compatible-decorator'

@Component()
class ZeroCard extends Vue {
  @Prop({ type: String }) variant
  @Prop({ type: String }) hoverVariant
  @Prop({ type: Boolean, default: false }) stretch
  @Prop({ type: Boolean, default: false }) showToolbar
  @Prop({ type: Boolean, default: false }) showSpacer
  @Prop({ type: String }) bgIcon
  @Prop({ type: String, default: 'right top' }) bgIconPosition
  @Prop({ type: String }) title
  @Prop({ type: String, default: 'left' }) titleAlign
  @Prop({ type: String }) titleVariant
  @Prop({ type: String }) titleHoverVariant
  @Prop({ type: String }) summary

  get computedTitleClass () {
    const classObject = ['font-weight-bolder font-size-h5']

    if (this.variant) {
      classObject.push('text-white')
    } else if (this.titleVariant) {
      classObject.push(`text-${this.titleVariant}`)
    } else {
      classObject.push('text-dark-75')
    }

    return classObject
  }

  get computedClass () {
    const classObject = ['card card-custom']

    if (this.stretch) {
      classObject.push('card-stretch')
    }

    if (this.$attrs.class) {
      classObject.push(this.$attrs.class.toString())
    }

    if (this.variant) {
      classObject.push(`bg-${this.variant}`)
    }

    if (this.hoverVariant) {
      classObject.push(`bg-${this.hoverVariant}`)
    }

    if (this.bgIcon && this.bgIcon !== '') {
      classObject.push(`background-position: ${this.bgIconPosition}`)
      classObject.push('background-size: 30% auto')
      classObject.push('background-repeat: no-repeat')
      if (this.bgIcon.indexOf('/') !== -1) {
        classObject.push(`background-image: url(${this.bgIcon})`)
      } else {
        classObject.push(`background-image: url(/assets/img/icon/svg/${this.name.replace(/\./g, '/')}.svg)`)
      }
    }

    return classObject
  }
}
export default ZeroCard
</script>
