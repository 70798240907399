<template>
  <div :class="computedClass">
    <div class="card-header border-0 py-5">
      <slot>
        <h3 class="card-title font-weight-bolder">{{ title || '' }}</h3>
        <div v-if="hasToolbar" class="card-toolbar">
          <div class="dropdown dropdown-inline">
            <a href="#" class="btn btn-text-white btn-hover-white btn-sm btn-icon border-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="ki ki-bold-more-hor"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <!--begin::Navigation-->
              <ul class="navi navi-hover">
                <li class="navi-header pb-1">
                  <span class="text-primary text-uppercase font-weight-bold font-size-sm">Add new:</span>
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-shopping-cart-1"></i>
                        </span>
                    <span class="navi-text">Order</span>
                  </a>
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-calendar-8"></i>
                        </span>
                    <span class="navi-text">Event</span>
                  </a>
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-graph-1"></i>
                        </span>
                    <span class="navi-text">Report</span>
                  </a>
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-rocket-1"></i>
                        </span>
                    <span class="navi-text">Post</span>
                  </a>
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-writing"></i>
                        </span>
                    <span class="navi-text">File</span>
                  </a>
                </li>
              </ul>
              <!--end::Navigation-->
            </div>
          </div>
        </div>
      </slot>
    </div>
    <div :ref="`base-${chartId}`" class="card-body d-flex flex-column p-0" style="position: relative;">
      <div class="echarts" :ref="chartId" :style="computedStyle" />
      <div v-if="hasDesc" class="card-spacer card-rounded flex-grow-1">
        <slot name="desc" />
      </div>
    </div>
  </div>

</template>

<script>
import * as echarts from 'echarts'
import IntellimesLightTheme from './intellimes-light.json'
import debounce from 'lodash/debounce'
import { addListener, removeListener } from 'resize-detector'
import { v4 as uuid } from 'uuid'
import { Component, Prop, Watch, Vue } from '@min/vue-compatible-decorator'
import merge from 'merge'

const INIT_TRIGGERS = ['theme', 'initOptions', 'autoresize']
const REWATCH_TRIGGERS = ['manualUpdate', 'watchShallow']

export default
@Component()
class ZEcharts extends Vue {
  @Prop({ type: String }) title
  @Prop({ type: String }) variant
  @Prop({ type: Boolean, default: false }) hasToolbar
  @Prop({ type: Boolean, default: false }) hasDesc
  @Prop({ type: Object }) options
  @Prop({ type: [String, Object] }) theme
  @Prop({ type: Object }) initOptions
  @Prop({ type: String }) group
  @Prop({ type: Boolean }) autoresize
  @Prop({ type: Boolean }) watchShallow
  @Prop({ type: Boolean }) manualUpdate
  @Prop({ type: String }) chartWidth
  @Prop({ type: String }) chartHeight
  @Prop({ type: [String, Number] }) scale
  @Prop({ type: [Array, Object], default: () => {} }) data
  @Prop({ type: Object, default: () => { return { show: false } } }) legend
  @Prop({ type: Object }) xAxis
  @Prop({ type: Object }) yAxis
  @Prop({ type: Object, default: () => { return { show: false } } }) tooltip
  @Prop({ type: Object, default: () => { return { show: false } } }) toolbox
  @Prop({ type: Boolean, default: false }) darkMode
  @Prop({ type: [String, Object], default: 'transparent' }) background
  @Prop({ type: Array, default: () => [] }) series

  graphic = echarts.graphic

  chartId = uuid()
  lastArea = 0
  containerWidth = 0
  containerHeight = 0

  get computedStyle () {
    return {
      width: this.containerWidth + 'px',
      height: this.containerHeight + 'px'
    }
  }

  get computedClass () {
    const classObject = ['card card-custom card-stretch']

    if (this.variant && this.variant !== '') {
      classObject.push(`bg-${this.variant}`)
    }

    if (this.$attrs.class) {
      classObject.push(this.$attrs.class)
    }

    return classObject
  }

  @Watch('group')
  onGroupChanged (group) {
    this.chart.group = group
  }

  mergeOptions (options, notMerge, lazyUpdate) {
    if (this.manualUpdate) {
      this.manualOptions = options
    }
    if (!this.chart) {
      this.init(options)
    } else {
      this.delegateMethod('setOption', options, notMerge, lazyUpdate)
    }
  }

  // just delegates ECharts methods to Vue Component()
  // use explicit params to reduce transpiled size for now
  appendData (params) {
    this.delegateMethod('appendData', params)
  }

  resize (options) {
    this.delegateMethod('resize', options)
  }

  dispatchAction (payload) {
    this.delegateMethod('dispatchAction', payload)
  }

  convertToPixel (finder, value) {
    return this.delegateMethod('convertToPixel', finder, value)
  }

  convertFromPixel (finder, value) {
    return this.delegateMethod('convertFromPixel', finder, value)
  }

  containPixel (finder, value) {
    return this.delegateMethod('containPixel', finder, value)
  }

  showLoading (type, options) {
    this.delegateMethod('showLoading', type, options)
  }

  hideLoading () {
    this.delegateMethod('hideLoading')
  }

  getDataURL (options) {
    return this.delegateMethod('getDataURL', options)
  }

  getConnectedDataURL (options) {
    return this.delegateMethod('getConnectedDataURL', options)
  }

  clear () {
    this.delegateMethod('clear')
  }

  dispose () {
    this.delegateMethod('dispose')
  }

  delegateMethod (name, ...args) {
    if (!this.chart) {
      this.init()
    }
    return (this.$refs[this.chartId]) ? this.chart[name](...args) : () => {}
  }

  delegateGet (methodName) {
    if (!this.chart) {
      this.init()
    }
    return this.chart[methodName]()
  }

  getArea () {
    return this.$refs[this.chartId] ? this.$refs[this.chartId].offsetWidth * this.$refs[this.chartId].offsetHeight : 0
  }

  parseOptions () {
    const options = {}

    options.dataset = {
      source: this.data
    }
    options.series = this.series
    options.legend = this.legend
    options.tooltip = this.tooltip
    options.toolbox = this.toolbox
    options.darkMode = this.darkMode
    options.backgroundColor = (typeof this.background === 'string')
      ? this.background
      : {
          color: this.background
        }
    return merge.recursive(options, this.options)
  }

  init (options) {
    if (this.chart) {
      return
    }
    if (this.$refs[this.chartId]) {
      echarts.registerTheme('intellimes-light', IntellimesLightTheme)
      const chart = echarts.init(this.$refs[this.chartId], 'intellimes-light', this.initOptions)
      if (this.group) {
        chart.group = this.group
      }
      chart.setOption(options || this.manualOptions || this.parseOptions() || {}, true)
      Object.keys(this.$listeners).forEach(event => {
        const handler = this.$listeners[event]
        if (event.indexOf('zr:') === 0) {
          chart.getZr().on(event.slice(3), handler)
        } else {
          chart.on(event, handler)
        }
      })
      if (this.autoresize) {
        this.lastArea = this.getArea()
        this.__resizeHandler = debounce(
          () => {
            this.setSize()
            if (this.lastArea === 0) {
              // emulate initial render for initially hidden charts
              this.mergeOptions({}, true)
              this.resize()
              this.mergeOptions(this.parseOptions() || this.manualOptions || {}, true)
            } else {
              this.resize()
            }
            this.lastArea = this.getArea()
          },
          100,
          { leading: true }
        )
        addListener(this.$refs[`base-${this.chartId}`], this.__resizeHandler)
      }
      Object.defineProperties(this, {
        // Only recalculated when accessed from JavaScript.
        // Won't update DOM on value change because getters
        // don't depend on reactive values
        width: {
          configurable: true,
          get: () => {
            return this.delegateGet('getWidth')
          }
        },
        height: {
          configurable: true,
          get: () => {
            return this.delegateGet('getHeight')
          }
        },
        isDisposed: {
          configurable: true,
          get: () => {
            return !!this.delegateGet('isDisposed')
          }
        },
        computedOptions: {
          configurable: true,
          get: () => {
            return this.delegateGet('getOption')
          }
        }
      })
      this.chart = chart
    }
  }

  initOptionsWatcher () {
    if (this.__unwatchOptions) {
      this.__unwatchOptions()
      this.__unwatchOptions = null
    }
    if (!this.manualUpdate) {
      this.__unwatchOptions = this.$watch(
        'options',
        (val, oldVal) => {
          if (!this.chart && val) {
            this.init()
          } else {
            // mutating `options` will lead to merging
            // replacing it with new reference will lead to not merging
            // eg.
            // `this.options = Object.assign({}, this.options, { ... })`
            // will trigger `this.chart.setOption(val, true)
            // `this.options.title.text = 'Trends'`
            // will trigger `this.chart.setOption(val, false)`
            this.chart.setOption(val, val !== oldVal)
          }
        },
        { deep: !this.watchShallow }
      )
    }
  }

  destroy () {
    if (this.autoresize) {
      if (this.$refs[`base-${this.chartId}`]) {
        removeListener(this.$refs[`base-${this.chartId}`], this.__resizeHandler)
      }
    }
    this.dispose()
    this.chart = null
  }

  refresh () {
    if (this.chart) {
      this.destroy()
      this.init()
    }
  }

  setSize () {
    if (this.$refs[`base-${this.chartId}`]) {
      let width = this.chartWidth || this.containerWidth
      let height = this.chartHeight || this.containerHeight

      if (width && (typeof width === 'string') && width.indexOf('%') !== -1) {
        width = this.$refs[`base-${this.chartId}`].clientWidth * width.replace('%', '') / 100
      }

      if (height && (typeof width === 'string') && height.indexOf('%') !== -1) {
        height = this.$refs[`base-${this.chartId}`].clientHeight * height.replace('%', '') / 100
      }

      if (this.scale) {
        const scale = this.scale * 1

        if (!this.chartWidth && this.chartHeight) {
          width = Math.round(height * scale)
        } else if (!this.chartHeight && this.chartWidth) {
          height = Math.round(width / scale)
        } else {
          width = this.$refs[`base-${this.chartId}`].clientWidth
          height = Math.round(width / scale)
        }
      } else {
        if (!this.chartWidth) {
          width = this.$refs[`base-${this.chartId}`].clientWidth
        }
        if (!this.chartHeight) {
          height = this.$refs[`base-${this.chartId}`].clientHeight
        }
      }

      this.containerWidth = width
      this.containerHeight = height
      /*
      if (!this.chartWidth) {
        this.containerWidth = this.$el.parentElement.clientWidth
      }
      if (!this.chartHeight) {
        this.containerHeight = this.$el.parentElement.clientHeight
      } */
    }
  }

  created () {
    this.initOptionsWatcher()
    INIT_TRIGGERS.forEach(prop => {
      this.$watch(
        prop,
        () => {
          this.refresh()
        },
        { deep: true }
      )
    })
    REWATCH_TRIGGERS.forEach(prop => {
      this.$watch(prop, () => {
        this.initOptionsWatcher()
        this.refresh()
      })
    })
  }

  mounted () {
    this.setSize()
    // auto init if `options` is already provided
    /* if (this.options) {

    } */
    this.init()
  }

  activated () {
    if (this.autoresize) {
      this.chart && this.chart.resize()
    }
  }

  destroyed () {
    if (this.chart) {
      this.destroy()
    }
  }

  connect (group) {
    if (typeof group !== 'string') {
      group = group.map(chart => chart.chart)
    }
    echarts.connect(group)
  }

  disconnect (group) {
    echarts.disConnect(group)
  }

  getMap (mapName) {
    return echarts.getMap(mapName)
  }

  registerMap (mapName, geoJSON, specialAreas) {
    echarts.registerMap(mapName, geoJSON, specialAreas)
  }

  registerTheme (name, theme) {
    echarts.registerTheme(name, theme)
  }
}
</script>
