const ProductionRoutes = [
  {
    path: '/production/schedule/discharge/list',
    name: 'production-schedule-discharge-list',
    component: () => import('../pages/schedule/discharge/list.vue')
  },
  {
    path: '/production/schedule/discharge/detail/:id',
    name: 'production-schedule-discharge-detail',
    component: () => import('../pages/schedule/discharge/detail.vue')
  }
]

const ProductionOverwriteRoutes = [
  {
    name: 'production-schedule-plan-scheduling-list',
    component: () => import('../pages/schedule/plan-scheduling/list.vue')
  },
  {
    name: 'production-schedule-plan-scheduling-log-list',
    component: () => import('../pages/schedule/plan-scheduling/log/list.vue')
  },
  {
    name: 'production-schedule-plan-scheduling-log-detail',
    component: () => import('../pages/schedule/plan-scheduling/log/detail.vue')
  },
  {
    name: 'production-schedule-plan-ticket-add',
    component: () => import('../pages/schedule/plan-ticket/add.vue')
  },
  {
    name: 'production-schedule-plan-ticket-add-child',
    component: () => import('../pages/schedule/plan-ticket/add-child.vue')
  },
  {
    name: 'production-schedule-plan-ticket-list',
    component: () => import('../pages/schedule/plan-ticket/list.vue')
  },
  {
    path: '/production/schedule/plan-ticket/change/:id',
    name: 'production-schedule-plan-ticket-change',
    component: () => import('../pages/schedule/plan-ticket/change.vue')
  },
  {
    name: 'production-schedule-plan-ticket-detail',
    component: () => import('../pages/schedule/plan-ticket/detail.vue')
  },
  {
    name: 'production-schedule-task-issued-list',
    component: () => import('../pages/schedule/task/issued-list.vue')
  },
  {
    name: 'production-schedule-task-detail',
    component: () => import('../pages/schedule/task/detail.vue')
  }
]

export {
  ProductionRoutes,
  ProductionOverwriteRoutes
}
