import { Restful, RestfulRequest, IntelliApi } from '@/services/intelli-api'
// import Event from '@/utils/event'
import { getVm } from '@/utils/vm'
import { useTenantStore } from '@/stores/tenant'

/**
 * IntelliApi Class
 * @class account
 **/
@Restful({
  name: 'account',
  path: '/authorization/token?platform=pc'
})
class AccountService extends IntelliApi {
  menuAdded = false

  @RestfulRequest({
    method: 'POST',
    parameters: ['username', 'password', 'tenant', 'public_key']
  })
  login (username, password, tenant, publicKey) {
    return (data) => {
      localStorage.setItem('access_token', data.access_token)
      localStorage.setItem('expire_at', data.expire_at)
      // jwt_token为规则引擎部分使用的token
      localStorage.setItem('jwt_token', data.access_token)
      localStorage.setItem('jwt_token_expiration', data.expire_at)
    }
  }

  @RestfulRequest({
    method: 'DELETE'
  })
  logout () {
    return () => {
      // WandQiankun.event.emit('tab.clean')
      // Event.emit('tab.clean')
      localStorage.removeItem('access_token')
      localStorage.removeItem('expire_at')
      localStorage.removeItem('jwt_token')
      localStorage.removeItem('jwt_token_expiration')
      localStorage.removeItem('admin_type')
      // window.location.href = '/account/login'
      getVm().$router.push('/account/login')
    }
  }

  @RestfulRequest({
    method: 'GET',
    basepath: 'system',
    path: 'login-tenant'
  })
  getAppList () {
    return data => {
      return data.sys_apps || []
    }
  }

  async profile () {
    let app = 'pc'
    try {
      const appList = await this.getAppList()
      for (const n in appList) {
        const matched = false
        switch (appList[n].code) {
          case 'pc_ultimate':
            app = 'pc_ultimate'
            break
          case 'pc_lite':
            app = 'pc_lite'
            break
          case 'pc_order':
            app = 'pc_order'
            break
        }
        if (matched) {
          break
        }
      }
    } catch (e) {
      console.log(e)
    }
    return this.getProfile(app)
  }

  @RestfulRequest({
    method: 'GET',
    basepath: 'system',
    path: 'login-user?app=#{app}',
    parameters: [{ app: '-' }]
  })
  getProfile (app) {}

  @RestfulRequest({
    method: 'GET',
    basepath: 'system',
    path: 'login-user?app=dashboard'
  })
  getDashboardList () {
    return (data) => {
      let hasDashboard = false

      for (const i in data.apps) {
        if (data.apps[i].code === 'dashboard') {
          hasDashboard = true
        }
      }

      const records = []

      if (hasDashboard) {
        for (const n in (data.menus || [])) {
          if (data.menus[n].name !== 'dashboard_custom') {
            const icon = (data.menus[n].meta.icon ?? '').split(':')
            records.push({
              id: data.menus[n].id,
              component: data.menus[n].component,
              open_mode: data.menus[n].path.indexOf('fullscreen:') ? 'fullscreen' : 'modal',
              meta: {
                title: data.menus[n].meta.title,
                parameters: {
                  dashboardId: data.menus[n].id
                },
                icon: {
                  type: icon[0] ?? '',
                  name: icon[1] ?? ''
                }
              }
            })
          }
        }
      }

      return {
        list: records,
        has_dashboard: hasDashboard,
        custom_permission: data.permission_codes && data.permission_codes.includes('dashboard_custom')
      }
    }
  }

  /* @RestfulRequest({
    method: 'GET',
    basepath: 'system',
    path: 'login-user?app=dashboard'
  })
  getDashboardMenu () {
    return data => {
      if (data.apps) {
        for (const i in data.apps) {
          if (data.apps[i].code && data.apps[i].code === 'dashboard') {
            if (!this.menuAdded) {
              this.menuAdded = true
              Menu.addMenu(data.menus)
            }
            return data.menus || []
          }
        }
      }
      return null
    }
  } */

  @RestfulRequest({
    method: 'GET',
    basepath: 'dashboard',
    path: 'board'
  })
  getDashboardMenu () {
    return (data) => {
      const records = []
      for (const n in (data.records || [])) {
        records.push({
          id: data.records[n].id,
          component: 'Overview',
          meta: {
            title: data.records[n].name,
            parameters: {
              dashboardId: data.records[n].id
            }
          }
        })
      }
      return records
    }
  }

  @RestfulRequest({
    method: 'GET',
    basepath: 'system',
    path: 'dashboard-custom'
  })
  getExternalDashboardMenu () {
    return (data) => {
      const tenant = useTenantStore().data

      const records = []
      for (const n in (data.records || [])) {
        if (data.records[n].status === 1) {
          records.push({
            id: data.records[n].id,
            component: data.records[n].component,
            route: '/dashboard-external/' + tenant?.account?.toLowerCase() + '/' + data.records[n].route,
            type: 'external',
            open_mode: 'fullscreen',
            meta: {
              title: data.records[n].name,
              parameters: {
                dashboardId: data.records[n].id
              },
              fullscreen: data.records[n].is_fullscreen === 1
            }
          })
        }
      }
      return records
    }
  }

  @RestfulRequest({
    method: 'PUT',
    path: '/updatePwd',
    basepath: '/system/user',
    parameters: ['data']
  })
  changePassword (info) {}

  @RestfulRequest({
    basepath: '/native',
    path: '/authorize/status',
    method: 'GET'
  })
  checkLogin () {
    return () => true
  }

  async check () {
    return await this.checkLogin()
    // return !!this.getAccessToken() && this.isExpire()
  }

  getAccessToken () {
    const token = localStorage.getItem('access_token')
    return (token && token !== 'undefined') ? token : undefined
  }

  isExpire () {
    const expireTime = localStorage.getItem('expire_at')
    return Math.round(new Date().getTime() / 1000) < expireTime
  }
}

export default new AccountService()
