<template>
  <div class="bg-main">
    <div style="position: absolute;left: 20px; top: 10px;cursor: pointer;" @click="goBack">
      <img src="/mes3/assets/img/portal/back.svg" style="width: 16px;height: 16px;" alt="">
    </div>
    <img src="@assets/img/dashboard/glxcl-mini-order-overview/top.png" style="width: 100%" alt="header">
    <div class="d-flex px-3" style="margin-top: 52px;padding-bottom: 46px;">
      <div style="width: 25%">
        <div class="p-5" style="height: 306px">
          <div class="left-bg h-100">
            <div class="title-bg ps-4 pt-2">报警信息</div>
            <div class="p-5 pt-3">
              <div class="d-flex">
                <div class="pb-6 mt-2 ps-4" style="width: 50%;border-right: 1px solid rgba(0, 228, 255, 0.15);border-bottom: 1px solid rgba(0, 228, 255, 0.15);">
                  <div class="number-title">生产异常</div>
                  <div class="number-bg mt-2 text-center pt-1">{{ warn.production }}</div>
                </div>
                <div class="ps-6 mt-2" style="width: 50%;border-bottom: 1px solid rgba(0, 228, 255, 0.15);">
                  <div class="number-title">质量异常</div>
                  <div class="number-bg mt-2 text-center pt-1" style="color: #FAE273;">{{ warn.quality }}</div>
                </div>
              </div>
              <div class="d-flex">
                <div class="pb-6 pt-4 ps-4" style="width: 50%;border-right: 1px solid rgba(0, 228, 255, 0.15);">
                  <div class="number-title">设备异常</div>
                  <div class="number-bg mt-2 text-center pt-1">{{ warn.device }}</div>
                </div>
                <div class="ps-6 pt-4" style="width: 50%;">
                  <div class="number-title">物料异常</div>
                  <div class="number-bg mt-2 text-center pt-1" style="color: #FF7B7B;">{{ warn.material }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-5" style="height: 306px">
          <div class="left-bg h-100">
            <div class="title-bg ps-1 pt-2">一周产量统计</div>
            <div style="margin-top: -46px">
              <z-original-echarts ref="weekProductionBar" :options="weekProductionBarOptions" title="weekProductionBar" style="height: 270px;width: 430px;"></z-original-echarts>
            </div>
          </div>
        </div>
        <div class="p-5" style="height: 306px">
          <div class="left-bg h-100">
            <div class="title-bg ps-5 pt-2">生产异常</div>
            <div style="margin-top: -30px">
              <z-original-echarts ref="productionErrorBar" :options="productionErrorBarOptions" title="productionErrorBar" style="height: 270px;width: 455px;"></z-original-echarts>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 50%">
        <div class="p-5" style="height: 260px;margin-top: -7px;">
          <div class="center-top-bg h-100">
            <div style="padding: 33px">
              <div class="d-flex" style="border-bottom: 1px solid #00e4ff">
                <div style="width: 40%">
                  <div class="d-flex">
                    <div class="center-top-title">当日产量</div>
                    <img class="ms-4 mt-2" src="@assets/img/dashboard/glxcl-mini-order-overview/center-top-arrow.png" style="height: 20px" alt="arrow"/>
                  </div>
                  <div class="d-flex mt-3">
                    <div class="number-bg-vertical text-center pt-5">{{ production.number[4] }}</div>
                    <div class="number-bg-vertical text-center pt-5 ms-5">{{ production.number[3] }}</div>
                    <div class="number-bg-vertical text-center pt-5 ms-5">{{ production.number[2] }}</div>
                    <div class="number-bg-vertical text-center pt-5 ms-5">{{ production.number[1] }}</div>
                    <div class="number-bg-vertical text-center pt-5 ms-5">{{ production.number[0] }}</div>
                  </div>
                </div>
                <div class="d-flex" style="width: 60%">
                  <div v-if="techProduction[0]" style="width: 33.3%">
                    <z-original-echarts ref="techPie-1" :options="techPieOptions" title="techOnePie" style="height: 130px;width: 130px;"></z-original-echarts>
                  </div>
                  <div v-if="techProduction[1]" style="width: 33.3%">
                    <z-original-echarts ref="techPie-2" :options="techPieOptions" title="techTwoPie" style="height: 130px;width: 130px;"></z-original-echarts>
                  </div>
                  <div v-if="techProduction[2]" style="width: 33.3%">
                    <z-original-echarts ref="techPie-3" :options="techPieOptions" title="techThreePie" style="height: 130px;width: 130px;"></z-original-echarts>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div style="width: 27%">
                  <div class="d-flex justify-content-between">
                    <div class="font-weight-bold pt-3">计划产量</div>
                    <i class="pt-1" style="font-size: 27px;font-family: helvetica;color: #8FEBFF;">{{ production.plan_number }}</i>
                  </div>
                </div>
                <div style="width: 10%"></div>
                <div style="width: 27%">
                  <div class="d-flex justify-content-between">
                    <div class="font-weight-bold pt-3">已完成</div>
                    <i class="pt-1" style="font-size: 27px;font-family: helvetica;color: #FAE273;">{{ production.rate }}%</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-5 mt-2 mx-2" style="height: 364px">
          <div class="center-center-bg h-100">
            <div class="title-bg-xl ps-6 pt-2">当日产量</div>
            <div class="d-flex">
              <div class="p-4" style="width:  45%">
                <div class="d-flex p-3 ps-5" style="background-color: rgba(0, 228, 255, 0.1)">
                  <div class="font-weight-bold" style="width: 20%">工艺</div>
                  <div class="font-weight-bold" style="width: 20%">计划</div>
                  <div class="font-weight-bold" style="width: 20%">实际</div>
                  <div class="font-weight-bold" style="width: 40%">进度</div>
                </div>
                <div style="border-bottom: rgba(0, 228, 255, 0.15) 1px solid;">
                  <div v-for="(item, i) in techProduction" :key="i" class="d-flex p-3 ps-5 pb-2" style="font-size: 12px;">
                    <div style="width: 20%">{{ item.name }}</div>
                    <div style="width: 20%">{{ item.plan_number ? parseFloat(item.plan_number).toFixed(2) : 0 }}</div>
                    <div style="width: 20%">{{ item.number.toFixed(2) }}</div>
                    <div class="pt-1" style="width: 40%">
                      <div class="progress" style="height: 10px; border-radius: 7px;background-color: rgba(255,255,255,0);border: rgba(0, 228, 255, 0.2) 1px solid;">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" :aria-valuenow="item.rate" class="progress-bar" style="border-radius: 7px" :style="`background-color:${computeProgressColor(parseFloat(item.rate))};width:${parseFloat(item.rate)}%;`">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-3" style="width: 55%">
                <div style="margin-top: -65px">
                  <z-original-echarts ref="productionMonthBar" :options="productionMonthBarOptions" title="productionMonthBar" style="height: 182px;width: 455px;"></z-original-echarts>
                </div>
                <div style="margin-top: -20px; margin-left: 5px">
                  <z-original-echarts ref="productionMonthLine" :options="productionMonthLineOptions" title="productionMonthLine" style="height: 208px;width: 481px;"></z-original-echarts>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-5 mx-2 mt-2" style="height: 286px">
          <div class="center-bottom-bg h-100">
            <div class="title-bg-xl ps-6 pt-2">生产量</div>
            <div style="margin-top: -46px;margin-left: -40px;">
              <z-original-echarts ref="productionDayArea" :options="productionDayAreaOptions" title="productionDayArea" style="height: 260px;width: 1000px;"></z-original-echarts>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 25%">
        <div class="p-5" style="height: 611px">
          <div class="right-top-bg h-100">
            <div class="title-bg ps-5 pt-2">设备稼动</div>
            <div class="p-4">
              <div class="d-flex" style="border-bottom: 1px solid rgba(0, 228, 255, 0.15)">
                <div class="p-5" style="height: 104px;width: 156px;background: linear-gradient(0deg, rgba(4, 232, 253, 0.1) 0%, rgba(4, 232, 253, 0) 100%);">
                  <div class="text-center font-weight-bold mt-3">设备稼动率</div>
                  <div class="digital-number text-center mt-3" style="color: rgba(250, 226, 115, 1);">{{ oeeInfo.now }}%</div>
                </div>
                <div style="margin-top: -26px">
                  <z-original-echarts ref="devicePie" :options="devicePieOptions" title="devicePie" style="height: 182px;width: 260px;"></z-original-echarts>
                </div>
              </div>
              <div class="d-flex ps-5 pt-3" style="border-bottom: 1px solid rgba(0, 228, 255, 0.15)">
                <div class="right-text-bg p-3 font-weight-bold" style="margin-top: 65px;">最佳设备稼动率</div>
                <div class="right-data-bg text-center ms-6 mb-4 mt-6">
                  <div class="digital-number" style="margin-top: -16px">{{ oeeInfo.max }}%</div>
                </div>
              </div>
              <div class="d-flex ps-5 pt-3">
                <div class="right-text-bg p-3 font-weight-bold" style="margin-top: 65px;color: rgba(255, 123, 123, 1);">最差设备稼动率</div>
                <div class="right-data-bg text-center ms-6 mt-6">
                  <div class="digital-number" style="margin-top: -16px;color: rgba(255, 123, 123, 1);">{{ oeeInfo.min }}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-5" style="height: 306px">
          <div class="left-bg h-100">
            <div class="title-bg ps-3 pt-2">不合格原因</div>
            <div class="ms-2" style="margin-top: -20px">
              <z-original-echarts ref="reasonPie" :options="reasonPieOptions" title="reasonPie" style="height: 260px;width: 416px;"></z-original-echarts>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import zOriginalEcharts from '@/components/dashboard/z-original-echarts'
// 修饰器依赖
import { Component, Vue } from '@min/vue-compatible-decorator'
import * as echarts from 'echarts'
import DashboardService from '@/services/dashboard'
import Moment from 'moment'

export default
@Component({ components: { zOriginalEcharts } })
class GlxclMiniOrderOverview extends Vue {
  weekProductionBarOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
      textStyle: {
        color: 'rgb(143, 235, 255)'
      }
    },
    xAxis: {
      type: 'value',
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.15)'
        }
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.08)'
        }
      },
      axisLabel: {
        color: 'rgb(143, 235, 255)'
      }
    },
    yAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.15)'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: 'rgb(143, 235, 255)'
      }
    },
    dataset: {
      dimensions: ['date', 'number'],
      source: []
    },
    dataSource: {
      type: 'function',
      function: this.getWeekProduction
    },
    series: [
      {
        name: '产量',
        type: 'bar',
        barWidth: 10,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            {
              offset: 0,
              color: 'rgba(0, 228, 255, 1)'
            },
            {
              offset: 1,
              color: 'rgba(0, 228, 255, 0)'
            }
          ]),
          borderRadius: 8
        }
      }
    ]
  }

  productionErrorBarOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      bottom: '10%',
      left: '4%',
      containLabel: true
    },
    legend: {
      right: '8%',
      top: '3%',
      textStyle: {
        color: 'rgb(143, 235, 255)'
      }
    },
    xAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.15)'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: 'rgb(143, 235, 255)'
      }
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.15)'
        }
      },
      minInterval: 1,
      splitLine: {
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.08)'
        }
      },
      axisLabel: {
        color: 'rgb(143, 235, 255)'
      }
    },
    dataset: {
      dimensions: ['process_name', 'device', 'material', 'production'],
      source: [
        { process_name: '出窑工序', device: 1, material: 2, production: 3 },
        { process_name: '压砖工序', device: 2, material: 1, production: 2 },
        { process_name: '烘干工序', device: 1, material: 1, production: 2 },
        { process_name: '包装工序', device: 0, material: 2, production: 1 }
      ]
    },
    dataSource: {
      type: 'sql',
      sql: '9302'
    },
    series: [
      {
        name: '设备异常',
        type: 'bar',
        barWidth: 18,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(0, 228, 255, 1)'
            },
            {
              offset: 1,
              color: 'rgba(0, 228, 255, 0)'
            }
          ]),
          borderRadius: 4
        }
      },
      {
        name: '物料异常',
        type: 'bar',
        barWidth: 18,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(0, 255, 210, 1)'
            },
            {
              offset: 1,
              color: 'rgba(0, 255, 210, 0)'
            }
          ]),
          borderRadius: 4
        }
      },
      {
        name: '生产异常',
        type: 'bar',
        barWidth: 18,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(255, 224, 77, 0.99)'
            },
            {
              offset: 1,
              color: 'rgba(255, 226, 88, 0)'
            }
          ]),
          borderRadius: 4
        }
      }
    ]
  }

  techPieOptions = {
    title: {
      text: '',
      textStyle: {
        color: 'rgba(143, 235, 255, 1)',
        fontWeight: 'bold',
        fontSize: 12
      },
      left: 40,
      top: -5
    },
    color: ['#1a4f6c', '#04e8fd'],
    series: [
      {
        type: 'pie',
        radius: ['40%', '75%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 5,
          borderColor: '#092033',
          borderWidth: 5
        },
        label: {
          show: true,
          position: 'center',
          formatter: (data) => {
            if (data.name === '未生产') {
              return (100 - data.value.value) + '%'
            } else if (data.name === '已生产') {
              return (data.value.value) + '%'
            }
          },
          fontStyle: 'italic',
          fontFamily: 'helvetica',
          borderWidth: 0,
          fontSize: 14,
          color: '#fae273',
          fontWeight: 'bold'
        },
        labelLine: {
          show: false
        },
        detail: {
          valueAnimation: true,
          width: '60%',
          lineHeight: 40,
          borderRadius: 8,
          offsetCenter: [0, '-15%'],
          fontSize: 60,
          fontWeight: 'bolder',
          formatter: '60',
          color: 'auto'
        }
      }
    ],
    dataset: {
      dimensions: ['name', 'value'],
      source: []
    }
  }

  productionMonthBarOptions = {
    title: {
      text: '月度理论生产',
      textStyle: {
        color: 'rgba(143, 235, 255, 1)',
        fontWeight: 'bold',
        fontSize: 12
      },
      top: 30,
      left: 45
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
      textStyle: {
        color: 'rgb(143, 235, 255)'
      }
    },
    xAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.15)'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: 'rgb(143, 235, 255)',
        formatter: '{value}月'
      }
    },
    yAxis: {
      type: 'value',
      splitNumber: 2,
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.15)'
        }
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.08)'
        }
      },
      axisLabel: {
        color: 'rgb(143, 235, 255)'
      }
    },
    dataset: {
      dimensions: ['mon', 'plan_number'],
      source: [
        { mon: '1月', plan_number: 16000 },
        { mon: '2月', plan_number: 18000 },
        { mon: '3月', plan_number: 14000 },
        { mon: '4月', plan_number: 12000 },
        { mon: '5月', plan_number: 10000 },
        { mon: '6月', plan_number: 20000 },
        { mon: '7月', plan_number: 17000 }
      ]
    },
    dataSource: {
      type: 'sql',
      sql: '9306'
    },
    series: [
      {
        name: '产量',
        type: 'bar',
        barWidth: 18,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(0, 255, 210, 1)'
            },
            {
              offset: 1,
              color: 'rgba(0, 228, 255, 0)'
            }
          ]),
          borderRadius: 4
        }
      }
    ]
  }

  productionMonthLineOptions = {
    title: {
      text: '月度生产计划量情况',
      textStyle: {
        color: 'rgba(143, 235, 255, 1)',
        fontWeight: 'bold',
        fontSize: 12
      },
      top: 30,
      left: 45
    },
    tooltip: {
      trigger: 'axis'
    },
    xAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.15)'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: 'rgb(143, 235, 255)'
      }
    },
    yAxis: {
      type: 'value',
      splitNumber: 2,
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.15)'
        }
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.08)'
        }
      },
      axisLabel: {
        color: 'rgb(143, 235, 255)'
      }
    },
    series: [
      {
        name: '计划量',
        type: 'line',
        itemStyle: {
          color: '#00E4FF'
        }
      }
    ],
    dataset: {
      dimensions: ['name', 'plan_number'],
      source: [
        { name: '混料', plan_number: 16000 },
        { name: '出窑', plan_number: 18000 },
        { name: '压砖', plan_number: 14000 },
        { name: '烘干', plan_number: 12000 },
        { name: '包装', plan_number: 10000 }
      ]
    },
    dataSource: {
      type: 'sql',
      sql: '9307'
    }
  }

  productionDayAreaOptions = {
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      bottom: '10%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.15)'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: 'rgb(143, 235, 255)'
      }
    },
    yAxis: {
      type: 'value',
      name: '产量/块',
      nameLocation: 'middle',
      nameTextStyle: {
        color: 'rgba(143, 235, 255, 1)',
        padding: [0, 0, 20, 0]
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.15)'
        }
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.08)'
        }
      },
      axisLabel: {
        color: 'rgb(143, 235, 255)'
      }
    },
    series: [
      {
        name: '产量',
        type: 'line',
        itemStyle: {
          color: 'rgba(0, 255, 210, 1)'
        },
        lineStyle: {
          color: 'rgba(0, 255, 210, 1)',
          width: 1
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(0, 228, 255, 0.3)'
            },
            {
              offset: 1,
              color: 'rgba(0, 228, 255, 0)'
            }
          ])
        }
      }
    ],
    dataset: {
      dimensions: ['time', 'number'],
      source: [
        { time: '8:00', number: 45 },
        { time: '9:00', number: 60 },
        { time: '10:00', number: 65 },
        { time: '11:00', number: 50 },
        { time: '12:00', number: 45 },
        { time: '1:00', number: 50 },
        { time: '2:00', number: 55 },
        { time: '3:00', number: 50 },
        { time: '4:00', number: 65 },
        { time: '5:00', number: 70 },
        { time: '6:00', number: 50 }
      ]
    },
    dataSource: {
      type: 'sql',
      sql: '9304'
    }
  }

  devicePieOptions = {
    color: ['#1a4f6c', '#fae273'],
    series: [
      {
        type: 'pie',
        radius: ['40%', '75%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 5,
          borderColor: '#062537',
          borderWidth: 5
        },
        label: {
          show: false,
          position: 'center'
        },
        labelLine: {
          show: false
        },
        detail: {
          valueAnimation: true,
          width: '60%',
          lineHeight: 40,
          borderRadius: 8,
          offsetCenter: [0, '-15%'],
          fontSize: 60,
          fontWeight: 'bolder',
          formatter: '60',
          color: 'auto'
        }
      }
    ],
    dataset: {
      dimensions: ['name', 'value'],
      source: []
    }
  }

  reasonPieOptions = {
    tooltip: {
      trigger: 'item'
    },
    color: ['#04e7fc', '#00fed1', '#fae273', '#fe7b7b', '#1a4f6c'],
    series: [
      {
        type: 'pie',
        radius: ['45%', '65%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 5,
          borderColor: '#092033',
          borderWidth: 5
        },
        label: {
          alignTo: 'edge',
          color: 'rgb(143, 235, 255)',
          formatter: '{name|{b}}\n{@rate} %',
          edgeDistance: 10,
          lineHeight: 15,
          rich: {
            name: {
              fontSize: 12
            }
          }
        },
        labelLine: {
          length: 15,
          length2: 0,
          maxSurfaceAngle: 80
        },
        labelLayout: function (params) {
          const isLeft = params.labelRect.x < 430 / 2
          const points = params.labelLinePoints
          // Update the end point.
          points[2][0] = isLeft
            ? params.labelRect.x
            : params.labelRect.x + params.labelRect.width
          return {
            labelLinePoints: points
          }
        }
      }
    ],
    dataset: {
      dimensions: ['name', 'rate'],
      source: [
        { rate: 43.2, name: '配料准确性' },
        { rate: 20.1, name: '配料性能' },
        { rate: 15.6, name: '体密度' },
        { rate: 12.3, name: '外观' },
        { rate: 8.8, name: '其他' }
      ]
    },
    dataSource: {
      type: 'sql',
      sql: '9303'
    }
  }

  timer = null

  computeProgressColor (value) {
    if (value < 10 || value === 10) {
      return '#fe7b7b'
    } else if ((value < 60 || value === 60) && value > 10) {
      return '#fae273'
    } else if (value < 100 && value > 60) {
      return '#04e7fc'
    } else if (value === 100) {
      return '#00fed1'
    } else {
      return '#fff'
    }
  }

  async mounted () {
    await this.init()
    this.requestFullscreen()
    // this.$nextTick(() => {
    //   document.addEventListener('keyup', (e) => {
    //     if (e.keyCode === 27) {
    //       this.exitFullscreen()
    //     }
    //   })
    // })
    this.timer = setInterval(this.update, 300000)
  }

  destroyed () {
    // document.removeEventListener('keyup', (e) => {
    //   if (e.keyCode === 27) {
    //     this.exitFullscreen()
    //   }
    // })
    clearInterval(this.timer)
  }

  requestFullscreen () {
    const de = document.documentElement

    if (de.requestFullscreen) {
      de.requestFullscreen()
    }

    if (de.mozRequestFullScreen) {
      de.mozRequestFullScreen()
    }

    if (de.webkitRequestFullScreen) {
      de.webkitRequestFullScreen()
    }
  };

  // 退出全屏
  exitFullscreen () {
    const d = document

    if (d.exitFullscreen) {
      d.exitFullscreen()
    }

    if (d.mozCancelFullScreen) {
      d.mozCancelFullScreen()
    }

    if (d.webkitCancelFullScreen) {
      d.webkitCancelFullScreen()
    }
  };

  async init () {
    const promiseList = [this.getWarnInfo(), this.getProductionInfo(), this.getTechProductionInfo(), this.initTechPie(), this.initOeeInfo(), this.initDevicePie()]
    await Promise.all(promiseList)
  }

  async update () {
    const promiseList = [this.$refs.weekProductionBar.updateData(), this.$refs.productionErrorBar.updateData(), this.$refs.productionMonthBar.updateData(), this.$refs.productionMonthLine.updateData(), this.$refs.productionDayArea.updateData(), this.$refs.reasonPie.updateData(), this.init()]
    await Promise.all(promiseList)
  }

  warn = { production: 0, device: 0, quality: 0, material: 0 }

  async getWarnInfo () {
    const data = await DashboardService.getData(9301)
    this.warn = {
      production: data[0] ? data[0].number : 0,
      device: data[1] ? data[1].number : 0,
      quality: data[2] ? data[2].number : 0,
      material: data[3] ? data[3].number : 0
    }
    if (data.length === 0) {
      this.warn = {
        production: 8,
        device: 4,
        quality: 10,
        material: 6
      }
    }
  }

  async getWeekProduction () {
    let data = await DashboardService.getData(9310)
    if (!(data.length > 0)) {
      data = [
        { date: '1/1', number: 301 },
        { date: '1/2', number: 321 },
        { date: '1/3', number: 501 },
        { date: '1/4', number: 201 },
        { date: '1/5', number: 331 },
        { date: '1/6', number: 501 },
        { date: '1/7', number: 201 }
      ]
      for (const index in data) {
        data[index].date = Moment().subtract(7 - index, 'days').format('MM/DD')
      }
    }
    return data
  }

  production = { number: 0, plan_number: 0, rate: 0 }

  async getProductionInfo () {
    const data = await DashboardService.getData(9308)
    this.production = { number: data[0].number ? data[0].number.split('').reverse().join('') : '', plan_number: data[0].plan_number ? parseFloat(data[0].plan_number).toFixed(2) : 0, rate: data[0].rate }
    if ((data[0].number === '' && data[0].plan_number === '' && data[0].rate === '') || (!data[0].number && !data[0].plan_number && !data[0].rate)) {
      this.production = { number: [0, 0, 6, 9, 7].reverse(), plan_number: 1500, rate: 46.5 }
    }
  }

  techProduction = [
    { name: '混料', plan_number: 1500, number: 1200, rate: 40 },
    { name: '压砖', plan_number: 350, number: 350, rate: 100 },
    { name: '烘干', plan_number: 120, number: 100, rate: 83 },
    { name: '出窑', plan_number: 1000, number: 600, rate: 60 },
    { name: '包装', plan_number: 105, number: 5, rate: 5 }
  ]

  async getTechProductionInfo () {
    let data = await DashboardService.getData(9305)
    if (data.length > 5) {
      data = data.splice(0, 5)
    }
    if (data.length > 0) {
      this.techProduction = data
    }
  }

  async initTechPie () {
    const data = [].concat(this.techProduction)
    const info = [data[0], data[2], data[4]]
    for (const i in info) {
      this.$refs[`techPie-${parseInt(i) + 1}`].chart.setOption({
        title: { text: info[i].name },
        dataset: {
          source: [
            { name: '未生产', value: 100 - parseInt(info[i].rate) },
            { name: '已生产', value: info[i].rate }
          ]
        }
      })
    }
  }

  oeeInfo = {
    now: '0',
    min: '0',
    max: '0'
  }

  async initOeeInfo () {
    const data = (await DashboardService.getData(9309))[0]
    const oeeInfo = {}

    if (data.crops === 0 && data.cropsMin === 0 && data.cropsMax === 0) {
      oeeInfo.now = '90.42'
      oeeInfo.min = '72.2'
      oeeInfo.max = '98.99'
    } else {
      oeeInfo.now = (data.crops * 100).toFixed(2)
      oeeInfo.min = (data.cropsMin * 100).toFixed(2)
      oeeInfo.max = (data.cropsMax * 100).toFixed(2)
    }

    if (Number.isNaN(oeeInfo.now) || oeeInfo.now === 'NaN') {
      oeeInfo.now = '90.42'
    }

    if (Number.isNaN(oeeInfo.min) || oeeInfo.min === 'NaN') {
      oeeInfo.min = '72.2'
    }

    if (Number.isNaN(oeeInfo.max) || oeeInfo.max === 'NaN') {
      oeeInfo.max = '98.99'
    }

    this.oeeInfo = oeeInfo
  }

  initDevicePie () {
    // console.log(this.oeeInfo.now)
    this.$refs.devicePie.updateData(
      [
        { name: '1', value: 100 - this.oeeInfo.now },
        { name: '2', value: this.oeeInfo.now }
      ]
    )
  }

  goBack () {
    // 返回portal
    window.location.href = '/portal'
  }
}
</script>
<style lang="scss" scoped>
.bg-main {
  color: #8FEBFF;
  background-image: url("@assets/img/dashboard/glxcl-mini-order-overview/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .left-bg {
    background-image: url("@assets/img/dashboard/glxcl-mini-order-overview/left-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .number-title {
      font-size: 14px;
      font-weight: bold;
    }
    .number-bg {
      background-image: url("@assets/img/dashboard/glxcl-mini-order-overview/number-bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 39px;
      width: 150px;
      font-size: 40px;
      font-family: ds-digib;
      font-weight: bold;
      line-height: 32px;
    }
  }
  .center-top-bg {
    background-image: url("@assets/img/dashboard/glxcl-mini-order-overview/center-top-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .center-center-bg {
    background-image: url("@assets/img/dashboard/glxcl-mini-order-overview/center-center-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .center-bottom-bg {
    background-image: url("@assets/img/dashboard/glxcl-mini-order-overview/center-bottom-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .right-top-bg {
    background-image: url("@assets/img/dashboard/glxcl-mini-order-overview/right-top-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .right-data-bg {
    background-image: url("@assets/img/dashboard/glxcl-mini-order-overview/right-data-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 130px;
    width: 182px;
  }
  .number-bg-vertical {
    background-image: url("@assets/img/dashboard/glxcl-mini-order-overview/number-bg-vertical.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 65px;
    width: 33px;
    font-size: 40px;
    font-family: ds-digib;
    font-weight: bold;
    line-height: 32px;
  }
}
.digital-number {
  font-size: 33px;
  font-family: ds-digib;
  font-weight: bold;
  line-height: 32px;
}
.center-top-title {
  font-size: 23px;
  font-family: pangmenzhengdao;
  font-weight: 400;
}
.right-text-bg {
  background-image: url("@assets/img/dashboard/glxcl-mini-order-overview/right-text-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 39px;
  width: 143px;
}
.title-bg {
  background-image: url("@assets/img/dashboard/glxcl-mini-order-overview/title-bg-small.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 39px;
  width: 156px;
  font-size: 18px;
  font-family: pangmenzhengdao;
  font-weight: 400;
}
.title-bg-xl {
  background-image: url("@assets/img/dashboard/glxcl-mini-order-overview/title-bg-big.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 39px;
  width: 156px;
  font-size: 18px;
  font-family: pangmenzhengdao;
  font-weight: 400;
}
</style>
